import * as React from 'react';
import BckLogo from "../../assets/logo/logo-bck-sm.png";

const StyledLogo = ({ ...props }) => (
  <div
    sx={{
      width:'auto',
      height:'auto',
      cursor:'pointer',
      marginTop: {
        xs: 10,
        sm: 10,
        md: 16
      }
    }}
    {...props}
  >
    <img className="header-logo" style={{ width:'auto', height:45, cursor: 'pointer' }} src={BckLogo} alt={"logo"}/>
  </div>
)

export default StyledLogo;
