import { TextField } from "@mui/material";
import React from "react";
import {TEXT_FIELD_STYLE} from "../../constants/Styles";

export const StyledTextField = ({
  children,
  textFieldStyle = {},
  InputProps,
  ...props
}) => (
  <TextField
    fullWidth
    margin="dense"
    variant="outlined"
    InputProps={{
      style: { ...TEXT_FIELD_STYLE, ...textFieldStyle },
      ...InputProps
    }}
    {...props}
  >{children}</TextField>
)

export const StyledMultilineTextField = ({
  children,
  textFieldStyle = {},
  ...props
}) => (
  <TextField
    fullWidth
    margin="dense"
    variant="outlined"
    multiline
    rows={5}
    InputProps={{
      style: { ...TEXT_FIELD_STYLE, ...textFieldStyle }
    }}
    {...props}
  >{children}</TextField>
)
