import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Paper } from '@mui/material';
import { Add, Check, Delete, Email, EmailOutlined, UndoOutlined } from "@mui/icons-material";
import { isEqual } from "lodash";
import { NOTIFICATION_TYPE, showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { createNewSiteSubscription, getProducts, updateSiteSubscription } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { getSiteByIdForUser, getSiteSubscription } from "../../../actions/internal/SiteActions";
import StyledSubHeader from "../../common/StyledSubHeader";
import { getSiteById } from "../../../selectors/SiteSelectors";
import StyledProgressCircle from "../../common/StyledProgressCircle";
import { StyledTextField } from "../../common/StyledTextField";
import StyledAccordion from "../../common/StyledAccordion";
import { ContainedButton, LoadingButton, TextButton, TooltipIconButton } from "../../common/StyledButtons";
import { SUMMARY_TITLE_FONT } from "../../../constants/Styles";
import { formatDateToFancyString } from "../../../utilities/DateUtilities";

const AdminEditSubscriptionPage = ({ match, history }) => {

    const subscription = useRef();
    const customer = useRef();
    const products = useRef();
    const emailPackages = useRef();
    const hostingPackages = useRef([]);
    const originalFields = useRef();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ errors, setErrors ] = useState([]);
    const [ fields, setFields ] = useState({});
    const [ emailToAdd, setEmailToAdd ] = useState('');

    const site = getSiteById(match.params.site_id)

    useEffect(() => {
        loadSession()
    }, [])

    const getSiteBusinessesEmails = (site) => {
        if (!site || !site.site_service_modules || !site.site_service_modules.email) { return [] }
        return site.site_service_modules.email.emails || [];
    }

    const getSelectedExtraServices = () => {
        if (!subscription.current) { return {} }
        const items = subscription.current.items.data.filter((item) => !item.product.metadata.group)
            .reduce((acc, val) => ({ ...acc, [val.price.id] : { quantity: val.quantity, product: val.product, price: val.price } }), {})
        return items;
    }
    
    const getHostingPackagePriceId = () => {
        if (!subscription.current) { return undefined }
        const item = subscription.current.items.data.find((item) => item.product && item.product.metadata.group === 'hosting')
        if (!item) { return undefined; }
        return item.price.id;
    }

    const getEmailHostingPriceId = () => {
        if (!subscription.current) { return undefined }
        const item = subscription.current.items.data.find((item) => item.product && item.product.metadata.group === 'email')
        if (!item) { return undefined; }
        return item.price.id;
    }

    const getSubscriptionRenewalDate = () => {
        if (!subscription.current) {
            const date = new Date();
            const newDate = new Date(date.setMonth(date.getMonth() + 1))
            return formatDateToFancyString(newDate.getTime())
        } else {
            return formatDateToFancyString(subscription.current.current_period_end * 1000)
        }
    }

    const getTotalPriceForSubscription = () => {
        if (!subscription.current) return 0;
        return subscription.current.items.data.reduce((acc, val) => acc += val.price.unit_amount * val.quantity, 0);
    }

    const getNewTotalPrice = (updatedFields) => {
        let totalPrice = 0;
        if (updatedFields.emailHostingPriceId) {
            const updatedEmails = updatedFields.emails.filter(e => !updatedFields.emailsToRemove.includes(e));
            updatedEmails.push(...updatedFields.emailsToAdd)
            totalPrice += emailPackages.current.find(ep => ep.id === updatedFields.emailHostingPriceId).unit_amount * updatedEmails.length;
        }
        if (updatedFields.hostingPackagePriceId) {
            totalPrice += hostingPackages.current.find(ep => ep.id === updatedFields.hostingPackagePriceId).unit_amount
        }
        totalPrice += Object.keys(updatedFields.selectedExtraServices).reduce((acc, val) => (
            acc += updatedFields.selectedExtraServices[val].price.unit_amount
        ), 0)
        return totalPrice;
    }

    const loadSession = async () => {
        try {
            const siteId = match.params.site_id
            const userId = getUserId()

            let fetchedSite;

            await Promise.all([
                getSiteByIdForUser(siteId, userId).then(resp => { fetchedSite = resp; }),
                getProducts().then(resp => { products.current = resp.products }),
                getSiteSubscription(userId, siteId).then(resp => {
                    if (resp.stripe_customer) customer.current = resp.stripe_customer;
                    if (resp.stripe_subscription) subscription.current = resp.stripe_subscription;
                })
            ])

            hostingPackages.current = products.current.find((product) => product && product.metadata.group === 'hosting').prices;
            hostingPackages.current = hostingPackages.current.sort((a,b) => a.unit_amount - b.unit_amount).sort((a,b) => a.package.is_legacy - b.package.is_legacy)

            emailPackages.current = products.current.filter((product) => product && product.metadata.group === 'email').flatMap(product => product.prices);

            const existingFields = {
                hostingPackagePriceId: getHostingPackagePriceId(),
                emailHostingPriceId: getEmailHostingPriceId(),
                emails: getSiteBusinessesEmails(fetchedSite),
                selectedExtraServices: getSelectedExtraServices(),
                emailsToRemove: [],
                emailsToAdd: [],
                totalPrice: getTotalPriceForSubscription()
            }

            originalFields.current = { ...existingFields };

            setFields(existingFields);
        } catch (err) {
            const error = await err;
            showNotificationBar('error', `Uh oh! A problem occurred while loading data. Details: ${error.message || 'No additional details.'}`);
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const isFormValid = () => {
        const foundErrors = [];
        if (fields.emailHostingPriceId) {
            const updatedEmails = fields.emails.filter(e => !fields.emailsToRemove.includes(e));
            updatedEmails.push(...fields.emailsToAdd)
            if (updatedEmails.length === 0) foundErrors.push('emailToAdd')
        }
        setErrors(foundErrors)

        return foundErrors.length === 0;
    }

    const handleSaveSubscription = async () => {
        if (!isFormValid()) {
            showNotificationBar(NOTIFICATION_TYPE.WARNING, 'Some fields in this form are invalid. Please check above before continuing.')
            return;
        }

        const emailProduct = products.current.filter((product) => product && product.metadata.group === 'email')
        .find(product => product.prices.some(price =>  price.id === fields.emailHostingPriceId));

        const updatedEmails = fields.emails.filter(e => !fields.emailsToRemove.includes(e));
        updatedEmails.push(...fields.emailsToAdd)

        const email = fields.emailHostingPriceId === undefined ? { } : {
            product_id: emailProduct.id,
            price_id: fields.emailHostingPriceId,
            emails: updatedEmails
        }

        const site_service_modules = { ...site.site_service_modules, email };
        const site_subscription_products = Object.keys(fields.selectedExtraServices).map(priceId => ({ priceId, quantity: fields.selectedExtraServices[priceId].quantity }))
        if (fields.hostingPackagePriceId) site_subscription_products.push({ price: fields.hostingPackagePriceId, quantity: 1 });
        if (fields.emailHostingPriceId) site_subscription_products.push({ price: fields.emailHostingPriceId, quantity: updatedEmails.length });

        try {
            setIsLoading(true)
            const options = { send_welcome_email: false }
            const userId = getUserId();
            const siteId = site.site_id;
            let message;
            if (!subscription.current) {
                await createNewSiteSubscription(userId, siteId, site_service_modules, site_subscription_products, options)
                message = 'Subscription created successfully!';
            } else {
                await updateSiteSubscription(userId, siteId, site_service_modules, site_subscription_products)
                message = 'Subscription updated successfully!';
            }
            await Promise.all([
                getSiteByIdForUser(siteId, userId, true),
                getSiteSubscription(userId, siteId, true)
            ])
            showNotificationBar(NOTIFICATION_TYPE.SUCCESS, message);
            history.goBack();
        } catch(err) {
            console.error(err)
            showNotificationBar(NOTIFICATION_TYPE.ERROR, 'Could not create subscription at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    const handleExtraServiceChange = (price, product, isChecked) => {
        const updatedSelectedExtraServices = { ...fields.selectedExtraServices }
        if (!isChecked) {
            delete updatedSelectedExtraServices[price.id]
        } else {
            updatedSelectedExtraServices[price.id] = { quantity: 1, product, price }
        }

        const newPrice = getNewTotalPrice({ ...fields, selectedExtraServices: updatedSelectedExtraServices });
        setFields({ ...fields, selectedExtraServices: updatedSelectedExtraServices, totalPrice: newPrice })
    }

    const handleFieldChange = (key, value) => {
        const updatedFields = { ...fields };
        updatedFields[key] = value;
        updatedFields.totalPrice = getNewTotalPrice(updatedFields);
        setFields(updatedFields)
    }
    
    const handleAddEmail = () => {
        const updatedEmailsToAdd = [ ...fields.emailsToAdd, emailToAdd]
        const updatedFields = { ...fields, emailsToAdd: updatedEmailsToAdd };
        updatedFields.totalPrice = getNewTotalPrice(updatedFields);
        setFields(updatedFields)
        setEmailToAdd('')
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderHostingPackageContent = () => {
        const selectedPackage = hostingPackages.current.find(item => item.id === fields.hostingPackagePriceId)
        const items = selectedPackage ? Object.keys(selectedPackage.package.included_services).map(key => ({
                title: <span><Check style={{ fontSize: '20px', color: Colors.primaryColor, marginRight: '5px' }} /> {selectedPackage.package.included_services[key].name}</span>,
                details: <>
                    <p>{selectedPackage.package.included_services[key].description}</p>
                </>
            })) : []
        return (
            <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                    <StyledTextField
                        select
                        error={errors.includes('hostingPackagePriceId') || (subscription.current && subscription.current.status !== "active")}
                        value={fields.hostingPackagePriceId}
                        helperText={subscription.current && subscription.current.status !== "active" && `This subscription is currently "${subscription.current.status}". Please go to Stripe to reactivate it or remove this subscription id from the account.`}
                        label="Hosting Package"
                        onChange={(e) => handleFieldChange('hostingPackagePriceId', e.target.value)}
                    >
                        <MenuItem key={'NONE'} value={null}>
                            <i>No Hosting Package</i>
                        </MenuItem>
                        {hostingPackages.current.map(price => (
                        <MenuItem key={price.id} value={price.id}>
                            {price.package.is_legacy ? '(LEGACY) ' : ''}{price.package ? price.package.name : 'No Name'}: ${(price.unit_amount / 100).toFixed(2)}/mo
                        </MenuItem>
                        ))}
                    </StyledTextField>
                </Grid>
                <Grid item xs={12}>
                    <h5 style={{ margin: 0, fontSize: '20px', paddingTop: '18px' }}>Details</h5>
                    { !selectedPackage ? <p>No package selected.</p> :
                        <>
                            <p>{selectedPackage.package.description}</p>
                            <StyledAccordion items={items} />
                        </>
                    }
                </Grid>
            </Grid>
        )
    }

    const renderExtraServicesContent = () => {
        const selectedPackage = hostingPackages.current.find(item => item.id === fields.hostingPackagePriceId)
        let filteredProducts = products.current.filter(prod => !prod.metadata.group);
        if (selectedPackage) {
            const includedServices = Object.keys(selectedPackage.package.included_services);
            filteredProducts = filteredProducts.filter(prod => !includedServices.includes(prod.metadata.packageServiceType))
        }
        const selectedServicePriceIds = Object.keys(fields.selectedExtraServices);
        const selectedServiceProductIds = Object.values(fields.selectedExtraServices).map(p => p.product.id);

        const items = filteredProducts.map(product => ({
            open: selectedServiceProductIds.includes(product.id),
            title: (
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {selectedServiceProductIds.includes(product.id) ?
                        <Check style={{ fontSize: '20px', color: Colors.primaryColor, marginRight: '5px' }} /> 
                    :
                        <Add style={{ fontSize: '20px', color: Colors.warningBackground, marginRight: '5px' }} />
                    }
                    <h4 style={{ margin: 0, fontSize: '18px' }}>{product.name}</h4>
                </div>
                <p style={{ marginTop: '0px' }}>{product.description}</p>
            </div>),
            details: <>
                {product.prices.map((price) => (
                    <div style={{ fontFamily:'Nunito Sans, sans-serif', display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                            defaultChecked={selectedServicePriceIds.includes(price.id)}
                            onChange={(e) => handleExtraServiceChange(price, product, e.target.checked)}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}><div>{price.nickname === null ? <i>No Name</i> : <strong>{price.nickname}</strong>}</div><div>${(price.unit_amount / 100).toFixed(2)}</div></div> 
                    </div>
                ))}
            </>
        }));
        return (
            <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                    <StyledAccordion items={items} />
                </Grid>
            </Grid>
        )
    }

    const renderOverviewSection = () => (
        <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
                <div style={{marginTop: '30px', marginBottom: '18px' }}><span style={{paddingRight: '25px', ...SUMMARY_TITLE_FONT }}>Renewal Date</span>{getSubscriptionRenewalDate()}</div>
                <Divider />
                <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '30px', ...SUMMARY_TITLE_FONT }}>Original Price</span>${(originalFields.current.totalPrice / 100).toFixed(2)}</div>
                <Divider />
                <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '52px', ...SUMMARY_TITLE_FONT }}>New Price</span>${(fields.totalPrice / 100).toFixed(2)}</div>
                <Divider />
            </Grid>
        </Grid>
    )

    const renderEmailContentSection = () => (
        <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
                <StyledTextField
                    select
                    error={errors.includes('emailHostingPriceId')}
                    label="Email Hosting Provider"
                    value={fields.emailHostingPriceId}
                    onChange={(e) => handleFieldChange('emailHostingPriceId', e.target.value)}
                >
                    <MenuItem key={'none'} value={undefined}>
                        <i>No Email Service</i>
                    </MenuItem>
                    {emailPackages.current.map((price) => (
                    <MenuItem key={price.id} value={price.id}>
                        {price.nickname ? price.nickname : 'No Name'}: ${(price.unit_amount / 100).toFixed(2)}/mo
                    </MenuItem>
                    ))}
                </StyledTextField>
            </Grid>
            
            {fields.emailHostingPriceId &&
            <>
                <Grid item xs={12}>
                    <List>
                        {fields.emails.map((email) => (
                            <ListItem
                                key={email}
                                dense
                                sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                <ListItemAvatar>
                                    <EmailOutlined sx={{ fontSize: '36px', color: Colors.primaryColor }} />
                                </ListItemAvatar>
                                {fields.emailsToRemove.includes(email) ?
                                    <ListItemText primary={<strike>{email}</strike>} />
                                    : <ListItemText primary={email} />
                                }
                                <ListItemSecondaryAction>
                                {fields.emailsToRemove.includes(email) ? 
                                        <TooltipIconButton
                                            text={'Remove Business Email'}
                                            icon={<UndoOutlined sx={{ fontSize: '24px', color: Colors.primaryColor }} />}
                                            onClick={() => handleFieldChange('emailsToRemove', [ ...fields.emailsToRemove.filter(e => e !== email) ])}
                                        />
                                    :
                                        <TooltipIconButton
                                            text={'Remove Business Email'}
                                            icon={<Delete sx={{ fontSize: '24px', color: Colors.redColor }} />}
                                            onClick={() => handleFieldChange('emailsToRemove', [ ...fields.emailsToRemove, email]) }
                                        />
                                    }
                                   
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        {fields.emailsToAdd.map((email) => (
                            <ListItem
                                key={email}
                                dense
                                sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                <ListItemAvatar>
                                    <Email sx={{ fontSize: '36px', color: Colors.primaryColor }} />
                                </ListItemAvatar>
                                <ListItemText primary={email} />
                                <ListItemSecondaryAction>
                                    <TooltipIconButton
                                        text={'Remove Business Email'}
                                        icon={<Delete sx={{ fontSize: '24px', color: Colors.redColor }} />}
                                        onClick={() => handleFieldChange('emailsToAdd', [ ...fields.emailsToAdd.filter(e => e !== email) ])}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                    {(!fields.emails || (fields.emails.length === 0 && fields.emailsToAdd.length === 0)) && <p style={{ paddingBottom: '10px' }}>No emails currently being hosted.</p>}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection:  'row', gap: '10px'}}>
                        <Box sx={{ flexGrow: 1 }}>
                            <StyledTextField
                                label="Business Email"
                                value={emailToAdd}
                                onChange={(e) => setEmailToAdd(e.target.value) }
                                error={errors.includes('emailToAdd')}
                                helperText={errors.includes('emailToAdd') && 'Provide at least one email for this service.'}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <ContainedButton
                                onClick={handleAddEmail}
                            >Add</ContainedButton>
                        </Box>
                    </Box>
                </Grid>
            </>
            }
            
        </Grid>
    )

    return (
        <div>
             <Grid container>
                <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
                    <StyledSubHeader title="Edit Subscription" closeIcon />
                    {isLoading ? renderLoadingContent() :
                        <Paper variant="outlined" sx={{ padding: '16px', marginTop: '20px'}}>
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Package Selection</h4>
                            <p>Choose which package that will be used to support the client.</p>
                            {renderHostingPackageContent()}
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Extra Services</h4>
                            <p>Select any additional services that the client would like to add to their package.</p>
                            {renderExtraServicesContent()}
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Professional Email</h4>
                            <p>Provide the business emails that the client would like registered and securly managed.</p>
                            {renderEmailContentSection()}
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Overview</h4>
                            <p>Please confirm the change of price and final price with the client.</p>
                            {renderOverviewSection()}
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <TextButton onClick={() => history.goBack() } sx={{ marginRight: '10px' }}>
                                    Cancel
                                </TextButton>
                                <LoadingButton
                                    isLoading={isLoading}
                                    disabled={
                                        isEqual(fields, originalFields.current)
                                        || (subscription.current && subscription.current.status !== "active")
                                    }
                                    onClick={handleSaveSubscription}
                                    color="primary">Save</LoadingButton>
                            </Box>
                        </Paper>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default AdminEditSubscriptionPage;
