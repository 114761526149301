export const SESSION_REDIRECT_KEY = 'login-redirect'

export const setSessionProperty = (key, value) => {
    sessionStorage.setItem(key, value);
}

export const getSessionProperty = (key, clearOnRetrieve = false) => {
    const value = sessionStorage.getItem(key);
    if (clearOnRetrieve) sessionStorage.removeItem(key);
    return value;
}
