import React, { useEffect, useState } from "react";
import { Grid, Tooltip as MuiTooltip, Paper, Box } from '@mui/material';
import { ArrowDownwardOutlined, ArrowDownwardSharp, ArrowUpwardOutlined, ArrowUpwardSharp, HourglassEmptyOutlined, InfoOutlined } from "@mui/icons-material";
import { capitalize } from "lodash";
import { CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getSiteAnalytics } from '../../../actions/internal/SiteActions';
import Colors from "../../../constants/Colors";
import GenericInfoCard from "./cards/GenericInfoCard";
import { getSiteAnalyticsById } from '../../../selectors/SiteSelectors';
import StyledSubHeader from '../../common/StyledSubHeader';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import { CARD_CAPTION_STYLE } from '../../../constants/Styles';
import { ContainedButton, TextButton } from '../../common/StyledButtons';
import { withRouter } from 'react-router';

const fillerData = [
    {visitors: 0, visitors_last_week: 0, name: 'Sun'},
    {visitors: 0, visitors_last_week: 0, name: 'Mon'},
    {visitors: 0, visitors_last_week: 0, name: 'Tues'},
    {visitors: 0, visitors_last_week: 0, name: 'Wed'},
    {visitors: 0, visitors_last_week: 0, name: 'Thurs'},
    {visitors: 0, visitors_last_week: 0, name: 'Fri'},
    {visitors: 0, visitors_last_week: 0, name: 'Sat'},
]

const COLORS = [Colors.redColor, Colors.primaryColor, Colors.purpleThemeColor, Colors.warningBackground];
const ACTIONS_INFO = "Actions are interactions users take on a website. For example, a reply button or a link, when clicked, is an action.";
const AVG_TIME_INFO = "This metric shows the average amount of minutes a user visits your website.";
const UPTIME_INFO = "This is live status of your website that shows if, or if not, your site is accessible to the world wide web.";

const ViewSiteActivityComponent = ({ history, match }) => {
    const [ isLoading, setIsLoading ] = useState();

    const siteId = match.params.site_id
    const analytics = getSiteAnalyticsById(siteId)

    useEffect(() => {
        loadData().then();
      }, [])
    
      const loadData = async () => {
        try {
          await getSiteAnalytics(siteId)
        } catch (ex) {
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
      }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes < 10 ? '0' + String(minutes) : String(minutes)}:${seconds < 10 ? '0' + String(seconds) : String(seconds)}`
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderPercentageSection = (current_number, past_number) => {
        const isNegative = current_number < past_number;
        // const color = isNegative ? Colors.redColor : Colors.greenColor;
        let percentChange = current_number - past_number;
        if (isNegative) percentChange *= -1;
        percentChange = percentChange / past_number * 100;
        return renderPercentageIcon(percentChange, isNegative)
    }

    const renderPercentageIcon = (percentage, isNegative = false) => {
        const color = isNegative ? Colors.redColor : Colors.greenColor;
        return (
            <span style={{ color }}>{isNegative ? <ArrowDownwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> : <ArrowUpwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> } {(percentage).toFixed(1)}% </span>
        )
    }

    const prepareVisitorData = () => {
        return analytics.report.visitors.timeline.map((stat) => ({
          visitors: stat.count,
          visitors_last_week: stat.court_past,
          name: stat.date_string,
      })).reverse();
    }

    const prepareUsageData = () => {
        return Object.keys(analytics.report.device_usage).map((key) => ({
          value: analytics.report.device_usage[key],
          name: key,
      })).reverse();
    }

    const renderUsageChart = () => (
        <Box>
            <ResponsiveContainer width="100%" height={250}>
                <PieChart width={800} height={400}>
                    <Pie
                        data={prepareUsageData()}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={(props) => capitalize(props.name)}
                    >
                        {Object.keys(analytics.report.device_usage).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip formatter={(value, name) => { return [`${(value).toFixed(1)}%`, `${capitalize(name.replace('_',' ').replace('_',' '))}`] }} />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    )

    const renderVisitorChart = () => (
        <Box sx={{ paddingRight: '8%', marginTop: '50px' }}>
            <ResponsiveContainer width="100%" height={250}>
                <LineChart width={800} height={300} data={analytics && analytics.report.visitors ? prepareVisitorData() : fillerData}>
                    <Line type="linear" dataKey="visitors" stroke={Colors.primaryColor} strokeWidth={3} fill={Colors.primaryColor} fillOpacity={0.05} />
                    <Line type="linear" dataKey="visitors_last_week" stroke={Colors.purpleThemeColor} strokeWidth={1}/>
                    <CartesianGrid stroke="#ddd" />
                    <XAxis dataKey="name" style={{ fontSize: 14 }}/>
                    <YAxis />
                    <Tooltip formatter={(value, name) => { return [`${value}`, `${capitalize(name.replace('_',' ').replace('_',' '))}`] }} />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    )

    const renderChartCard = (title, chart, shrink = false) => (
        <Grid item xs={12} md={shrink && 6}>
            <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                    <div style={CARD_CAPTION_STYLE}>{title}</div>
                </Box>
                {chart}
            </Paper>
        </Grid>
    )

    const renderOverviewSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Overview</h5>
            </Grid>
            <GenericInfoCard title={"Visitors"} content={analytics.report.visitors.count} subcontent={renderPercentageSection(analytics.report.visitors.count, analytics.report.visitors.count_past)} footer="for the past 7 days"/>
            <GenericInfoCard title={"Actions"} content={analytics.report.actions.count} subcontent={renderPercentageSection(analytics.report.actions.count, analytics.report.actions.count_past)} footer="for the past 7 days" info={ACTIONS_INFO}/>
            <GenericInfoCard title={"Average Visit Time"} content={formatTime(analytics.report.time_average.count)} subcontent={renderPercentageSection(analytics.report.time_average.count, analytics.report.time_average.count_past)} footer="minutes per user" info={AVG_TIME_INFO}/>
        </Grid>
    )

    const renderWebsiteTrafficSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Website Traffic</h5>
            </Grid>
            {renderChartCard('Daily Visitors', renderVisitorChart())}
            {renderChartCard('Device Usage', renderUsageChart(), true)}
            {renderUptimeCard()}
        </Grid>
    )

    const renderUptimeIcon = (status) => {
        if (status === "UP") return <ArrowUpwardOutlined style={{ color: Colors.primaryColor, fontSize: 128 }} />
        else if (status === "DOWN") return  <ArrowDownwardOutlined style={{ color: Colors.redColor, fontSize: 128 }} />
        else return <HourglassEmptyOutlined style={{ color: Colors.warningBackground, fontSize: 128 }} />
    }

    const renderUptimeCard = () => (
        <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                    <div style={CARD_CAPTION_STYLE}>Uptime Guard</div>
                    <MuiTooltip title={UPTIME_INFO}>
                        <InfoOutlined style={{ fontSize: 18, cursor: 'pointer', marginTop: 3 }} />
                    </MuiTooltip>
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
                    {renderUptimeIcon(analytics.report.uptime_report.status)}
                    <h4 style={{ fontSize: '32px', marginBottom: 0, marginTop: 0 }}>{analytics.report.uptime_report.status}</h4>
                    <p>{analytics.report.uptime_report.status === "UP" ?
                        `${Number(analytics.report.uptime_report.custom_uptime_ratio).toFixed(1)}% for the past 30 days.`
                    :
                        'This website may be unavailable...'
                    }</p>
                </Box>
            </Paper>
        </Grid>
    )

    const renderSetupContent = () => {
        return (
            <Grid container justifyContent="center" sx={{ marginTop: '10%'}}>
                <Grid item xs="auto">
                    <Paper sx={{ padding: '20px', maxWidth: '600px' }}>
                        <h4 style={{ marginTop: '10px', marginBottom: '10px'}}>Not Enabled...</h4>
                        <p>Your website activity & analytics aren't turned on for your account yet. If you'd like to get them set up, please let us know!</p>
                        <Box sx={{ marginTop: '50px', display: 'flex' }}>
                            <ContainedButton sx={{ marginRight: '10px' }} onClick={() => window.open('https://consultwithkyle.com/contact-us', '_blank')}>
                                Contact Us
                            </ContainedButton>
                            <TextButton onClick={() => history.goBack()}>
                                Go Back
                            </TextButton>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    const renderProperView = () => {
        if (isLoading) {
            return renderLoadingContent();
        } else if (!analytics || analytics.message === "no-clicky-connected") {
            return renderSetupContent();
        } else {
            return (
                <>
                   {renderOverviewSection()}
                   {renderWebsiteTrafficSection()}
                </>
            )
        }
    }
    
    return (
        <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <StyledSubHeader title="Activity" />
            {renderProperView()}
        </Grid>
      </Grid>
    )
}

export default withRouter(ViewSiteActivityComponent);
