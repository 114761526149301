import React, { useEffect, useState } from "react";
import { Box, Grid, Paper } from '@mui/material';
import { getAllProjects } from "../../../selectors/AdminSelectors";
import { Link, withRouter } from "react-router-dom";
import { StyledTextField } from "../../common/StyledTextField";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { TooltipIconButton } from "../../common/StyledButtons";
import Colors from "../../../constants/Colors";
import StyledSubHeader from "../../common/StyledSubHeader";
import { NOTIFICATION_TYPE, showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getAllProjectsForAdmin } from "../../../actions/internal/AdminActions";
import { getUserId } from "../../../selectors/UserSelectors";
import StyledProgressCircle from "../../common/StyledProgressCircle";
import StyledDataTable from "../../common/StyledDataTable";
import { JOB_STATUS_LOOKUP, JOB_STATUS_MAP } from "../../../constants/Statuses";
import StyledCheckbox from "../../common/StyledCheckbox";

const PAGE_LIMIT = 20;

const AdminProjectsPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [offset, setOffset] = useState(0);
    const [showInactiveProjects, setShowInactiveProjects] = useState(false);

    useEffect(() => {
        loadData()
    }, []);
    const loadData = async () => {
        try {
            setIsLoading(true)
            const projects = await getAllProjectsForAdmin(getUserId())
            setFilteredProjects(projects)
        } catch(err) {
            const e = await err;
            console.error(e);
            showNotificationBar(NOTIFICATION_TYPE.ERROR, `Uh oh! Something went wrong when fetching project data. Details: ${e.message}`);
        } finally {
            setIsLoading(false)
        }
    }

    const handleChangeProjectQuery = async (queryInactiveProjects) => {
        try {
            setIsLoading(true);
            const statuses = queryInactiveProjects ? Object.values(JOB_STATUS_MAP) : undefined
            const projects = await getAllProjectsForAdmin(getUserId(), statuses, true)

            setFilteredProjects(projects)
            setShowInactiveProjects(queryInactiveProjects)
            setOffset(0)
        } catch(err) {
            const e = await err;
            console.error(e);
            showNotificationBar(NOTIFICATION_TYPE.ERROR, `Uh oh! Something went wrong when fetching project data. Details: ${e.message}`);
        } finally {
            setIsLoading(false)
        }
    }

    const handleSearchProjects = (e) => {
        const projects = getAllProjects();
        const filtered = projects.filter(project => {
            if (project.job_details.job_name.toLowerCase().includes(e.target.value.toLowerCase())
            || (project.job_site && project.job_site.site_name.toLowerCase().includes(e.target.value.toLowerCase()))) return true;
            return false;
        })
        setFilteredProjects(filtered || []);
        setOffset(0)
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderItemRows = () => {
        if (filteredProjects && filteredProjects.length) {
            return filteredProjects.slice(offset, offset + PAGE_LIMIT).map((project) => {
                return {
                    "Name": <Link to={`/admin/projects/${project.job_id}/manage`} style={{ color: Colors.primaryColor }}>{project.job_details.job_name}</Link>,
                    "Site": project.job_site ? project.job_site.site_name : <em>Not Available</em>,
                    "Status": JOB_STATUS_LOOKUP[project.job_details.job_status].label
                }
            })
        }
        return [];
    }

    const renderProjectsList = () => (
        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
            <Grid item xs={12}>
                <h5 style={{ padding: 0, margin: 0}}>Overview ({filteredProjects ? filteredProjects.length : 0} Total)</h5>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ padding: '16px'}}>
                    <StyledTextField
                        label="Search Projects"
                        onChange={handleSearchProjects}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                        <StyledCheckbox
                            label={'Include Inactive Projects'}
                            disabled={isLoading}
                            onChange={(e) => {
                                handleChangeProjectQuery(e.target.checked)
                            }}
                            checked={showInactiveProjects}
                        /> 
                    </Box>
                    {isLoading ? renderLoadingContent() :
                    <div>
                        <StyledDataTable 
                            columns={["Name", "Site", "Status"]}
                            rows={renderItemRows()}
                        />
                        {filteredProjects.length === 0 && 
                        <div>
                            <p>No business accounts found.</p>
                        </div>
                        }
                    </div>
                    }
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TooltipIconButton
                            icon={<ArrowBackIos />}
                            text={'Previous page'}
                            disabled={offset === 0}
                            onClick={() => setOffset(offset - PAGE_LIMIT)}
                        />
                        <h6 style={{ fontSize: '16px', paddingTop: '10px' }}>{(offset / PAGE_LIMIT) + 1}</h6>
                        <TooltipIconButton
                            icon={<ArrowForwardIos />}
                            text={'Next page'}
                            disabled={offset + PAGE_LIMIT > filteredProjects.length}
                            onClick={() => setOffset(offset + PAGE_LIMIT)}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )

    // Implement Search and Pagination next
    return (
        <Grid container>
            <Grid item lg={8} md={9} sm={12}>
                <StyledSubHeader title="Projects" />
                {renderProjectsList()}
            </Grid>
      </Grid>
    )
}

export default withRouter(AdminProjectsPage);

