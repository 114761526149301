import {observable, action, makeObservable} from "mobx";

class AdminStore {
    sites = undefined;
    submissions = undefined;
    projects = undefined;
    project = {};

    constructor() {
        makeObservable(this, {
            sites: observable,
            submissions: observable,
            projects: observable,
            project: observable,

            setSites: action,
            setSubmissions: action,
            setProjects: action,
            setProject: action,
        })
    }

    initialize() {
        this.sites = undefined;
        this.project = {};
    }

    setSites(sites) {
        this.sites = sites;
    }

    setSubmissions(submissions) {
        this.submissions = submissions;
    }

    setProjects(projects) {
        this.projects = projects;
    }

    setProject(project) {
        this.project[project.job_id] = project;
    }
};


export default new AdminStore();
