import React from 'react';
import { Avatar, Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { AssessmentOutlined, AssignmentOutlined, Store, StoreOutlined } from '@mui/icons-material';
import {TooltipIconButton} from "../common/StyledButtons";
import {withRouter} from "react-router";
import {getSiteById} from "../../selectors/SiteSelectors";

const InternalSiteHeader = ({ match, history }) => {

  const site = getSiteById(match.params.site_id)

  return (
    <div>
      <AppBar position="fixed" style={{backgroundColor: '#fff', top: 70, borderTopColor: '#ccc', borderTopWidth: '2px', borderTopStyle: 'solid'}}>
        <Toolbar>
          <Grid container justifyContent='space-between' direction='row'>
            <Grid item xs='auto'>
              <Grid container>
                {site && site.site_details && site.site_details.site_img_url ?
                  <Avatar alt={site.site_details.site_name} src={site.site_details.site_img_url} />
                  :
                  <Avatar><Store /></Avatar>
                }
                <h4
                  style={{ cursor: 'pointer', margin: 0, marginLeft: '15px', fontSize: '25px' ,color: '#333', marginTop: '5px' }}
                  onClick={() => history.push(`/dashboard/site/${site && site.site_id}`)}
                >{site && site.site_details.site_name}</h4>
              </Grid>
            </Grid>
            <Grid item xs='auto'>
              <Grid container direction='row'>
                <Grid item xs={'auto'}>
                  <TooltipIconButton
                    icon={<AssessmentOutlined />}
                    text={'Activity'}
                    onClick={() => history.push(`/dashboard/site/${site.site_id}/activity`)}
                  />
                </Grid>
                <Grid item xs={'auto'}>
                  <TooltipIconButton
                    icon={<AssignmentOutlined />}
                    text={'Projects'}
                    onClick={() => history.push(`/dashboard/site/${site.site_id}/projects`)}
                  />
                  <TooltipIconButton
                    icon={<StoreOutlined />}
                    text={'My Business'}
                    onClick={() => history.push(`/dashboard/site/${site.site_id}/account`)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(InternalSiteHeader);
