import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute
} from "amazon-cognito-identity-js";
import { TEST_MODE } from "../../constants/System";

const USER_POOL_ID = TEST_MODE ? 'us-east-2_iqATlvpgN' : "us-east-2_ssrPIwZPU";
const APP_CLIENT_ID = TEST_MODE ? '2ku43pgie8p34rklbg666gd9md' : "c2v3b6it63tnchkmh52ltttfp";
const POOL_DATA = { UserPoolId: USER_POOL_ID, ClientId: APP_CLIENT_ID }


export const logProfileIn = async (email, password) => {
    const userPool = new CognitoUserPool(POOL_DATA);
    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) => {
        user.authenticateUser(authenticationDetails, {
            onSuccess: () => {
                resolve(user)
            },
            onFailure: err => reject(err),
            mfaRequired: function(codeDeliveryResults) {
                alert("MFA is required");
                reject("MFA is required");
            },
            newPasswordRequired: function(userAttributes, requiredAttributes) {
                alert("New password required");
                reject("New password required");
            }
        })
    });
  }

  export const beginForgottenPasswordProcess = async (email) => {
    const userPool = new CognitoUserPool(POOL_DATA);
    const user = new CognitoUser({ Username: email, Pool: userPool });
    return new Promise((resolve, reject) =>
      user.forgotPassword({
        onSuccess() {
          resolve()
        },
        onFailure(err) {
          reject(err.message || JSON.stringify(err))
        },
      })
    );
  }

  export const resetForgottenPassword = async (email, verificationCode, newPassword) => {
    const userPool = new CognitoUserPool(POOL_DATA);
    const user = new CognitoUser({ Username: email, Pool: userPool });
    return new Promise((resolve, reject) =>
    user.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve()
        },
        onFailure(err) {
          reject(err)
        },
      })
    );
  }

  export const registerProfile = (email, password, first_name, last_name, user_id) => {
      const userPool = new CognitoUserPool(POOL_DATA);

      let attributeList = [];

      const attributeEmail = new CognitoUserAttribute({ Name : 'email', Value : email});
      const attributeFirstName = new CognitoUserAttribute({Name : 'given_name', Value : first_name});
      const attributeLastName = new CognitoUserAttribute({Name : 'family_name', Value : last_name });
      const attributeUserUuid = new CognitoUserAttribute({Name : 'custom:custom:user_uuid', Value : user_id });

      if (user_id === undefined) throw new Error('No user id defined. Cannot create user');

      attributeList.push(attributeEmail);
      attributeList.push(attributeFirstName);
      attributeList.push(attributeLastName);
      attributeList.push(attributeUserUuid);

      return new Promise((resolve, reject) =>
          userPool.signUp(email, password, attributeList, null, function(err, result){
              if (err) {
                  reject(err);
                  return;
              } else {
                  resolve(result)
              }
          })
      );
  }

  export const getAttributes = (cognitoUser) => {
      return new Promise((resolve, reject) => {
        try {
          cognitoUser.getUserAttributes(function(err, result) {
            if (err) {
                reject(err);
                return;
            }
            const attributes = result.map(function(item) {
              return ({
                name: item.getName(),
                value: item.getValue(),
              })
            })
            resolve(attributes);
          })
        } catch(err) {
          reject(err);
        }
      });
  }

  export const resendVerificationCode = (user_email) => {
      const userPool = new CognitoUserPool(POOL_DATA);
      const userData = {
          Username : user_email,
          Pool : userPool
      };

      const cognitoUser = new CognitoUser(userData);

      return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode(function(err, result) {
                if (err) {
                    reject(err);
                    return;
                    }
                    resolve();
            });
      });
    }

    export const verifyCode = (email, code) => {
        const userPool = new CognitoUserPool(POOL_DATA);
        const userData = {
            Username : email,
            Pool : userPool
        };
        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) =>
            cognitoUser.confirmRegistration(code, true, function(err, result) {
                if (err) {
                    reject(err);
                    return;
                }
                resolve();
            })
        );
    }

  export const isLoggedIn = () => {
      const userPool = new CognitoUserPool(POOL_DATA);
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser !== null) {
        return new Promise((resolve, reject) => {
          try {
            cognitoUser.getSession(function(err, session) {
                if (err) {
                  console.error(err)
                  reject(err);
                  return;
                }
                if(!session.isValid()) reject();
                else resolve(cognitoUser);
            })
          } catch(err) {
            reject(err);
          }
        });
      }
  }
