import React from "react";
import {Grid, Box, Avatar} from '@mui/material';
import { getUserObject } from "../../../actions/profile/UserActions";
import {getAllSites} from "../../../selectors/SiteSelectors";
import Paper from "@mui/material/Paper";
import {StorefrontOutlined} from "@mui/icons-material";
import {CARD_HEADER_STYLE, CARD_SUB_HEADER_STYLE} from "../../../constants/Styles";
import {ContainedButton, TextButton} from "../../common/StyledButtons";
import {formatUrlWithHttps} from "../../../utilities/StringFormatUtilities";
import {withRouter} from "react-router";

const MY_TOOLS = [
  {
    name: 'Schedule a Meeting',
    description: `Want to chat tech, brainstorm future business ideas, or give your website a fresh polish? Book a time with BCK Consulting! We’re here to help you reach your goals and make sure your online presence shines.`,
    img_url: 'https://s3.us-east-2.amazonaws.com/docs.consultwithkyle.com/assets/bck-schedule.webp',
    action_1: {
      url: 'https://consultwithkyle.com/appointments',
      text: 'Book Time Now'
    }
  },
  {
    name: 'Start a New Project',
    description: `Ready to kick off a new project? Whether it’s adding a new page or launching an entire business, BCK Consulting is here to listen and help you succeed. Let’s turn your vision into reality!`,
    img_url: 'https://s3.us-east-2.amazonaws.com/docs.consultwithkyle.com/assets/bck-start-new.webp',
    action_1: {
      url: 'https://consultwithkyle.com/appointments',
      text: 'Get Started'
    }
  },
  {
    name: 'Explore Resources',
    description: `Excited to start something new? Whether it’s adding a page or launching your dream business, BCK Consulting is here to listen and support you every step of the way. Let’s make your vision a reality!`,
    img_url: 'https://s3.us-east-2.amazonaws.com/docs.consultwithkyle.com/assets/bck-resources.webp',
    action_1: {
      url: 'https://consultwithkyle.com/resources',
      text: 'Explore & Learn More'
    }
  }
]

const DashboardComponent = ({ history }) => {

  const renderGreeting = () => {
    const today = new Date()
    if (today.getHours() < 6 ) return 'Evening'
    else if (today.getHours() >= 6 && today.getHours() < 12) return 'Morning'
    else if (today.getHours() >= 12 && today.getHours() < 18) return 'Afternoon'
    else if (today.getHours() >= 18) return 'Evening'
  }

  const getFirstName = () => (
    getUserObject() && `${getUserObject().user_personal.first_name}`
  )

  const getSiteUrl = (site) => (
    (site
    && site.site_service_modules
    && site.site_service_modules.website
    && site.site_service_modules.website.domain.url
    && formatUrlWithHttps(site.site_service_modules.website.domain.url)) || undefined
  )

  const renderToolCards = () => MY_TOOLS.map((tool) => (
    <Grid item xs={12} sm={8} md={6}>
      <Paper variant="outlined" style={{ overflow: 'hidden' }}>
        <img src={tool.img_url} alt="" style={{ width: '100%' }}/>
        <Box sx={{ padding: '16px 16px' }}>
          <div style={CARD_HEADER_STYLE}>{tool.name}</div>
          <p>{tool.description}</p>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <TextButton onClick={() => window.open(tool.action_1.url, '_blank').focus()}>{tool.action_1.text}</TextButton>
          </Box>
        </Box>
      </Paper>
    </Grid>
  ))

  const renderNoSitesSection = () => (
    <Grid item xs={12} sx={{ minHeight: '25vh' }}>
      <Box sx={{
        height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
        width: '100%'
      }}>
        <Paper variant="outlined" sx={{ padding: '16px' }}>
          <div style={CARD_HEADER_STYLE}>No Accounts Created. Yet...</div>
          <p>Let's get started! Set up a time with us to talk and get your business up and running on the web. Were you expecting to see something here, contact us and we'll get it sorted out.</p>
          <ContainedButton sx={{ marginTop: '10px' }}>Contact Us</ContainedButton>
        </Paper>
      </Box>
    </Grid>
  )

  const renderSiteCards = () => getAllSites().map(({ site_details, ...site }) => {
    const url = getSiteUrl(site);
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper sx={{ padding: '32px 16px 32px 16px' }} variant="outlined">
            <Box>
              {site_details.site_img_url ?
                <Avatar alt={site_details.site_name} src={site_details.site_img_url} sx={{ boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.31)'}} />
                :
                <Avatar sx={{ boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.31)'}}><StorefrontOutlined /></Avatar>
              }
            </Box>
            <div style={CARD_HEADER_STYLE}>{site_details.site_name}</div>
            <div style={CARD_SUB_HEADER_STYLE}>{getSiteUrl(site)}</div>
            <Box sx={{marginTop: '24px', display: 'flex', flexDirection: 'row' }}>
              <ContainedButton sx={{ marginRight: '8px' }} onClick={() => history.push(`/dashboard/site/${site.site_id}`)}>Manage</ContainedButton>
              { url && <TextButton onClick={() => window.open(url, '_blank').focus()}>Go To Site</TextButton> }
            </Box>
          </Paper>
        </Grid>
      )
  })

    return (
      <Grid container>
        <Grid item lg={8} md={9} sm={12}>
          <h3 style={{ margin: 0, paddingTop: '10px', marginBottom: '10px', lineHeight: '1.2em' }}>Business Manager</h3>
          <h5 style={{ margin: 0, paddingBottom: '10px' }}>Good {renderGreeting()}, {getFirstName()}!</h5>
          <Grid container spacing={2} sx={{ marginTop: '24px' }}>
            <Grid item xs={12}>
              <h6>My Accounts</h6>
            </Grid>
            {getAllSites().length > 0 ? renderSiteCards() : renderNoSitesSection()}
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '24px' }}>
            <Grid item xs={12} >
              <h6>Resources</h6>
            </Grid>
            {renderToolCards()}
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '24px' }} justifyContent="center">
            <Grid item xs={'auto'}>
              <h6 style={{ textAlign: 'center' }}>Need something?</h6>
              <ContainedButton sx={{ width: '100%' }} onClick={() => window.open('https://consultwithkyle.com/contact-us', '_blank').focus()}>Contact Us Directly</ContainedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
}

export default withRouter(DashboardComponent);
