import React, { useRef, useState } from 'react';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { Delete, PersonAddOutlined, PersonOutline, UndoOutlined } from '@mui/icons-material';
import Colors from '../../../constants/Colors';
import { STANDARD_PARAGRAPH_STYLE } from '../../../constants/Styles';
import { ContainedButton, LoadingButton, TextButton, TooltipIconButton } from '../../common/StyledButtons';
import { StyledTextField } from '../../common/StyledTextField';
import { isValidEmail } from '../../../utilities/Validation';
import { NOTIFICATION_TYPE, showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { updateSiteAccess } from '../../../actions/internal/SiteActions';
import { getUserId } from '../../../selectors/UserSelectors';

const EditSiteAccessModal = ({ site, open, onClose }) => {

    const addedUserDataRef = useRef({});

    const [ showAddUserModal, setShowAddUserModal ] = useState(false);
    const [ removedUsers, setRemovedUsers ] = useState([]);
    const [ addedUsers, setAddedUsers ] = useState([])
    const [ errors, setErrors ] = useState([])
    const [ isSaving, setIsSaving ] = useState(false)

    const validateAddedUserData = () => {
        const { first_name, last_name, email } = addedUserDataRef.current;
        const es = []
        if (!first_name) {
            es.push('first_name')
        }
        if (!last_name) {
            es.push('last_name')
        }
        if (!email || !isValidEmail(email)) {
            es.push('email')
        }
        return es;
    }

    const handleCleanCloseOfAddUserModal = () => {
        setErrors([])
        addedUserDataRef.current = {}
        setShowAddUserModal(false);
    }

    const handleCleanCloseOfManageAccessModal = () => {
        setRemovedUsers([])
        setAddedUsers([])
        onClose()
    }

    const handleAddUser = () => {
        const es = validateAddedUserData();
        if (es.length > 0) {
            setErrors(es);
        } else {
            addedUsers.push(addedUserDataRef.current)
            handleCleanCloseOfAddUserModal();
        }
    }

    const handleSave = async () => {
        if (removedUsers.length === 0 && addedUsers.length === 0) {
            handleCleanCloseOfManageAccessModal();
            return;
        }

        try {
            setIsSaving(true)
            await updateSiteAccess(site.site_id, getUserId(), removedUsers, addedUsers)
            showNotificationBar(NOTIFICATION_TYPE.SUCCESS, 'Your Business Account access has been successfully updated!')
            handleCleanCloseOfManageAccessModal();
        } catch {
            showNotificationBar(NOTIFICATION_TYPE.ERROR, 'Oh no! Something went wrong. Please try again or contact us if this continues to happen.')
        } finally {
            setIsSaving(false)
        }
    }

    const renderAddUserModal= () => (
        <Dialog
            fullWidth
            open={showAddUserModal}
            maxWidth={'md'}
        >
            <DialogTitle>
                <h4 style={{ margin: 0, padding: 0 }}>Add User</h4>
                <p style={ STANDARD_PARAGRAPH_STYLE }>Please provide the first and last name of the user you would like to add, as well as their email address. By registering for an account, they will gain immediate access to this business account as a standard user.</p>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12} md={6}>
                        <StyledTextField
                            type="text"
                            label="First Name"
                            error={errors.includes('first_name')}
                            onChange={(e) => addedUserDataRef.current.first_name = e.target.value}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledTextField
                            type="text"
                            label="Last Name"
                            error={errors.includes('last_name')}
                            onChange={(e) => addedUserDataRef.current.last_name = e.target.value}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextField
                            type="text"
                            label="E-Mail"
                            error={errors.includes('email')}
                            onChange={(e) => addedUserDataRef.current.email = e.target.value}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <TextButton onClick={handleCleanCloseOfAddUserModal}>Cancel</TextButton>
               <ContainedButton  onClick={handleAddUser}>Add</ContainedButton>
            </DialogActions>
        </Dialog>
    )


    return (
        <>
        <Dialog
            fullWidth
            open={open && !showAddUserModal}
            maxWidth={'md'}
        >
            <DialogTitle>
                <h4 style={{ margin: 0, padding: 0 }}>Manage User Access</h4>
                <p style={STANDARD_PARAGRAPH_STYLE}>This panel allows you to manage user access to your business account by adding or removing users. Those with access will be able to view active projects, website activity, and some billing information. Please note that the primary user cannot be removed here. If you wish to change the primary user, please contact us directly.</p>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center">
                    <Grid item xs={12} style={{ display: 'none'}}>
                        <Switch
                            defaultChecked color="secondary"
                        /> <span style={{ fontFamily:'Nunito Sans, sans-serif' }}>Send Welcome Email</span>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                        {site && site.site_security.accessible_users.map((user) => (
                            <ListItem
                                key={user.email}
                                dense
                                sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                <ListItemAvatar>
                                    <Avatar><PersonOutline /></Avatar>
                                </ListItemAvatar>
                                {removedUsers.includes(user.email) ? 
                                    <ListItemText primary={<strike>{user.first_name} {user.last_name} {user.is_primary_user ? '(Primary)' : ''}</strike>} secondary={<stike>{user.email}</stike>} />
                                :
                                    <ListItemText primary={`${user.first_name} ${user.last_name} ${user.is_primary_user ? '(Primary)' : ''}`} secondary={user.email} />
                                }
                                <ListItemSecondaryAction>
                                    {removedUsers.includes(user.email) ? 
                                        <TooltipIconButton
                                            text={'Undo'}
                                            onClick={() => setRemovedUsers([...removedUsers.filter(email => email !== user.email)])}
                                            icon={<UndoOutlined sx={{ fontSize: '24px', color: !user.is_primary_user && Colors.primaryColor }} />} />
                                    :
                                        <TooltipIconButton
                                            text={'Remove Access'}
                                            disabled={user.is_primary_user}
                                            onClick={() => setRemovedUsers([...removedUsers, user.email])}
                                            icon={<Delete sx={{ fontSize: '24px', color: !user.is_primary_user && Colors.redColor }} />} />
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        {addedUsers.map((user) => (
                            <ListItem
                                key={user.email}
                                dense
                                sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                <ListItemAvatar>
                                    <Avatar><PersonAddOutlined /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                                <ListItemSecondaryAction>
                                    <TooltipIconButton
                                        text={'Remove Added User'}
                                        disabled={user.is_primary_user}
                                        onClick={() => setAddedUsers([...addedUsers.filter(u => u.email !== user.email)])}
                                        icon={<Delete sx={{ fontSize: '24px', color: !user.is_primary_user && Colors.redColor }} />} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '16px'}}>
                <ContainedButton onClick={() => setShowAddUserModal(true) }>Add User</ContainedButton>
                <Box>
                    <TextButton sx={{ marginRight: '10px' }} onClick={onClose} disabled={isSaving}>Cancel</TextButton>
                    <LoadingButton onClick={handleSave} isLoading={isSaving}>Save</LoadingButton>
                </Box>
            </Box>
        </Dialog>
        {renderAddUserModal()}
        </>
    )
}

export default EditSiteAccessModal;

