import React, { Component } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GenericNotFound from './components/error-pages/GenericNotFound';
import NotificationBar from "./components/error-pages/NotificationBar";
import ExternalRouter from './components/external/ExternalRouter';
import InternalRouter from "./components/internal/InternalRouter";
import Colors from "./constants/Colors";
import { GlobalStyles } from "@mui/material";

const styles = {
  body: {
    fontFamily:"'Lora',Georgia,'Times New Roman',serif",
    fontSize: '16px'
  },
  h1: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'uppercase',
    fontSize: '64px'
  },
  h2: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'uppercase',
    fontSize: '56px'
  },
  h3: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'none',
    fontSize: '48px'
  },
  h4: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'none',
    fontSize: '30px'
  },
  h5: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'uppercase',
    fontSize: '19px',
    letterSpacing: '5px',
    fontWeight: 600
  },
  h6: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'none',
    fontSize: '28px',
    margin: 0,
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  th: {
    color: '#333',
    fontFamily: "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif",
    textTransform: 'none',
    fontSize: '16px'
  },
  tr: {
    color: '#333',
    fontFamily: "'Lora',Georgia,'Times New Roman',serif",
    textTransform: 'none',
    fontSize: '14px'
  }
}

const theme = createTheme({
  palette: {
    primary: {
        main: Colors.darkColor
    },
  },
  typography: {
      fontSize:18,
      fontFamily:'Nunito Sans, sans-serif',
  },
  icon: {
    fontSize: 18,
  },
  input : {
    textAlign: 'center'
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: 24,
      },
    },
  },
})

export default class MasterComponent extends Component {

    render() {
        return (
          <ThemeProvider theme={theme}>
            <GlobalStyles styles={styles} />
            <BrowserRouter>
              <Switch>
                <Route path="/account" render={routeProps => <InternalRouter {...routeProps} />} />
                <Route path="/admin" render={routeProps => <InternalRouter {...routeProps} />} />
                <Route path="/dashboard" render={routeProps => <InternalRouter {...routeProps} />} />
                <Route path="/" render={routeProps => <ExternalRouter {...routeProps} />} />
                <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
              </Switch>
            </BrowserRouter>
            <NotificationBar />
          </ThemeProvider>
        );
    }
}
