import { Box } from "@mui/material";
import React from "react";

export const DesktopBox = ({
  sx,
  children,
  ...props
}) => (
  <Box sx={{ ...sx, display: { xs: 'none', sm: 'none', md: 'flex' } }} {...props}>
    {children}
  </Box>
)

export const MobileBox = ({
  sx,
  children,
  ...props
}) => (
  <Box sx={{ ...sx, display: { xs: 'flex', sm: 'flex', md: 'none' } }} {...props}>
    {children}
  </Box>
)
