import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';
import '../../styles/internal/internal-styles.css';
import {Box, CircularProgress, Grid} from '@mui/material';
import Colors from "../../constants/Colors";
import ErrorPanel from "../error-pages/ErrorPanel";
import GenericNotFound from "../error-pages/GenericNotFound";
import WebsiteViewComponent from "./account/WebsiteViewComponent";
import ViewJobsComponent from "./account/ViewJobsComponent";
import InternalSiteHeader from "./InternalSiteHeader";
import {getSiteAnalytics, getSiteByIdForUser} from "../../actions/internal/SiteActions";
import ViewJobDetailsComponent from "./account/ViewJobDetailsComponent";
import ViewSiteBillingComponent from "./account/ViewSiteBillingComponent";
import ViewSiteHoursComponent from "./account/ViewSiteHoursComponent";
import SiteAccountDetails from "./account/views/SiteAccountDetails";
import ViewSiteActivityComponent from "./account/ViewSiteActivityComponent";
import ViewSitePackageComponent from "./account/ViewSitePackageComponent";
import SiteCarePage from "./account/SiteCarePage";
import ShopBlogPage from "./account/ShopBlogPage";
import { getUserId } from "../../selectors/UserSelectors";
import { DesktopBox, MobileBox } from "../common/Boxes";
import { StyledLoadingSection } from "../common/StyledProgressCircle";

const InternalSiteRouter = ({ history, match, location }) => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [ error, setError ] = useState(false);

    useEffect(() => {
        prepareSiteSession().then();
    }, [])

    const prepareSiteSession = async () => {
      try {
        setIsLoading(true);
        const { params } = match;
        const site_id = params.site_id;
        if (site_id === undefined) { history.replace('/dashboard'); return; }
        await getSiteByIdForUser(site_id, getUserId());
        await getSiteAnalytics(site_id);
      } catch (ex) {
        setError('Could not load the business data at this time!');
        history.replace('/dashboard');
        console.error(ex);
      } finally {
        setIsLoading(false);
      }
    }

    const loadingContent = (
      <StyledLoadingSection />
    )

    const mainContent = !isLoading && !error ?
        <Switch>
          {/* DASHBOARD ROUTES */}
          <Route exact path="/dashboard/site/:site_id" render={routeProps => <WebsiteViewComponent {...routeProps} />} />
          <Route exact path="/dashboard/site/:site_id/account" render={routeProps => <SiteAccountDetails {...routeProps} />} />

          {/* PROJECT ROUTES */}
          <Route exact path="/dashboard/site/:site_id/projects" render={routeProps => <ViewJobsComponent {...routeProps} />} />
          <Route exact path="/dashboard/site/:site_id/projects/:job_id" render={routeProps => <ViewJobDetailsComponent {...routeProps} />} />

          {/* BILLING ROUTES */}
          <Route exact path="/dashboard/site/:site_id/invoices" render={routeProps => <ViewSiteBillingComponent {...routeProps} />} /> {/* deprecated route */}
          <Route exact path="/dashboard/site/:site_id/billing" render={routeProps => <ViewSiteBillingComponent {...routeProps} />} />

          {/* CONSULTING ROUTES */}
          <Route exact path="/dashboard/site/:site_id/hours" render={routeProps => <ViewSiteHoursComponent {...routeProps} />} /> {/* deprecated route */}
          <Route exact path="/dashboard/site/:site_id/consulting" render={routeProps => <ViewSiteHoursComponent {...routeProps} />} />

          {/* ACTIVITY ROUTES */}
          <Route exact path="/dashboard/site/:site_id/activity" render={routeProps => <ViewSiteActivityComponent {...routeProps} />} />

          {/* PACKAGE ROUTES */}
          <Route exact path="/dashboard/site/:site_id/package" render={routeProps => <ViewSitePackageComponent {...routeProps} />} />
          <Route exact path="/dashboard/site/:site_id/package/site-care" render={routeProps => <SiteCarePage {...routeProps} />} />
          <Route exact path="/dashboard/site/:site_id/package/shop-blog-editor" render={routeProps => <ShopBlogPage {...routeProps} />} />

          {/* MISC ROUTES */}
          <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
        </Switch>
        :
        <ErrorPanel
            message={"Oh no! Something went wrong. Please check your internet connection and try again."}
        />;
    return (
      <>
        <MobileBox>
          {isLoading ? loadingContent : mainContent}
        </MobileBox>
        <DesktopBox sx={{ marginTop: '50px' }}>
          <InternalSiteHeader />
          {isLoading ? loadingContent : mainContent}
        </DesktopBox>
      </>
    );
}

export default InternalSiteRouter;

/*
<InternalSiteHeader history={history} site={site} />
<Redirect from="/dashboard/site/:site_id/statistics" to="/dashboard/site/:site_id/activity" />
            <Redirect from="/dashboard/site/:site_id/jobs" to="/dashboard/site/:site_id/projects" />
            <Redirect from="/dashboard/site/:site_id/jobs/:job_id" to="/dashboard/site/:site_id/projects/:job_id" />

            <Route exact path="/dashboard/site/:site_id" render={routeProps => <WebsiteViewComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/invoices" render={routeProps => <ViewSiteInvoicesComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/hours" render={routeProps => <ViewSiteHoursComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/activity" render={routeProps => <ViewSiteStatsComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/projects" render={routeProps => <ViewSiteJobsComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/account" render={routeProps => <SiteAccountDetails {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/jobs/:job_id" render={routeProps => <ViewSiteJobComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/projects/:job_id" render={routeProps => <ViewSiteJobComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/projects/:job_id/review" render={routeProps => <ReviewSiteJobComponent {...routeProps} />} />
 */
