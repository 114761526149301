import { Box, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { AssignmentOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Colors from "../../../constants/Colors";
import { JOB_GROUPS } from "../../../constants/Statuses";
import { CARD_CAPTION_STYLE, CARD_SUB_HEADER_STYLE, CARD_HEADER_STYLE } from "../../../constants/Styles";
import { getJobsForSite } from '../../../actions/internal/JobActions';
import { getGroupedJobsBySiteId } from '../../../selectors/JobSelectors';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import StyledStatusIcon from '../../common/StyledStatusIcon';
import { ContainedButton, TextButton, TooltipIconButton } from '../../common/StyledButtons';
import { formatDateToFancyString } from '../../../utilities/DateUtilities';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import StyledSubHeader from '../../common/StyledSubHeader';

const ViewJobsComponent = ({ history, match }) => {

    const [ isLoading, setIsLoading ] = useState(true);

    const siteId = match.params.site_id
    const groupedJobs = getGroupedJobsBySiteId(siteId)

    useEffect(() => {
        loadData().then();
    }, [])

    const loadData = async () => {
        try {
            await getJobsForSite(siteId)
        } catch (ex) {
            console.error(ex);
        } finally {
            setIsLoading(false);
        }
    }

    /* const handleShowBookingPage = () => {
        const win = window.open('https://consultwithkyle.com/book', '_blank');
        win.focus();
    }

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        const site_jobs = state && state.site_jobs ? state.site_jobs : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined || site_jobs === undefined) loadData(site_id);
        else {
            jobsRef.current = sortJobs(site_jobs);
            setSiteData(site);
        }
    } */

    /* const sortJobs = (jobs) => {
        return ({
            DRAFT: jobs.filter(job => job.job_details.job_status === 'DRAFT'),
            ACTIVE: jobs.filter(job => ACTIVE_JOB_STATUSES.includes(job.job_details.job_status)),
            INACTIVE: jobs.filter(job => INACTIVE_JOB_STATUES.includes(job.job_details.job_status)),
            ACTION_REQUIRED: jobs.filter(job => ACTION_REQUIRED_JOB_STATUSES.includes(job.job_details.job_status)),
            COMPLETED: jobs.filter(job => job.job_details.job_status === 'COMPLETED')
        })
    }

    const renderAttentionSection = () => (
        <React.Fragment>
            <div style={{ padding: 16, marginTop: 16 }}>
                <Typography variant='h6' component='h2' style={{ paddingLeft: 4, fontSize: 18, marginBottom: 16 }}>
                    Pending Your Action
                </Typography>
                <Paper style={{ borderColor: 'rgba(250, 141, 0,0.75)', borderStyle: 'solid', borderWidth: 5 }} variant='outlined'>
                    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Grid container justify='flex-start' spacing={2}>
                            <Grid item xs={12}>
                                <List className={classes.root}>
                                    {ACTION_REQUIRED && ACTION_REQUIRED.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!ACTION_REQUIRED || ACTION_REQUIRED.length === 0 &&
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2">
                                            No Projects At This Time...
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>
        </React.Fragment>

    ) */

    /* if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } */

    // const { site_details, site_id } = siteData ? siteData : {};
    // const { ACTION_REQUIRED, ACTIVE, INACTIVE, DRAFT, COMPLETED } = jobsRef.current || {};

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderNoProjectsSection = () => (
        <Grid item xs={12} md={8} sx={{ minHeight: '25vh' }}>
            <Paper variant="outlined" sx={{ padding: '16px' }}>
                <div style={CARD_HEADER_STYLE}>No Active Projects. Yet...</div>
                <p>Let's get something started! Set up a time with us to talk and get your business up and running on the web. Were you expecting to see something here, contact us and we'll get it sorted out.</p>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <ContainedButton
                        sx={{ marginRight: '10px'}}
                        onClick={() => window.open('https://consultwithkyle.com/book/#consultations|1', '_blank')}
                    >Schedule A Meeting</ContainedButton>
                    <TextButton
                        onClick={() => window.open('https://consultwithkyle.com/contact-us', '_blank')}
                    >Contact Us</TextButton>
                </Box>
                
            </Paper>
        </Grid>
    )

    /* const getShowcasedJobs = () => {
        const showcase = []
        if (groupedJobs[JOB_GROUPS.ACTION_REQUIRED]) showcase.push(...groupedJobs[JOB_GROUPS.ACTION_REQUIRED])
        if (groupedJobs[JOB_GROUPS.ACTION_REQUIRED]) showcase.push(...groupedJobs[JOB_GROUPS.ACTIVE])
        if (groupedJobs[JOB_GROUPS.ACTION_REQUIRED]) showcase.push(...groupedJobs[JOB_GROUPS.COMPLETED])
        return showcase.slice(0,3)
    } */

    const renderJobCards = (jobs) => {

        if (!jobs || jobs.length === 0) {
            return renderNoProjectsSection()
        }

        const renderedJobs = jobs.map((job) => (
            <Grid item xs={12} sm={6} lg={4} key={job.job_id}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                        <div style={CARD_CAPTION_STYLE}>Project</div>
                        <StyledStatusIcon status={job.job_details.job_status} />
                    </Box>
                    <Box>
                        <h4 style={{ margin: 0 }}>{job.job_details.job_name}</h4>
                        <div style={{...CARD_SUB_HEADER_STYLE, fontStyle: 'italic' }}>{formatDateToFancyString(job.job_metadata.created_at)}</div>
                        <p>{job.job_details.job_description.substring(0, 150)}{job.job_details.job_description.length > 150 ? '...' : ''}</p>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '32px', position: 'relative', zIndex: 15}}>
                        <TextButton onClick={() => history.push(`/dashboard/site/${siteId}/projects/${job.job_id}`)}>Details</TextButton>
                    </Box>
                </Paper>
            </Grid>

        ))
        return (
            <Grid item xs={12} md={12}>
                <Grid container spacing={1}>
                    {renderedJobs}
                </Grid>s
            </Grid>
        )
    }

    const renderProjectPast = () => {
        const jobs = [...groupedJobs[JOB_GROUPS.COMPLETED], ...groupedJobs[JOB_GROUPS.INACTIVE]].sort((a, b) => b.job_metadata.created_at - a.job_metadata.created_at)
        return (
            <Grid item xs={12} md={8}>
                <List style={{ flex: 1 }}>
                    {jobs && jobs.map((job) => (
                        <ListItem
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}
                            onClick={() => history.push(`/dashboard/site/${siteId}/projects/${job.job_id}`)} key={job.job_id}>
                            <ListItemAvatar>
                                <StyledStatusIcon status={job.job_details.job_status} />
                            </ListItemAvatar>
                            <ListItemText primary={job.job_details.job_name} secondary={formatDateToFancyString(job.job_metadata.created_at)} />
                            <ListItemSecondaryAction>
                                <TooltipIconButton
                                    text={'View Details'}
                                    icon= {<AssignmentOutlined sx={{ color: Colors.primaryColor }}
                                    onClick={() => history.push(`/dashboard/site/${siteId}/projects/${job.job_id}`)}
                                /> } />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                    {(!jobs || jobs.length === 0) &&
                        <p>Once your first project has ended, it will appear here.</p>
                    }
                </List>
            </Grid>
          
        )
      }

    return (
        <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <StyledSubHeader title="My Projects" />
            {isLoading ? renderLoadingContent() :
                <Grid container justify='flex-start' spacing={2}>
                    <Grid item xs={12}>
                        <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Active</h5>
                    </Grid>
                    {renderJobCards([...groupedJobs[JOB_GROUPS.ACTION_REQUIRED], ...groupedJobs[JOB_GROUPS.ACTIVE]])}
                    <Grid item xs={12}>
                        <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Project History</h5>
                    </Grid>
                    {renderProjectPast()}
                </Grid>
            }
        </Grid>
      </Grid>
    )
}

export default observer(withRouter(ViewJobsComponent));

/*
<Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='space-between' spacing={1}>
                        <Grid item xs='auto'>
                            <Grid container justify='flex-start' spacing={1}>
                                <Grid item xs={'auto'}>
                                    <IconButton onClick={() => history.goBack()}>
                                        <KeyboardBackspace />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Typography variant="h6" component="h2">
                                        Projects
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isAdmin() && <Grid item xs='auto'>
                            <Button
                                style={{ marginRight: 16}}
                                variant="contained"
                                className={classes.actionButton}
                                disabled={isLoading}
                                onClick={() => history.push(`/admin/jobs/add?site_id=${match.params.site_id}`)}
                            >
                               Add
                            </Button>
                        </Grid>}
                    </Grid>
                    {ACTION_REQUIRED.length > 0 && renderAttentionSection()}
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                        <Typography variant='h6' component='h2' style={{ paddingLeft: 4, fontSize: 18, marginBottom: 16 }}>
                            Active Projects
                        </Typography>
                        <Paper style={{ marginBottom: 28 }} variant='outlined'>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <Grid container justify='flex-start' spacing={2}>
                                    <Grid item xs={12}>

                                        <List className={classes.root}>
                                            {ACTIVE && ACTIVE.map((job) => (
                                                <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                                    <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                                </ListItem>
                                            ))}
                                            {!ACTIVE || ACTIVE.length === 0 &&
                                                <Grid container style={{ margin: 18 }} justify='space-between'>
                                                    <Grid item xs="auto">
                                                        <Typography variant="body2" component="h2">
                                                            No Active Projects...
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Button
                                                            style={{ marginRight: 16}}
                                                            variant="outlined"
                                                            className={classes.actionButtonOutlined}
                                                            onClick={handleShowBookingPage}
                                                        >
                                                            Start A New Project
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <div style={{ marginLeft: '40%', width: '20%', height: 1, backgroundColor: Colors.darkGreyText, marginBottom: 14 }}></div>
                        <Typography variant='h6' component='h2' style={{ paddingLeft: 4, fontSize: 18, marginBottom: 16 }}>
                            Other Projects
                        </Typography>
                        <Accordion style={{ display: isAdmin() ? 'default' : 'none', marginBottom: 8 }} variant='outlined'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                            Draft Projects
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: { display: 'block' } }}>
                                <List style={{ flex: 1 }}>
                                    {DRAFT && DRAFT.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!DRAFT || DRAFT.length === 0 &&
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            No Drafts Projects...
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ marginBottom: 8 }} variant='outlined'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                            Completed Projects
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: { display: 'block' } }}>
                                <List style={{ flex: 1 }}>
                                    {COMPLETED && COMPLETED.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!COMPLETED || COMPLETED.length === 0 &&
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            No Completed Projects Yet...
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        {INACTIVE !== undefined && INACTIVE.length > 0 && <Accordion style={{ marginBottom: 8 }} variant='outlined'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                            Inactive Projects
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: { display: 'block' } }}>
                                <List style={{ flex: 1 }}>
                                    {INACTIVE && INACTIVE.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!INACTIVE || INACTIVE.length === 0 &&
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            No Inactive Projects
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion> }
                    </div>
                </Grid>
            </Grid>
*/
