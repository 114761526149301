const API_KEY = 'MzExZmQyMmItNzJlOS00MjAwLWI2ZjgtMTkwNGYwODIxMDNm';

// TODO THIS API NEEDS TO BE REMOVED FROM FRONT END!!!!

export const getClockifyClients = async () => {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", API_KEY);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return await fetch('https://api.clockify.me/api/v1/workspaces/605252757060744f0803ff3c/clients', requestOptions)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.error('error', error));
}

export const getClockifyProjects = async () => {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", API_KEY);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return await fetch('https://api.clockify.me/api/v1/workspaces/605252757060744f0803ff3c/projects', requestOptions)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.error('error', error));
}
