import React from 'react';
import {Paper, Grid, Box} from '@mui/material';
import Colors from '../../../constants/Colors';
import { showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { isValidEmail } from '../../../utilities/Validation';
import { logProfileIn } from '../../../clients/profile/ProfileClient';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { getCurrentUser, setUserFromCognito } from '../../../actions/profile/UserActions';
import { useEffect } from 'react';
import { SESSION_REDIRECT_KEY, getSessionProperty } from '../../../utilities/SessionUtilities';
import {LoadingButton, TextButton} from "../../common/StyledButtons";
import {StyledTextField} from "../../common/StyledTextField";
import { DesktopBox, MobileBox } from "../../common/Boxes";

const LoginForm = ({ history }) => {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checkAndRestoreSession().then()
  }, [])

  const checkAndRestoreSession = async () => {
    try {
      const loggedInUser = await getCurrentUser();
      if (loggedInUser !== undefined) {
        history.push('/dashboard');
      }
    } catch (err) { /* do nothing */ }
  }

  const isValidForm = () => {
    const foundErrors = [];
    let reportedError;
    if (!username || isEmpty(username) || !isValidEmail(username)) {
       foundErrors.push('username');
       reportedError = 'Please provide a valid user name.'
    }
    if (!password || isEmpty(password)) {
       foundErrors.push('password');
       if (!reportedError) reportedError = 'Please provide a password'
    }
    setErrors(foundErrors);
    setErrorMessage(reportedError)
    return foundErrors.length === 0;
  }

  const handleRegisterOnClick = (e) => {
    e.stopPropagation();
    history.push('/register');
  }

  const handleForgotPasswordOnClick = (e) => {
    e.stopPropagation();
    history.push('/reset-password');
  }

  const handleSignIn = async () => {
    if (!isValidForm()) return;
    setIsLoading(true)
    await logProfileIn(username, password)
    .then((result) => {
      setUserFromCognito(result);
      const redirect = getSessionProperty(SESSION_REDIRECT_KEY, true)
      if (redirect) history.push(redirect);
      else history.push('/dashboard')
    })
    .catch((err) => {
      if (err.name === 'NotAuthorizedException' || err.name === 'UserNotFoundException') {
        setErrors(['password'])
        setErrorMessage('The username and password combination is incorrect.')
      } else if(err.name === 'UserNotFoundException') {
        setErrors(['username'])
        setErrorMessage('This username is not registered. Create an account by clicking the button below.')
      } else if(err.name === 'UserNotConfirmedException') {
        history.push({
          pathname: '/verify',
          state: { user: { email: username, password }}
        });
      } else {
        showNotificationBar('error', 'Unable to log in at this time. Please try again in a few minutes.');
        console.error('error', err.name, err.message)
      }
    }).finally(() => setIsLoading(false));
  }

  const renderLoginContent = () => (
    <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
      <Grid container justifyContent='center'>
        <Grid item xs={11}>
          <h5 style={{ textAlign: 'center', margin: 0 }}>BCK Business Manager</h5>
          <h3 style={{ textAlign: 'center', margin: 0 }}>Sign In</h3>
          <p style={{ textAlign: 'center' }}>{errorMessage ? <span style={{ color: Colors.redColor }}>{errorMessage}</span> : 'Please provide your credentials to access the Dashboard.'}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='center'>
        <Grid item xs={11} md={10}>
          <StyledTextField
            autoFocus
            error={errors.includes('username')}
            onKeyPress={(e) => {if (e.key === 'Enter') { handleSignIn();}}}
            value={username}
            type="text"
            label="E-Mail"
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent='center'>
        <Grid item xs={11} md={10}>
          <StyledTextField
            error={errors.includes('password')}
            onKeyPress={(e) => {if (e.key === 'Enter') { handleSignIn();}}}
            value={password}
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={'auto'}>
          <LoadingButton
            onClick={handleSignIn}
            disabled={isLoading}
            isLoading={isLoading}
          >Sign In</LoadingButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: 10 }}>
        <Grid item xs={'auto'}>
          <TextButton
            onClick={handleForgotPasswordOnClick}
            disabled={isLoading}
          >Forgot Password</TextButton>
        </Grid>
        <Grid item xs={'auto'}>
          <TextButton
            onClick={handleRegisterOnClick}
            disabled={isLoading}
          >Create an Account</TextButton>
        </Grid>
      </Grid>
    </Box>
  )

  return (
      <div>
        <DesktopBox sx={{justifyContent: 'center', width: '100%'}}>
            <Paper sx={{ minWidth: '600px', marginTop: '5%' }} variant='outlined'>
              {renderLoginContent()}
            </Paper>
        </DesktopBox>
        <MobileBox sx={{flexDirection: 'column', justifyContent: 'center', height: '100%', minHeight: '90vh', backgroundColor: '#fff'}} >
          {renderLoginContent()}
        </MobileBox>
      </div>
  );
}

export default LoginForm;
