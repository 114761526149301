import React, { useEffect, useRef, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { setUserFromCognito } from '../../../actions/profile/UserActions';
import { logProfileIn, resendVerificationCode, verifyCode } from '../../../clients/profile/ProfileClient';
import Colors from '../../../constants/Colors';
import { SESSION_REDIRECT_KEY, getSessionProperty } from '../../../utilities/SessionUtilities';
import {StyledTextField} from "../../common/StyledTextField";
import {LoadingButton, TextButton} from "../../common/StyledButtons";
import {DesktopBox, MobileBox} from "../../common/Boxes";

const VerificationForm = ({ location, history }) => {

  const [hasResentCode, setHasRecentCode] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const userRef = useRef(location.state ? location.state.user : undefined);

  useEffect(() => {
    if (!userRef
      || !userRef.current
      || userRef.current.email === undefined
      || userRef.current.password === undefined) history.push('/register');
  }, []);

  const handleResendCode = async event => {
    try {
      if (userRef.current.email === undefined) history.push('/login');
      await resendVerificationCode(userRef.current.email);
      setHasRecentCode(true);
      showNotificationBar('info', 'Verification code sent.');
    } catch(err) {
      console.error('resend code error', err)
      showNotificationBar('error', 'An issue happened when sending the verification code.');
    }
  }

  const handleVerify = async () => {
    try {
      const email = userRef.current.email;
      const password = userRef.current.password;
      if (email === undefined || password === undefined) {
        history.push('/login');
        return;
      } else if(verificationCode.trim() === '') {
        setErrorMessage('Please enter a code.');
        return;
      }
      setIsLoading(true);
      await verifyCode(email, verificationCode)
      .then(() => handleSignIn(email, password));
    } catch(err) {
      if(err.code === 'CodeMismatchException') {
        setErrorMessage('Oh no! That code was not correct.');
      } else {
        console.error('err', err);
        setErrorMessage('Oh no! Something went wrong. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }

const handleSignIn = async (email, password) => {
    try {
      let username = email.toLowerCase();
      let user = await logProfileIn(username, password)
      setUserFromCognito(user);
      const redirect = getSessionProperty(SESSION_REDIRECT_KEY, true)
      if (redirect) history.push(redirect);
      else history.push('/dashboard')
    } catch (e) {
      console.error('LOGIN ERROR', e)
      history.push('/login');
    }
  }

  const renderVerifyContent = () => (
    <div>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={11}>
          <h3 style={{ textAlign: 'center' }}>We Just Sent You A Message!</h3>
          <p style={{ textAlign: 'center' }}>This is the last step. Please check your email to retrieve your activation code and insert it below.</p>
          { errorMessage &&
          <div style={{ color: Colors.errorOutline }}>{errorMessage}</div>
          }
        </Grid>
        <Grid item xs={11} sm={10} md={8} sx={{paddingTop: '50px', paddingBottom: '40px' }} >
          <StyledTextField
            autoFocus
            value={verificationCode}
            onKeyPress={(e) => {if (e.key === 'Enter') { handleVerify();}}}
            label="Verification Code"
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" sx={{paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
        <Grid item xs={'auto'}>
          <TextButton
            onClick={handleResendCode} disabled={isLoading || hasResentCode }
          >Resend Code</TextButton>
        </Grid>
        <Grid item xs={'auto'}>
          <LoadingButton
            onClick={handleVerify} disabled={isLoading} isLoading={isLoading}
          >Verify</LoadingButton>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <div>
      <DesktopBox sx={{justifyContent: 'center', width: '100%'}}>
        <Paper sx={{ minWidth: '600px', marginTop: '5%' }} variant='outlined'>
          {renderVerifyContent()}
        </Paper>
      </DesktopBox>
      <MobileBox sx={{flexDirection: 'column', justifyContent: 'center', height: '90vh', backgroundColor: '#fff'}} >
        {renderVerifyContent()}
      </MobileBox>
    </div>
  )
}

export default VerificationForm;
