import { observable, action, makeObservable } from "mobx";

class NotificationBarStore {
    show = false;
    message = undefined;
    variant = undefined;

    constructor() {
        makeObservable(this, {
            show: observable,
            message: observable,
            variant: observable,

            showBar: action,
            setShowBar: action,
            setMessage: action,
            setVariant: action
        })
    }

    showBar(variant, message) {
        this.show = true;
        this.message = message;
        this.variant = variant;
    }

    setShowBar(show) {
        this.show = show;
    }

    setMessage(message) {
        this.message = message;
    }

    setVariant(variant) {
        this.variant = variant;
    }
}

export default new NotificationBarStore();
