import React from 'react';
import { Dialog, Grid, DialogActions } from '@mui/material';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import {StyledTextField} from "../../common/StyledTextField";
import {LoadingButton, TextButton} from "../../common/StyledButtons";

const CreateAccountModal = ({ open, onCreateAccount, onLeave, isLoading }) =>  {

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [errors, setErrors] = useState([]);

    const isValid = () => {
        if (!password || isEmpty(password)) {
            setErrors(['password']);
            return;
        }
        const foundErrors = [];
        const capitalVal = (/[A-Z]/.test(password));
        const numberVal = (/\d/.test(password));
        if (!password || password.length < 8 || !capitalVal || !numberVal) foundErrors.push('password');
        if (!foundErrors.includes('password') && password !== confirmPassword) foundErrors.push('confirmPassword');
        setErrors(foundErrors);
        return foundErrors.length === 0;
    }

    const handleCreateAccount = () => {
        if (!isValid()) return;
        onCreateAccount(password);
    }

    return (
        <Dialog
            open={open}
        >
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <h3>Last Step!</h3>
                    <p>Please provide a secure password to finish creating your account with BCK Consulting. Make sure to be at least <b>8 characters long</b>, containing <b>at least one number, and one capital letter.</b></p>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <StyledTextField
                      error={errors.includes('password')}
                      helperText={errors.includes('password') && 'Required: At least 8 characters, one number, one capital letter.'}
                      value={password}
                      onKeyPress={(e) => {if (e.key === 'Enter') { handleCreateAccount();}}}
                      type="password"
                      label="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={11}>
                    <StyledTextField
                      error={errors.includes('confirmPassword')}
                      helperText={errors.includes('confirmPassword') && 'The two passwords did not match.'}
                      value={confirmPassword}
                      onKeyPress={(e) => {if (e.key === 'Enter') { handleCreateAccount();}}}
                      type="password"
                      label="Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Grid>
            </Grid>
            <DialogActions>
                <TextButton
                  onClick={onLeave}
                >Not Now</TextButton>
                <LoadingButton
                  onClick={handleCreateAccount}
                  disabled={isLoading}
                  isLoading={isLoading}
                >Create Account</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default CreateAccountModal;
