import { ArrowDropDownOutlined } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import * as React from 'react';
import { CARD_SUB_HEADER_STYLE } from '../../constants/Styles';

const StyledAccordion = ({
    items
  }) => (
    <div>
        {items.map(({ title, details, open }) => (
            <Accordion variant='outlined' defaultExpanded={open} >
                <AccordionSummary
                    expandIcon={<ArrowDropDownOutlined />}
                    sx={{ ...CARD_SUB_HEADER_STYLE }}
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails>
                    {details}
                </AccordionDetails>
            </Accordion>
        ))}
    </div>
   
)

export default StyledAccordion;