import React from "react";
import { Box, Divider, Grid, Link, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router";
import { getSitePaymentReport, getSiteSubscription } from "../../../actions/internal/SiteActions";
import StyledSubHeader from "../../common/StyledSubHeader";
import { getSiteInvoiceReportById, getSiteSubscriptionById } from "../../../selectors/SiteSelectors";
import StyledProgressCircle from "../../common/StyledProgressCircle";
import StyledChip from "../../common/StyledChip";
import { SUMMARY_TITLE_FONT } from "../../../constants/Styles";
import StyledLineItem from "../../common/StyledLineItem";
import { ContainedButton, TooltipIconButton } from "../../common/StyledButtons";
import Colors from "../../../constants/Colors";
import { AssignmentOutlined, ReceiptOutlined } from "@mui/icons-material";
import { getUserId } from "../../../selectors/UserSelectors";
import { formatDateToFancyString } from "../../../utilities/DateUtilities";

const ViewSiteBillingComponent = ({ match, history }) => {

    const [ isLoading, setIsLoading ] = useState(true);

    const siteId = match.params.site_id
    const invoiceReport = getSiteInvoiceReportById(siteId)
    const subscription = getSiteSubscriptionById(siteId)
  
    useEffect(() => {
      loadData().then();
    }, [])
  
    const loadData = async () => {
      try {
        const userId = getUserId()
        await Promise.all([
            getSitePaymentReport(siteId),
            getSiteSubscription(userId, siteId)
        ])
      } catch (ex) {
        console.error(ex);
      } finally {
        setIsLoading(false);
      }
    }

    const showLinkInNewTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderPackageDetailsSection = () => {
        let total = 0;
        if(subscription 
            && subscription.stripe_subscription 
            && subscription.stripe_subscription.status === 'active') {
            total = subscription.stripe_subscription.items.data.reduce((acc, val) => acc + val.price.unit_amount*val.quantity, 0)
        }
        return (
            <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
                <Grid item xs={12}>
                    <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>My Services</h5>
                </Grid>
                <Grid item xs={12}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    {!subscription || !subscription.stripe_subscription || subscription.stripe_subscription.status !== 'active' ?
                        <>
                            <h4 style={{ margin: 0, fontSize: '24px' }}>No Active Services...</h4>
                            <p>It looks like you haven't choosen any services for your account yet. Our services offer a valuable way to make your mark on the web. If you're interested in getting started, contact us to find the perfect fit for you!</p>
                            <ContainedButton
                                    onClick={() => showLinkInNewTab(`https://consultwithkyle.com/appointments/quick-call`)}
                                >
                                Schedule A Call
                            </ContainedButton>
                        </>
                        :
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                                <h4 style={{ margin: 0, fontSize: '24px' }}>Payment Details</h4>
                                <StyledChip
                                    text={subscription.stripe_subscription.collection_method === "charge_automatically" ? "Autopay On" : "Autopay Off"}
                                    sx={{ backgroundColor: subscription.stripe_subscription.collection_method === "charge_automatically" ? Colors.primaryColor : Colors.greyColor }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '25px'}}>
                                <StyledLineItem primary="Status" secondary="Active" sx={{ flexDirection: 'column' }} primarySx={{ fontSize: '20px' }}/>
                                <StyledLineItem primary="Renewal Date" secondary={formatDateToFancyString(subscription.stripe_subscription.current_period_end * 1000)} sx={{ flexDirection: 'column' }} primarySx={{ fontSize: '20px' }}/>
                                <div></div>
                            </Box>
                            <Box>
                                <div style={{ ...SUMMARY_TITLE_FONT, fontSize: '20px', marginTop: '20px' }}>Item Breakdown</div>
                                {subscription.stripe_subscription.items.data.map((item, index) => (
                                    <div>
                                        {index !== 0 && <Divider /> }
                                        <StyledLineItem sx={{ marginTop: '10px', marginBottom: '10px' }} primary={`${item.product.name} (x${item.quantity})`} secondary={`$${((item.price.unit_amount*item.quantity) / 100).toFixed(2)} `}/>
                                    </div>
                                ))}
                                <Divider sx={{ borderBottomWidth: 'medium' }} />
                                <StyledLineItem sx={{ marginTop: '10px', marginBottom: '10px' }} primary="Total" secondary={`$${(total/100).toFixed(2)}`}/>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '45px'}}>
                                <ContainedButton
                                    onClick={() => history.push(`/dashboard/site/${siteId}/package`)}
                                >View Services</ContainedButton>
                            </Box>
                        </>
                    }
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    const renderInvoicesSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Due Invoices</h5>
            </Grid>
            <Grid item xs={12}>
                <List style={{ flex: 1 }}>
                    {invoiceReport && invoiceReport.report.open_invoices.invoices.map((invoice) => (
                        <ListItem
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}
                            key={invoice.id}>
                            <ListItemText primary={<span style={{fontSize: 18}}>{invoice.name}</span>} secondary={`$${(invoice.amount_due/100).toFixed(2)}`} />
                            <ListItemSecondaryAction>
                                {invoice.project_id && <TooltipIconButton
                                    text={'View Project Details'}
                                    icon= {<AssignmentOutlined sx={{ color: Colors.primaryColor }}
                                        onClick={() => history.push(`/dashboard/site/${siteId}/projects/${invoice.project_id}`)}
                                    /> } 
                                />}
                                <TooltipIconButton
                                    text={'View Invoice & Pay Now'}
                                    icon= {<ReceiptOutlined sx={{ color: Colors.primaryColor }}
                                        onClick={() => showLinkInNewTab(invoice.hosted_invoice_url)}
                                    />}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                    {(!invoiceReport || invoiceReport.report.open_invoices.invoices.length === 0) &&
                        <p>Woo-hoo! No invoices are due <span role="img" aria-label="party face emoji">🥳</span></p>
                    }
                </List>
            </Grid>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Invoice History</h5>
            </Grid>
            <Grid item xs={12}>
                <List style={{ flex: 1 }}>
                    {invoiceReport && invoiceReport.report.paid_invoices.invoices.map((invoice) => (
                        <ListItem
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}
                            key={invoice.id}>
                            <ListItemText primary={invoice.name} secondary={`$${(invoice.amount_due/100).toFixed(2)}`} />
                            <ListItemSecondaryAction>
                                {invoice.project_id && <TooltipIconButton
                                    text={'View Project Details'}
                                    icon= {<AssignmentOutlined sx={{ color: Colors.primaryColor }}
                                        onClick={() => history.push(`/dashboard/site/${siteId}/projects/${invoice.project_id}`)}
                                    /> } 
                                />}
                                <TooltipIconButton
                                    text={'View Invoice'}
                                    icon= {<ReceiptOutlined sx={{ color: Colors.primaryColor }}
                                        onClick={() => showLinkInNewTab(invoice.hosted_invoice_url)}
                                    />}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                    {!invoiceReport || invoiceReport.report.paid_invoices.invoices.length === 0 ?
                        <p>It looks like we don't have any past invoices on file. If you think this is a mistake, please <Link href="https://consultwithkyle.com/contact-us" target="_blank">let us know</Link>!</p>
                        :
                        <p>If you're looking for an older invoice or can't find the one you need, please <Link href="https://consultwithkyle.com/contact-us" target="_blank">let us know</Link>!</p>
                    }
                </List>
            </Grid>
        </Grid>
    )

    const renderBillingPreferencesSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Billing Preferences</h5>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <h4 style={{ margin: 0, fontSize: '24px' }}>Looking To Update Something?</h4>
                    <p>We’re not quite set up for you to update billing preferences directly from the site just yet. No worries though! Just reach out to us, and we'll gladly handle any changes or updates to your billing information for you.</p>
                    <ContainedButton onClick={() =>  window.open('https://consultwithkyle.com/contact', '_blank')} sx={{ marginTop: '20px' }}>Contact Us</ContainedButton>
                </Paper>
            </Grid>
        </Grid>
    )

    return (
        <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <StyledSubHeader title="Billing" />
            {isLoading ? renderLoadingContent() :
                <>
                    {renderPackageDetailsSection()}
                    {renderInvoicesSection()}
                    {renderBillingPreferencesSection()}
                </>
            
            }
            
        </Grid>
      </Grid>
    )
}

export default observer(withRouter(ViewSiteBillingComponent));
