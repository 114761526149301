import React from "react";
import {Chip} from "@mui/material";
import {CHIP_STYLE} from "../../constants/Styles";

const StyledChip = ({
  text,
  sx,
  ...props
}) => (
  <Chip label={text} sx={{ ...CHIP_STYLE, ...sx }} { ...props } />
)

export default StyledChip;
