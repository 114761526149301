import React, { useState } from "react";
import { Box, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { getUserObject } from "../../../actions/profile/UserActions";
import { getAllSubmissions } from "../../../selectors/AdminSelectors";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import { AssignmentOutlined, StoreOutlined } from "@mui/icons-material";
import { ContainedButton, TooltipIconButton } from "../../common/StyledButtons";
import Colors from "../../../constants/Colors";
import { CARD_HEADER_STYLE } from "../../../constants/Styles";
import { formatDateTimeToFancyString } from "../../../utilities/DateUtilities";
import StyledStatusIcon from "../../common/StyledStatusIcon";
import { SUBMISSION_TYPES_HUMAN_MAP } from "../../../constants/Statuses";
import ViewRequestModal from "../modals/ViewRequestModal";

const AdminComponent = ({ history }) => {

    const [requestInView, setRequestInView] = useState()
    const [submissions, setSubmissions] = useState(getAllSubmissions());

    const prepareActions = () => {
        const tools = [
          {
            name: "Business Accounts",
            icon: <StoreOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
            description: 'Review and manage all business accounts.',
            link: `/admin/sites`
          },
          {
            name: "Projects",
            icon: <AssignmentOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
            description: 'Manage active and review completed projects.',
            link: `/admin/projects`
          }
        ]
        return tools
      }

    const handleViewRequestOnClose = () => {
        setRequestInView();
        setSubmissions(getAllSubmissions());
    }

    const renderGreeting = () => {
        const today = new Date()
        if (today.getHours() < 6 ) return 'Evening'
        else if (today.getHours() >= 6 && today.getHours() < 12) return 'Morning'
        else if (today.getHours() >= 12 && today.getHours() < 18) return 'Afternoon'
        else if (today.getHours() >= 18) return 'Evening'
    }

    const getFirstName = () => (
        getUserObject() && `${getUserObject().user_personal.first_name}`
    )

    const renderQuickActions = () => prepareActions().map((action) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={action.name}>
            <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                {action.icon}
            </Box>
            <Box>
                <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>{action.name}</div>
                <p style={{ textAlign: 'center' }}>{action.description}</p>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                <ContainedButton onClick={() =>  history.push(action.link)} sx={{ padding: '8px 16px 8px 16px' }}>View</ContainedButton>
            </Box>
            </Paper>
        </Grid>
    ))

    const createSubmissionTitle = (submission) => {
        const type = SUBMISSION_TYPES_HUMAN_MAP[submission.submission_type] || submission.submission_type
        if (submission.submission_identifiers.site) {
            return `${type} ${submission.submission_id.substring(submission.submission_id.length - 6).toUpperCase()}: ${submission.submission_identifiers.site.site_name}`
        } else if (submission.submission_identifiers.user) {
            return `${type} ${submission.submission_id.substring(submission.submission_id.length - 6).toUpperCase()}: ${submission.submission_identifiers.user.first_name} ${submission.submission_identifiers.user.last_name}`
        }
        return type;
    }

    const renderSubmissions = () => {
        return (
            <Grid item xs={12} md={8}>
                <List style={{ flex: 1 }}>
                    {submissions && submissions.map((submission) => (
                        <ListItem
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}
                            key={submission.submission_id}
                        >
                            <ListItemAvatar>
                                <StyledStatusIcon status={submission.submission_status} />
                            </ListItemAvatar>
                            <ListItemText primary={createSubmissionTitle(submission)} secondary={formatDateTimeToFancyString(submission.submission_received_epoch)} />
                            <ListItemSecondaryAction>
                                <TooltipIconButton
                                    text={'View Details'}
                                    icon= {<AssignmentOutlined sx={{ color: Colors.primaryColor }}
                                    onClick={() => setRequestInView(submission)}
                                /> } />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                    {(!submissions || submissions.length === 0) &&
                        <p>No active requests.</p>
                    }
                </List>
            </Grid>
        )
    }
      
    return (
        <Grid container>
            <Grid item lg={8} md={9} sm={12}>
                <h3 style={{ margin: 0, paddingTop: '10px' }}>Admin Panel</h3>
                <h5 style={{ margin: 0, paddingBottom: '10px' }}>Good {renderGreeting()}, {getFirstName()}!</h5>
                <Grid container spacing={2} sx={{ marginTop: '24px' }}>
                    <Grid item xs={12}>
                        <h6>Actions</h6>
                    </Grid>
                    {renderQuickActions()}
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: '24px' }}>
                    <Grid item xs={12}>
                        <h6>Requests</h6>
                    </Grid>
                    {renderSubmissions()}
                </Grid>
            </Grid>
            <ViewRequestModal
                open={!!requestInView}
                onClose={handleViewRequestOnClose}
                request={requestInView}
            />
      </Grid>
    )
}

export default withRouter(observer(AdminComponent));

