import {
  Grid,
  Box
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {CARD_CAPTION_STYLE, CARD_HEADER_STYLE, CARD_SUB_HEADER_STYLE} from "../../../../constants/Styles";
import {ContainedButton, TextButton} from "../../../common/StyledButtons";
import {withRouter} from "react-router";
import {observer} from "mobx-react-lite";
import {getJobsForSite} from "../../../../actions/internal/JobActions";
import { getGroupedJobsBySiteId } from "../../../../selectors/JobSelectors";
import StyledStatusIcon from "../../../common/StyledStatusIcon";
import { JOB_GROUPS } from "../../../../constants/Statuses";
import StyledProgressCircle from "../../../common/StyledProgressCircle";
import { formatDateToFancyString } from "../../../../utilities/DateUtilities";

const ProjectsCard = ({ match, history }) => {

  const [ isLoading, setIsLoading ] = useState(true);

  const siteId = match.params.site_id
  const groupedJobs = getGroupedJobsBySiteId(siteId)

  useEffect(() => {
    loadData().then();
  }, [])

  const loadData = async () => {
    try {
      await getJobsForSite(siteId)
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const showLinkInNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  }

  const renderLoadingContent = () => (
    <StyledProgressCircle />
  )

  const renderNoProjectsSection = () => (
    <Grid item xs={12} sx={{ minHeight: '25vh' }}>
      <Box sx={{
        height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
        width: '100%'
      }}>
        <Paper variant="outlined" sx={{ padding: '16px' }}>
          <div style={CARD_HEADER_STYLE}>No projects, for now...</div>
          <p>Let's get something started! Set up a time with us to talk and get your business up and running on the web. Were you expecting to see something here, contact us and we'll get it sorted out.</p>
          <ContainedButton
            sx={{ marginTop: '10px' }}
            onClick={() => showLinkInNewTab('https://consultwithkyle.com/appointments') }
          >Get Something Started</ContainedButton>
        </Paper>
      </Box>
    </Grid>
  )

  const getShowcasedJobs = () => {
    const showcase = []
    if (groupedJobs[JOB_GROUPS.ACTION_REQUIRED]) showcase.push(...groupedJobs[JOB_GROUPS.ACTION_REQUIRED])
    if (groupedJobs[JOB_GROUPS.ACTION_REQUIRED]) showcase.push(...groupedJobs[JOB_GROUPS.ACTIVE])
    if (groupedJobs[JOB_GROUPS.ACTION_REQUIRED]) showcase.push(...groupedJobs[JOB_GROUPS.COMPLETED])
    return showcase.slice(0,3).sort((a, b) => b.job_metadata.created_at - a.job_metadata.created_at)
  }

  const renderJobCards = () => {
    const jobs = getShowcasedJobs();
    if (!jobs || jobs.length === 0) {
      return renderNoProjectsSection()
    }
    const renderedJobs = jobs.map((job) => (
      <Grid item xs={12} sm={6} lg={4} key={job.job_id}>
        <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
            <div style={CARD_CAPTION_STYLE}>Project</div>
            <StyledStatusIcon status={job.job_details.job_status} />
          </Box>
          <Box>
            <h4 style={{ margin: 0 }}>{job.job_details.job_name}</h4>
            <div style={{...CARD_SUB_HEADER_STYLE, fontStyle: 'italic' }}>{formatDateToFancyString(job.job_metadata.created_at)}</div>
            <p>{job.job_details.job_description.substring(0, 150)}{job.job_details.job_description.length > 150 ? '...' : ''}</p>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '32px', position: 'relative', zIndex: 15}}>
            <TextButton onClick={() => history.push(`/dashboard/site/${siteId}/projects/${job.job_id}`)}>Details</TextButton>
          </Box>
        </Paper>
      </Grid>

    ))
    return (
      <Grid container justifyContent="flex-start" spacing={1}>
        {renderedJobs}
        <Grid item xs={12}>
          <ContainedButton
            sx={{ marginTop: '10px' }}
            onClick={() => history.push(`/dashboard/site/${siteId}/projects`)}
          >View All</ContainedButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
       {isLoading ? renderLoadingContent() : renderJobCards() }
    </Grid>
  )
}

export default observer(withRouter(ProjectsCard));
