import React, { useEffect, useState } from "react";
import {Box, Grid, Skeleton} from "@mui/material";
import Colors from "../../../../constants/Colors";
import {
  CloudOutlined, OpenInNewOutlined
} from "@mui/icons-material";
import {withRouter} from "react-router";
import Paper from "@mui/material/Paper";
import {CARD_CAPTION_STYLE, CARD_SUB_HEADER_STYLE} from "../../../../constants/Styles";
import StyledChip from "../../../common/StyledChip";
import {ContainedButton} from "../../../common/StyledButtons";
import Divider from "@mui/material/Divider";
import { getSiteById, getSiteSubscriptionById } from "../../../../selectors/SiteSelectors";
import { getSiteSubscription } from "../../../../actions/internal/SiteActions";
import { getUserId } from "../../../../selectors/UserSelectors";
import { observer } from "mobx-react-lite";

const SiteDetailsCard = ({ match, history }) => {
  const siteId = match.params.site_id
  const site = getSiteById(siteId)

  const [ isLoading, setIsLoading ] = useState(true);

  const subscription = getSiteSubscriptionById(siteId)

  useEffect(() => {
    loadData().then();
  }, [])

  const loadData = async () => {
    try {
      const userId = getUserId()
      await getSiteSubscription(userId, siteId)
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const resolvePackageName = () => {
    if (!subscription || !subscription.site_package) return '---'
    return subscription.site_package.package_details.name;
  }

  const renderWebsite = () => {
    if (site && site.site_service_modules && site.site_service_modules.website && site.site_service_modules.website.domain )
    return <a style={{ color: '#666', textDecoration: 'none' }} href={`https://${site.site_service_modules.website.domain.url}`} target='_blank' rel="noopener noreferrer">{site.site_service_modules.website.domain.url} <OpenInNewOutlined sx={{fontSize: '16px' }} /></a>
    else return <i>Not Available</i>
  }

  const renderLoadingContent = (width, height) => (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Skeleton variant="rectangular" width={width} height={height} sx={{ marginBottom: '5px' }} />
    </Box>
  )

  return (
    <Grid item xs={12}>
       <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
         <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
           <div style={CARD_CAPTION_STYLE}>My Services</div>
           {isLoading ?
            renderLoadingContent(75, 30) 
           :
            <StyledChip
              text={subscription.site_package.package_details.is_active ? 'Active' : 'Inactive'}
              sx={{ backgroundColor: subscription.site_package.package_details.is_active ? Colors.primaryColor : Colors.greyColor }}
            />
            }
           
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
           <CloudOutlined sx={{ color: Colors.primaryColor, fontSize: '128px' }} />
         </Box>
         <h6 style={{ textAlign: 'center' }}>{isLoading ? renderLoadingContent('70%', 32) : resolvePackageName()}</h6>
         <div style={{ textAlign: 'center', ...CARD_SUB_HEADER_STYLE}}>{renderWebsite()}</div>
         <Divider sx={{ marginTop: '16px', marginBottom: '0px' }}/>
         <Box sx={{ marginBottom: '16px' }}>
           <div style={{ ...CARD_CAPTION_STYLE, marginBottom: '8px' }}>Extras</div>
           <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
            {isLoading ? 
              <>
                <Skeleton variant="rectangular" width={175} height={30} sx={{ margin: '3px'}} />
                <Skeleton variant="rectangular" width={150} height={30} sx={{ margin: '3px' }} />
              </>
              :
              Object.keys(subscription.site_package.package_services.extra_services).map((key) => (
                <StyledChip text={subscription.site_package.package_services.extra_services[key].name} sx={{ backgroundColor: Colors.warningBackground, margin: '3px' }}/>
              ))
            }
           </Box>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '32px'}}>
           <ContainedButton
            sx={{ padding: '8px 16px 8px 16px' }}
            onClick={() => history.push(`/dashboard/site/${siteId}/package`)}
            >Manage</ContainedButton>
         </Box>
      </Paper>
    </Grid>
  )
}

export default withRouter(observer(SiteDetailsCard));
