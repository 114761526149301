import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import {Box, Button, Typography} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { getUserObject, isAdmin, signUserOut } from '../../actions/profile/UserActions';
import { getSiteByIdForUser } from '../../actions/internal/SiteActions';
import { TEST_MODE } from '../../constants/System';
import StyledLogo from "../common/StyledLogo";
import {DesktopBox, MobileBox} from "../common/Boxes";
import {PROFILE_FONT_STYLE} from "../../constants/Styles";
import { getUserId } from '../../selectors/UserSelectors';

const InternalHeader = ({ history, location }) => {

  const [ anchorEl, setAnchorEl ] = useState();
  const [ isLoadingSite, setIsLoadingSite ] = useState();
  const [ siteInFocus, setSiteInFocus ] = useState();

  useEffect(() => {
    const siteId = getSiteIdFromUrl(location.pathname)
    loadSite(siteId)
  }, [location])

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  };

  const handleMenuClose = () => {
    setAnchorEl(null)
  };

  const handleSignUserOut = () => {
    signUserOut();
    handleMenuClose();
    history.push('/');
  };

  const getFirstName = () => (
    getUserObject() && `${getUserObject().user_personal.first_name}`
  )

  const getLastName = () => (
    getUserObject() && `${getUserObject().user_personal.last_name}`
  )

  const getUserEmail = () => (
    getUserObject() && `${getUserObject().user_login_id}`
  )

  const handleDashboardClick = () =>  { history.push('/dashboard'); handleMenuClose(); }
  const handleAccountClick = () =>  { history.push('/account/me'); handleMenuClose(); }
  // const handleLocationsClick = () => { history.push('/account/locations'); handleMenuClose(); }
  const handleAdminClick = () => { history.push('/admin'); handleMenuClose(); }
  const handleLinkClick = (link) => { handleMenuClose(); history.push(link); }

  const loadSite = async (siteId) => {
    if (!siteId && siteInFocus) {
      setSiteInFocus(undefined)
      return
    } else if (!siteId) return;
    try {
      setIsLoadingSite(true)
      const site = await getSiteByIdForUser(siteId, getUserId())
      setSiteInFocus(site)
    } catch(err) {
      console.error(err)
    } finally {
      setIsLoadingSite(false)
    }
  }

  const getSiteIdFromUrl = (url) => {
    const lookFor = 'site/'
    const startIndex = url.indexOf(lookFor)
    if (startIndex < 0) return;
    let siteId = url.slice(startIndex + lookFor.length)
    let end = siteId.indexOf('/')
    if (end < 0) return siteId
    siteId = siteId.slice(0, siteId.indexOf('/'))
    return siteId;
  }

  const renderMenu = () => {
    return (<Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={anchorEl}
      onClose={handleMenuClose}
      sx={{ PROFILE_FONT_STYLE }}
    >
      <div style={{ paddingLeft: '18px', paddingRight: '18px', ...PROFILE_FONT_STYLE }}>
        <h4 style={{ margin: 0, fontSize: 24, fontWeight: 'normal' }}>{getFirstName()} {getLastName()}</h4>
        <p style={{ margin: 0, fontSize: 16 }}>{getUserEmail()}</p>
        { siteInFocus && <MobileBox>
          <h6 style={{ fontSize: '16px', textTransform: 'uppercase', paddingTop: '8px' }}>{siteInFocus.site_details.site_name}</h6>
        </MobileBox> }
      </div>
      <Divider sx={{ marginTop: '20px', marginBottom: '10px' }} />
      { siteInFocus && <MobileBox sx={{ flexDirection: 'column' }}>
        <MenuItem onClick={() => handleLinkClick(`/dashboard/site/${siteInFocus.site_id}/activity`)}>Activity</MenuItem>
        <MenuItem onClick={() => handleLinkClick(`/dashboard/site/${siteInFocus.site_id}/projects`)}>Projects</MenuItem>
        <MenuItem onClick={() => handleLinkClick(`/dashboard/site/${siteInFocus.site_id}/account`)}>Business</MenuItem>
        <Divider sx={{ marginTop: '10px', marginBottom: '10px' }}/>
      </MobileBox>
      }
      <MenuItem onClick={handleDashboardClick}>Dashboard</MenuItem>
      <MenuItem onClick={handleAccountClick}>My Account</MenuItem>
      <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
      {isAdmin() && <MenuItem onClick={handleAdminClick}>Admin</MenuItem>}
      <MenuItem onClick={handleSignUserOut}>Log Out</MenuItem>
    </Menu>)
  }

  return (
    <div style={{ marginTop: '70px' }}>
      <AppBar position="fixed" elevation={3} sx={{ backgroundColor: '#fff', height: 70, paddingTop: '5px', }}>
        { TEST_MODE && <div style={{ position: 'fixed', top: 0, left: '50%', backgroundColor: '#f00', padding: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, fontSize: 12 }}>TEST MODE</div> }
        <Toolbar>
          <Box sx={{ flexGrow: 1, marginTop: { xs: '7px', sm: '0px' } }}>
            <StyledLogo onClick={handleDashboardClick} />
          </Box>
          <DesktopBox sx={{ flexGrow: 1, display: { sm: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            <Button
              aria-owns={anchorEl ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ textTransform : 'none', color: '#333' }}
            >
              <AccountCircle style={{ fontSize: 24, marginRight: 4 }}/> {getFirstName()}
            </Button>
          </DesktopBox>
          <MobileBox sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
            <IconButton
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MenuIcon style={{ fontSize: 24, color: '#000' }}/>
            </IconButton>
          </MobileBox>
        </Toolbar>
      </AppBar>
      {renderMenu()}
    </div>
  )
}

export default withRouter(InternalHeader);
