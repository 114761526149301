import React, { useState } from "react";
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { getAllSites } from "../../../selectors/AdminSelectors";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import { StyledTextField } from "../../common/StyledTextField";
import { ArrowBackIos, ArrowForwardIos, Settings, StorefrontOutlined } from "@mui/icons-material";
import { TooltipIconButton } from "../../common/StyledButtons";
import Colors from "../../../constants/Colors";
import StyledSubHeader from "../../common/StyledSubHeader";

const PAGE_LIMIT = 10;

const AdminBusinessesPage = ({ history }) => {

    const [filteredSites, setFilteredSites] = useState(getAllSites() || []);
    const [offset, setOffset] = useState(0);

    const handleSearchAccounts = (e) => {
        const sites = getAllSites();
        const filtered = getAllSites().filter(site => {
            
            if (site.site_details.site_name.toLowerCase().includes(e.target.value.toLowerCase())) return true;
            return false;
        })
        setFilteredSites(filtered || []);
        setOffset(0)
    }

    // Implement Search and Pagination next
    return (
        <Grid container>
            <Grid item lg={8} md={9} sm={12}>
                <StyledSubHeader title="Business Accounts" />
                <Grid container spacing={2} sx={{ marginTop: '24px' }}>
                    <Grid item xs={12}>
                        <h5 style={{ padding: 0, margin: 0}}>Overview ({filteredSites ? filteredSites.length : 0} Total)</h5>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: '16px'}}>
                            <StyledTextField
                                label="Search Accounts"
                                onChange={handleSearchAccounts}
                            />
                            <List style={{ flex: 1 }}>
                                {filteredSites.slice(offset, offset + PAGE_LIMIT).map((site) => (
                                    <ListItem
                                        dense
                                        button
                                        onClick={() => history.push(`/admin/sites/${site.site_id}/manage`)}
                                        sx={{ backgroundColor: '#fff', marginBottom: '5px', paddingTop: '10px', paddingBottom: '10px' }}
                                        key={site.site_id}>
                                        <ListItemAvatar>
                                            {site.site_details.site_img_url ?
                                                <Avatar alt={site.site_details.site_name} src={site.site_details.site_img_url} sx={{ boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.31)'}} />
                                                :
                                                <Avatar sx={{ boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.31)'}}><StorefrontOutlined /></Avatar>
                                            }
                                        </ListItemAvatar>
                                        <ListItemText primary={site.site_details.site_name} />
                                        <ListItemSecondaryAction>
                                            <TooltipIconButton
                                                text={'Manage Business Account'}
                                                icon= {<Settings sx={{ color: Colors.primaryColor, fontSize: '24px' }}
                                                onClick={() => history.push(`/admin/sites/${site.site_id}/manage`)}
                                            /> } />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                                {filteredSites.length === 0 && 
                                <ListItem>
                                    <p>No business accounts found.</p>
                                </ListItem>
                                }
                            </List>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TooltipIconButton
                                    icon={<ArrowBackIos />}
                                    text={'Previous page'}
                                    disabled={offset === 0}
                                    onClick={() => setOffset(offset - PAGE_LIMIT)}
                                />
                                <h6 style={{ fontSize: '16px', paddingTop: '10px' }}>{(offset / PAGE_LIMIT) + 1}</h6>
                                <TooltipIconButton
                                    icon={<ArrowForwardIos />}
                                    text={'Next page'}
                                    disabled={offset + PAGE_LIMIT > filteredSites.length}
                                    onClick={() => setOffset(offset + PAGE_LIMIT)}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
      </Grid>
    )
}

export default withRouter(observer(AdminBusinessesPage));

