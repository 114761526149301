import { Box, Grid, IconButton, Link, Paper } from '@mui/material';
import { BusinessOutlined, ComputerOutlined, KeyboardArrowLeft, KeyboardArrowRight, Phone } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getSiteHoursReport } from '../../../actions/internal/SiteActions';
import { getSiteById } from '../../../selectors/SiteSelectors';
import Colors from "../../../constants/Colors";
import { getMonthName } from "../../../utilities/DateUtilities";
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import StyledSubHeader from '../../common/StyledSubHeader';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import { ContainedButton } from '../../common/StyledButtons';
import { CARD_HEADER_STYLE } from '../../../constants/Styles';

const ViewSiteHoursComponent = ({ match }) => {
    const date = new Date();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingDate, setIsLoadingDate ] = useState(false);
    const [ currentDate, setCurrentDate ] = useState(date);
    const [ hoursReport, setHoursReport ] = useState();

    const siteId = match.params.site_id
    const site = getSiteById(siteId)
  
    useEffect(() => {
      loadData().then();
    }, [])
  
    const loadData = async () => {
      try {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

        const report = await getSiteHoursReport(siteId, startDate.getTime(), endDate.getTime());
        setHoursReport(report);
      } catch (ex) {
        console.error(ex);
      } finally {
        setIsLoading(false);
      }
    }

    const getMonthHours = async (date) => {
        try {
            setIsLoadingDate(true);
            const startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

            const report = await getSiteHoursReport(siteId, startDate.getTime(), endDate.getTime());
            setHoursReport(report)
        } catch(err) {
            console.error(err);
            showNotificationBar('error', 'An error has occurred while fetching hours. Please try again later.')
        } finally {
            setIsLoadingDate(false)
        }
    }

    const decrementMonth = () => {
        if (currentDate.getMonth() === 1) {
            currentDate.setMonth(12);
            currentDate.setFullYear(currentDate.getFullYear() - 1);
        } else {
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        const date = new Date(currentDate);
        setCurrentDate(date);
        getMonthHours(date);
    }

    const incrementMonth = () => {
        if (currentDate.getMonth() === 12) {
            currentDate.setMonth(1);
            currentDate.setFullYear(currentDate.getFullYear() + 1);
        } else {
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        const date = new Date(currentDate);
        setCurrentDate(date);
        getMonthHours(date);
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderQuickActionsSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Quick Actions</h5>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <Phone sx={{ color: Colors.primaryColor, fontSize: '64px' }} />
                    </Box>
                    <Box>
                        <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>Phone Call</div>
                        <p style={{ textAlign: 'center' }}>Schedule a time for BCK to give you a call.</p>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <ContainedButton onClick={() =>  window.open('https://consultwithkyle.com/appointments/quick-call/', '_blank')} sx={{ padding: '8px 16px 8px 16px' }}>Schedule</ContainedButton>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <ComputerOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />
                    </Box>
                    <Box>
                        <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>Virtual Consultation</div>
                        <p style={{ textAlign: 'center' }}>Plan a virtual meeting with BCK.</p>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <ContainedButton onClick={() =>  window.open('https://consultwithkyle.com/appointments/virtual-meeting', '_blank')} sx={{ padding: '8px 16px 8px 16px' }}>Schedule</ContainedButton>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <BusinessOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />
                    </Box>
                    <Box>
                        <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>On-Site Consultation</div>
                        <p style={{ textAlign: 'center' }}>Plan an in person meeting with BCK.</p>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <ContainedButton onClick={() =>  window.open('https://consultwithkyle.com/appointments/on-site-meeting/', '_blank')} sx={{ padding: '8px 16px 8px 16px' }}>Schedule</ContainedButton>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )

    const renderConsultingRatesSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Rates</h5>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <h4 style={{ margin: 0, fontSize: '24px' }}>General Consulting</h4>
                    <p>Receive help with technology guidance, setup assistance for online accounts and devices, and expert advice on SEO and enhancing your online presence.</p>
                    <h4 style={{ margin: 0, fontSize: '18px' }}>${(site.site_service_modules.consulting.general_consulting.pricing.default / 100).toFixed(2)}/hour</h4>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <h4 style={{ margin: 0, fontSize: '24px' }}>Development Consulting</h4>
                    <p>Provides service for creating and maintaining large systems, including software development, DevOps, cloud development, custom application management, and application deployment.</p>
                    <h4 style={{ margin: 0, fontSize: '18px' }}>${(site.site_service_modules.consulting.development_consulting.pricing.default / 100).toFixed(2)}/hour</h4>
                </Paper>
            </Grid>
        </Grid>
    )

    const renderConsultingHoursSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Hour Reports</h5>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <h4 style={{ margin: 0, fontSize: '24px' }}>{getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}</h4>
                        <div>
                            <IconButton onClick={decrementMonth}>
                                <KeyboardArrowLeft sx={{ color: Colors.primaryColor, fontSize: '24px' }}/>
                            </IconButton>
                            <IconButton onClick={incrementMonth} disabled={currentDate.getTime() >= Date.now()}>
                                <KeyboardArrowRight sx={{ color: Colors.primaryColor, fontSize: '24px' }}/>
                            </IconButton>
                        </div>
                    </Box>
                    {isLoadingDate ? renderLoadingContent() : 
                        <Box sx={{ marginBottom: '50px' }}>
                            {hoursReport.report.current_hours.time_entries.length === 0 &&
                                <p>No hours reported for this month.</p>
                            }
                        </Box>
                    }
                    <p style={{ fontSize: '13px', fontStyle: 'italic' }}>Consulting hours accumulated during a month will be invoiced on the 5th of the following month. You can view all of your current and past invoices <Link href={`/dashboard/site/${siteId}/billing`}>here</Link>.</p>
                </Paper>
            </Grid>
        </Grid>
    )

    return (
        <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <StyledSubHeader title="Consulting" />
            {isLoading ? renderLoadingContent() :
                <>
                    {renderQuickActionsSection()}
                    {renderConsultingHoursSection()}
                    {renderConsultingRatesSection()}
                </>
            }
        </Grid>
      </Grid>
    )


}

export default observer(withRouter(ViewSiteHoursComponent));
