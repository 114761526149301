import React, { useEffect, useState } from "react";
import { Avatar, Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import { AlternateEmailOutlined, AnalyticsOutlined, AssignmentOutlined, BusinessOutlined, Check, DashboardOutlined, Edit, LocationOnOutlined, PersonOutline, ReceiptOutlined } from "@mui/icons-material";
import { ContainedButton, TooltipIconButton } from "../../common/StyledButtons";
import Colors from "../../../constants/Colors";
import StyledSubHeader from "../../common/StyledSubHeader";
import { getSiteByIdForUser, getSiteSubscription } from "../../../actions/internal/SiteActions";
import { getSiteById, getSiteSubscriptionById } from "../../../selectors/SiteSelectors";
import StyledProgressCircle from "../../common/StyledProgressCircle";
import { getUserId } from "../../../selectors/UserSelectors";
import { CARD_HEADER_STYLE, ICON_TEXT_SECONDARY_FONT, SUMMARY_TITLE_FONT } from "../../../constants/Styles";
import StyledChip from "../../common/StyledChip";
import StyledLineItem from "../../common/StyledLineItem";
import { formatDateToFancyString } from "../../../utilities/DateUtilities";
import EditSiteAccessModal from "../modals/EditSiteAccessModal";
import IconTextSection from "../../common/IconTextSection";
import { capitalize } from "lodash";

const AdminManageBusinessPage = ({ history, match }) => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [ showEditAccessModal, setShowEditAccessModal ] = useState(false)
  
    const siteId = match.params.site_id
    const site = getSiteById(siteId);
    const subscription = getSiteSubscriptionById(siteId)


    useEffect(() => {
        loadData().then();
    }, [])
  
    const loadData = async () => {
        try {
            const userId = getUserId()
            await Promise.all([
                getSiteByIdForUser(siteId, userId),
                getSiteSubscription(userId, siteId)
            ])
        } catch (ex) {
            console.error(ex);
        } finally {
            setIsLoading(false);
        }
    }

    const prepareActions = () => {
    const tools = [
        {
            name: "Dashboard",
            icon: <DashboardOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
            description: 'Access the business full dashboard.',
            link: `/dashboard/site/${siteId}`
        },
        {
            name: "Projects",
            icon: <AssignmentOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
            description: 'Review and manage all projects.',
            link: `/dashboard/site/${siteId}/projects`
        },
        {
            name: "Activity",
            icon: <AnalyticsOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
            description: 'View the business page analytics.',
            link: `/dashboard/site/${siteId}/activity`
        },
        {
            name: "Billing",
            icon: <ReceiptOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
            description: 'View past and current invoices and receipts.',
            link: `/dashboard/site/${siteId}/billing`
        },
        
        ]
        return tools
    }

    const resolvePackageName = () => {
        if (!subscription || !subscription.site_package) return '---'
        return subscription.site_package.package_details.name;
    }

    const renderJson = (obj) => {
        return (
          <div>
            {Object.keys(obj).map((key) => (<div><b>{key}</b>: "{obj[key]}"</div>))}
          </div>
        )
      }

    const renderQuickActions = () => prepareActions().map((action) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={action.name}>
            <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                {action.icon}
            </Box>
            <Box>
                <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>{action.name}</div>
                <p style={{ textAlign: 'center' }}>{action.description}</p>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                <ContainedButton onClick={() =>  history.push(action.link)} sx={{ padding: '8px 16px 8px 16px' }}>View</ContainedButton>
            </Box>
            </Paper>
        </Grid>
    ))

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderBusinessDetails = () => (
        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
            <Grid item xs={12}>
                <h5 style={{ padding: 0, margin: 0}}>Business Details</h5>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', paddingBottom: '20px' }}>
                        <h4 style={{ margin: 0, fontSize: '24px' }}>{site.site_details.site_name}</h4>
                        <TooltipIconButton
                            text="Edit Business Account Details"
                            icon={<Edit sx={{ fontSize: '24px' }}/>}
                            onClick={() => history.push(`/admin/sites/${siteId}/details/edit`)}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <IconTextSection
                                icon={<BusinessOutlined style={{ color: Colors.primaryColor }}/>}
                                primaryText={site.site_details.site_structure}
                                secondaryText={<span>EIN: {site.site_details.site_ein ? site.site_details.site_ein : <i>Not Provided</i>}</span>}
                                primaryTextStyle={SUMMARY_TITLE_FONT}
                                secondaryTextStyle={ICON_TEXT_SECONDARY_FONT}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <IconTextSection
                                icon={<LocationOnOutlined style={{ color: Colors.primaryColor }}/>}
                                primaryText={'Address'}
                                secondaryText={<React.Fragment><div>{site.site_details.site_address.address_line_1} {site.site_details.site_address.address_line_2}</div><div>{site.site_details.site_address.city}, {site.site_details.site_address.state} {site.site_details.site_address.zipcode}</div></React.Fragment>}
                                primaryTextStyle={SUMMARY_TITLE_FONT}
                                secondaryTextStyle={ICON_TEXT_SECONDARY_FONT}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <IconTextSection
                                icon={<AlternateEmailOutlined style={{ color: Colors.primaryColor }}/>}
                                primaryText={'Recovery Email'}
                                secondaryText={<span>{site.site_details.site_recovery_email ? site.site_details.site_recovery_email : <i>Not Provided</i>}</span>}
                                primaryTextStyle={SUMMARY_TITLE_FONT}
                                secondaryTextStyle={ICON_TEXT_SECONDARY_FONT}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', paddingTop: '20px', paddingBottom: '20px' }}>
                        <h4 style={{ margin: 0, fontSize: '20px' }}>Connections</h4>
                    </Box>
                    <Grid container spacing={2}>
                        {Object.keys(site.site_external_connections).map((key) => (
                            <Grid item xs={12}>
                                <IconTextSection icon={<Check style={{ color: Colors.primaryColor }}/>}
                                    primaryText={capitalize(key)}
                                    secondaryText={renderJson(site.site_external_connections[key])}
                                    primaryTextStyle={SUMMARY_TITLE_FONT}
                                    secondaryTextStyle={ICON_TEXT_SECONDARY_FONT}
                                />
                            </Grid>
                        ))}
                        {Object.keys(site.site_external_connections).length === 0 && <Grid item xs={12}><p>No external connections.</p></Grid>}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )

    const renderBusinessSubscription = () => {
        let total = 0;
        if(subscription 
            && subscription.stripe_subscription 
            && subscription.stripe_subscription.status === 'active') {

            total = subscription.stripe_subscription.items.data.reduce((acc, val) => acc + val.price.unit_amount*val.quantity, 0)
        }
        return (
            <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
                <Grid item xs={12}>
                    <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Package</h5>
                </Grid>
                <Grid item xs={12}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', paddingBottom: '20px' }}>
                        <h4 style={{ margin: 0, fontSize: '24px' }}>Details</h4>
                        <TooltipIconButton
                            text="Edit Subscription"
                            icon={<Edit sx={{ fontSize: '24px' }}/>}
                            onClick={() => history.push(`/admin/sites/${siteId}/subscription/edit`)}
                        />
                    </Box>
                    {!subscription || !subscription.stripe_subscription || subscription.stripe_subscription.status !== 'active' ?
                        <>
                            <p>It looks like there isn't a package for this business account yet. Click the edit button to create one!</p>
                        </>
                        :
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={3}><StyledLineItem primary="Package Type" secondary={resolvePackageName()} sx={{ flexDirection: 'column' }} primarySx={{ fontSize: '20px' }}/></Grid>
                                <Grid item xs={6} sm={2}><StyledLineItem primary="Status" secondary="Active" sx={{ flexDirection: 'column' }} primarySx={{ fontSize: '20px' }}/></Grid>
                                <Grid item xs={6} sm={3}><StyledLineItem primary="Renewal Date" secondary={formatDateToFancyString(subscription.stripe_subscription.current_period_end * 1000)} sx={{ flexDirection: 'column' }} primarySx={{ fontSize: '20px' }}/></Grid>
                                <Grid item xs={6} sm={2}><StyledLineItem primary="Autopay" secondary={subscription.stripe_subscription.collection_method === "charge_automatically" ? "On" : "Off"} sx={{ flexDirection: 'column' }} primarySx={{ fontSize: '20px' }}/></Grid>
                                <Grid item xs={6} sm={2}></Grid>
                            </Grid>
                            <Box>
                                <div style={{ ...SUMMARY_TITLE_FONT, fontSize: '20px', marginTop: '20px' }}>Item Breakdown</div>
                                {subscription.stripe_subscription.items.data.map((item, index) => (
                                    <div>
                                        {index !== 0 && <Divider /> }
                                        <StyledLineItem sx={{ marginTop: '10px', marginBottom: '10px' }} primary={`${item.product.name} (x${item.quantity})`} secondary={`$${((item.price.unit_amount*item.quantity) / 100).toFixed(2)} `}/>
                                    </div>
                                ))}
                                <Divider sx={{ borderBottomWidth: 'medium' }} />
                                <StyledLineItem sx={{ marginTop: '10px', marginBottom: '10px' }} primary="Total" secondary={`$${(total/100).toFixed(2)}`}/>
                            </Box>
                        </>
                    }
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    const renderBusinessAccess = () => (
        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
            <Grid item xs={12}>
                <h5 style={{ padding: 0, margin: 0}}>Access</h5>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined" style={{ padding: '24px 16px 24px 16px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <h4 style={{ margin: 0, fontSize: '24px' }}>Accounts</h4>
                    <TooltipIconButton
                        text="Manage User Access"
                        icon={<Edit sx={{ fontSize: '24px' }}/>}
                        onClick={() => setShowEditAccessModal(true)}
                    />
                    </Box>
                    
                    <List style={{ flex: 1 }}>
                    {site && site.site_security.accessible_users.map((user) => (
                        <ListItem
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                            <ListItemAvatar>
                                <Avatar><PersonOutline /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                            {user.is_primary_user && (<ListItemSecondaryAction>
                                <StyledChip text="Primary" />
                            </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    )

    const renderBusinessQuickActions = () => (
        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
            <Grid item xs={12}>
                <h5 style={{ padding: 0, margin: 0}}>Quick Actions</h5>
            </Grid>
            {renderQuickActions()}
        </Grid>
    )

    // Implement Search and Pagination next
    return (
        <Grid container>
            <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
                <StyledSubHeader title="Manage Business Account" />
                {isLoading ? renderLoadingContent() :
                    <div>
                        {renderBusinessQuickActions()}
                        {renderBusinessDetails()}
                        {renderBusinessSubscription()}
                        {renderBusinessAccess()}
                    </div>
                }
            </Grid>
            <EditSiteAccessModal
                open={showEditAccessModal}
                onClose={() => setShowEditAccessModal(false)}
                site={site}
            />
        </Grid>
    )
}

export default withRouter(observer(AdminManageBusinessPage));
