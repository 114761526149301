import React, { useState } from 'react';
import findKey from 'lodash/findKey';
import { Box, Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import {NOTIFICATION_TYPE, showNotificationBar} from '../../../actions/error-pages/NotificationBarActions';
import { getUserId, isUserAdmin } from '../../../actions/profile/UserActions';
import { LoadingButton, TextButton } from '../../common/StyledButtons';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { updateSiteSubmission } from '../../../actions/internal/SiteActions';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_LOOKUP } from '../../../constants/Statuses';
import { StyledMultilineTextField, StyledTextField } from '../../common/StyledTextField';
import { StyledDropDown } from '../../common/StyledDropDown';

const EditRequestModal = ({ open, onClose, onSave, request, match }) => {

  const [ status, setStatus ] = useState();
  const [ values, setValues ] = useState({})
  const [errors, setErrors] = useState([])

  const [ isLoading, setIsLoading ] = useState(false);

  const setFieldValue = (id, value) => {
    setValues({ ...values, [id]: value })
  }

  const clearForm = () => {
    setStatus();
    setValues({});
    setErrors([]);
  }

  const handleClose = () => {
    clearForm();
    onClose();
  }

  const validateFields = () => {
    return Object.keys(values).filter(key => !values[key] || values[key].trim() === '');
  }

  const handleUpdateRequest = async () => {
    try {
      if (!values && !status) { handleClose(); return; }
      setIsLoading(true)

      // validate payload
      const errors = validateFields();
      if (errors.length > 0) {
        setErrors(errors)
        return;
      }

      // send payload to update request
      const masks = ['submission_fields']
      const payload = {
        submission_fields: { ...request.submission_fields, ...values }
      }
      if (status) {
        payload.submission_status = SUBMISSION_STATUS[status]
        masks.push('submission_status')
      }

      const site_id = request.submission_identifiers.site ? request.submission_identifiers.site.site_id : undefined;
      await updateSiteSubmission(getUserId(), site_id, request.submission_id, masks, payload)
      showNotificationBar(NOTIFICATION_TYPE.SUCCESS, "This request has been updated successfully!");
      clearForm();
      onSave();
    } catch(ex) {
      showNotificationBar(NOTIFICATION_TYPE.ERROR, "An error has occurred while canceling this request. If this continues to happen, please contact us.");
    } finally {
      setIsLoading(false)
    }
  }

  const { submission_status, submission_fields } = request || {}
  const defaultFieldValues = submission_fields || {}
  const isAdmin = isUserAdmin();

  return (
    <>
      <Dialog
          fullWidth
          open={open}
          maxWidth={'md'}
      >
          <DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box>
                <h4 style={{ margin: 0, padding: 0 }}>Edit Request</h4>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              {isAdmin && <StyledDropDown
                label="Status"
                value={status || findKey(SUBMISSION_STATUS, (o) => o === submission_status)}
                onChange={(e) => setStatus(e.target.value)}
              >
                {Object.keys(SUBMISSION_STATUS).map(key => (
                  <MenuItem key={key} value={key}>{SUBMISSION_STATUS_LOOKUP[SUBMISSION_STATUS[key]].label}</MenuItem>
                ))}
              </StyledDropDown> }
              <StyledTextField
                label="Title"
                onChange={(e) => setFieldValue('title', e.target.value)}
                error={errors.includes('title')}
                value={values['title'] || defaultFieldValues['title'] || ''}
              />
              <StyledTextField
                label="Url"
                onChange={(e) => setFieldValue('url', e.target.value)}
                error={errors.includes('url')}
                value={values['url'] || defaultFieldValues['url'] || ''}
              />
              <StyledMultilineTextField
                label="Description"
                onChange={(e) => setFieldValue('description', e.target.value)}
                error={errors.includes('description')}
                value={values['description'] || defaultFieldValues['description'] || ''}
              />
            </Box>
          </DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '16px'}}>
              <TextButton sx={{ marginRight: '10px' }} onClick={handleClose} disabled={isLoading}>Cancel</TextButton>
              <LoadingButton onClick={handleUpdateRequest} isLoading={isLoading}>Save</LoadingButton>
            </Box>
      </Dialog>
    </>
  )
}

export default observer(withRouter(EditRequestModal));
