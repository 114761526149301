import React from "react";
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { TooltipIconButton } from "./StyledButtons";

const StyledListItem = ({
icon,
 primary,
 secondary,
 actionProps,
 actionTooltipText = 'Click me!',
 actionIcon,
 ...props
}) => (
  <ListItem
    dense
    sx={{ backgroundColor: '#fff', marginBottom: '5px' }}
    {...props} >
      <ListItemAvatar>
          {icon}
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
          <TooltipIconButton
              text={actionTooltipText}
              icon= {actionIcon}
              {...actionProps}
          />
      </ListItemSecondaryAction>
    </ListItem>
)

export default StyledListItem;

/*

*/