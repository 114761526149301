import React from 'react';
import { Paper, Grid } from '@mui/material';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { isValidEmail } from '../../../utilities/Validation';
import { createNewUser, findUser } from '../../../clients/bettercallkyle/BckApi';
import { registerProfile } from '../../../clients/profile/ProfileClient';
import { showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import {StyledTextField} from "../../common/StyledTextField";
import { LoadingButton, TextButton } from "../../common/StyledButtons";
import {DesktopBox, MobileBox} from "../../common/Boxes";
import {LINK_TEXT_STYLE} from "../../../constants/Styles";

const RegistrationForm = ({ history }) => {

  const [ firstName, setFirstName ] = useState();
  const [ lastName, setLastName ] = useState();
  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState();
  const [ confirmPassword, setConfirmPassword ] = useState();

  const [ errors, setErrors ] = useState([]);
  const [ isLoading, setIsLoading ] = useState();

  const handleLoginOnClick = (e) => {
    e.stopPropagation();
    history.push('/login');
  }

  const handleShowTermsAndConditions = (event) => {
    var win = window.open('/terms', '_blank');
    win.focus();
  }

  const handleShowPrivacyPolicy = (event) => {
    var win = window.open('/privacy', '_blank');
    win.focus();
  }

  const isValidForm = () => {
    const foundErrors = [];
    if (!firstName || isEmpty(firstName)) foundErrors.push('firstName');
    if (!lastName || isEmpty(lastName)) foundErrors.push('lastName');
    if (!email || isEmpty(email) || !isValidEmail(email)) foundErrors.push('email');
    const capitalVal = (/[A-Z]/.test(password));
    const numberVal = (/\d/.test(password));
    if (!password || password.length < 8 || !capitalVal || !numberVal) foundErrors.push('password');
    if (!foundErrors.includes('password') && password !== confirmPassword) foundErrors.push('confirmPassword');
    setErrors(foundErrors);
    return foundErrors.length === 0;
  }

  const handleRegister = async () => {

    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      let user = await findUser(email).catch((err) => { return; });
      if (!user || user.code === 'no-user-exists') {
        user = await createNewUser(firstName, lastName, email);
      }
      await registerProfile(email, password, firstName, lastName, user.user_id);
      history.push({
        pathname: '/verify',
        state: { user: { email, password }}
      });
    } catch (err) {
      if(err.code === 'UsernameExistsException') {
        setErrors(['email'])
        showNotificationBar('error', 'This email has already been registered. Please try logging in.');
      } else {
        console.error(err);
        showNotificationBar('error', 'Oh no! Something went wrong when creating the account. Please try again.');
      }
    } finally {
      setIsLoading(false)
    }
  }

  const renderRegistrationSection = () => (
    <div style={{ marginTop: '30px', marginBottom: '30px', paddingLeft: '16px', paddingRight: '16px' }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={11}>
            <h5 style={{ textAlign: 'center', margin: 0 }}>BCK Business Manager</h5>
            <h3 style={{ textAlign: 'center', margin: 0 }}>Create an Account</h3>
            <p style={{ textAlign: 'center' }}>Simply provide some information below and let's get started.</p>
          </Grid>
        </Grid>
        <Grid container spacing={1} justify='center'>
          <Grid item xs={12} md={6}>
            <StyledTextField
              autoFocus
              error={errors.includes('firstName')}
              value={firstName}
              disabled={isLoading}
              type="text"
              label="*First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              error={errors.includes('lastName')}
              value={lastName}
              disabled={isLoading}
              type="text"
              label="*Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <StyledTextField
              error={errors.includes('email')}
              value={email}
              disabled={isLoading}
              type="email"
              label="*E-Mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              error={errors.includes('password')}
              value={password}
              disabled={isLoading}
              type="password"
              label="*Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              error={errors.includes('confirmPassword')}
              value={confirmPassword}
              disabled={isLoading}
              type="password"
              label="*Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 30}} justifyConetnt='center' >
          <Grid item xs={12} sm={12}>
            <p>By clicking 'I Agree & Continue' below you are accepting the <span style={LINK_TEXT_STYLE}
            onClick={handleShowTermsAndConditions}
            >Terms and Conditions</span> and the <span style={LINK_TEXT_STYLE} onClick={handleShowPrivacyPolicy}
            >Privacy Policy</span> of Better Call Kyle, LLC.</p>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs={'auto'}>
            <TextButton
              onClick={handleLoginOnClick}
              disabled={isLoading}
              >Sign In To Existing Account</TextButton>
          </Grid>
          <Grid item xs={'auto'}>
            <LoadingButton
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleRegister}
              >I Agree & Continue</LoadingButton>
          </Grid>
        </Grid>
      </div>
  )

  return (
    <div>
      <DesktopBox sx={{justifyContent: 'center', width: '100%'}}>
        <Paper sx={{ minWidth: '600px', maxWidth: { lg: '60%', md: '50%' }, marginTop: '5%' }} variant='outlined'>
          {renderRegistrationSection()}
        </Paper>
      </DesktopBox>
      <MobileBox sx={{flexDirection: 'column', justifyContent: 'center', height: '90vh', backgroundColor: '#fff'}} >
        {renderRegistrationSection()}
      </MobileBox>
    </div>
  )
}

export default RegistrationForm;
