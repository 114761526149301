import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MasterComponent from './MasterComponent';
import * as serviceWorker from './serviceWorker';
import './styles/animate.min.css';
import WebFont from 'webfontloader';

WebFont.load({
    google: {
      families: ['Raleway', 'Nunito+Sans', 'Roboto+Condensed', 'Lora', 'Georgia', 'Rock+Salt', 'sans-serif']
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MasterComponent />
  </React.StrictMode>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
