export const getMonthName = (monthIndex) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex];
}

export const getCurrentMonthName = (epoch = Date.now()) => {
    const d = new Date(epoch);
    return getMonthName(d.getMonth());
}

export const getCurrentYear = (epoch = Date.now()) => {
    const d = new Date(epoch);
    return d.getFullYear();
}

export const formatDateToFancyString = (epoch = Date.now()) => 
    new Date(epoch).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 

export const formatDateTimeToFancyString = (epoch = Date.now()) => 
    new Date(epoch).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric"}) 
    
export const formatSecondsToPrettyMSTime = (time) => {
    if (time < 0 || time === undefined) return undefined;
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes < 10 ? '0' + String(minutes) : String(minutes)}m ${seconds < 10 ? '0' + String(seconds) : String(seconds)}s`
}

export const formatSecondsToPrettyHMTime = (seconds) => {
    const time = seconds / 60
    const hours = (time / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rhours + "h " + rminutes + "m";
}