import React from "react";
import { withRouter } from "react-router-dom";
import {AppBar, Toolbar, Menu, MenuItem, IconButton, Box} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useState } from "react";
import { TEST_MODE } from "../../constants/System";
import {MENU_ITEM_STYLE} from "../../constants/Styles";
import StyledLogo from "../common/StyledLogo";
import {ContainedButton, NavTextButton} from "../common/StyledButtons";
import {DesktopBox, MobileBox} from "../common/Boxes";
import Colors from "../../constants/Colors";

const ExternalHeader = ({ history }) => {

    const [ menuElement, setMenuElement ] = useState();

    const handleMenuOpen = event => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(undefined);
    };

    const handleNavigate = (url) => {
        window.location.href = url;
    }

    const handleMyAccountOnClick = (e) => {
        e.stopPropagation();
        if(history.location.pathname !== '/register') {
            history.push('/register');
        }
    }

    const renderMenu = () => (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={menuElement !== undefined}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handleNavigate('https://consultwithkyle.com/#services')} sx={MENU_ITEM_STYLE} >SERVICES</MenuItem>
            <MenuItem onClick={() => handleNavigate('https://consultwithkyle.com/resources')} sx={MENU_ITEM_STYLE} >RESOURCES</MenuItem>
            <MenuItem onClick={() => handleNavigate('https://consultwithkyle.com/contact-us')} sx={MENU_ITEM_STYLE} >CONTACT</MenuItem>
            <MenuItem onClick={handleMyAccountOnClick} sx={MENU_ITEM_STYLE} >CREATE AN ACCOUNT</MenuItem>
        </Menu>
    )

    return (
        <div style={{ marginTop: '70px' }}>
            <AppBar position="fixed" elevation={3} sx={{ backgroundColor: '#fff', height: 70, paddingTop: '5px', }}>
                { TEST_MODE && <div style={{ position: 'fixed', top: 0, left: '50%', backgroundColor: '#f00', padding: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, fontSize: 12 }}>TEST MODE</div> }
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <StyledLogo onClick={() => handleNavigate('https://consultwithkyle.com')} />
                    </Box>
                    <DesktopBox sx={{ flexGrow: 1, display: { sm: 'none', md: 'flex' }, justifyContent: 'flex-end', gap: '14px' }}>
                        <NavTextButton
                          onClick={() => handleNavigate('https://consultwithkyle.com/#services')}
                        >
                            Services
                        </NavTextButton>
                        <NavTextButton
                          onClick={() => handleNavigate('https://consultwithkyle.com/resources')}
                        >
                            Resources
                        </NavTextButton>
                        <NavTextButton
                          onClick={() => handleNavigate('https://consultwithkyle.com/contact-us')}
                        >
                            Contact
                        </NavTextButton>
                        <ContainedButton
                            onClick={handleMyAccountOnClick}
                            style={{ paddingTop: '0px', paddingBottom: '0px' }}
                        >
                            Create An Account
                        </ContainedButton>
                    </DesktopBox>
                    <MobileBox sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                        <IconButton
                          onClick={handleMenuOpen}
                          color="inherit"
                        >
                            <MenuIcon style={{ fontSize: 24, color: '#000' }}/>
                        </IconButton>
                    </MobileBox>
                </Toolbar>
            </AppBar>

            {renderMenu()}
        </div>
        );
}

export default withRouter(ExternalHeader);
