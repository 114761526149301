import React from "react";
import { Table, TableCell, TableHead, TableBody, TableRow } from "@mui/material";
import { TABLE_BODY_STYLE, TABLE_HEADER_STYLE } from "../../constants/Styles";

const StyledDataTable = ({
  columns = [], // list of strings
  rows = [], // list of objects with key as column name
  sx,
  ...props
}) => (
    <Table sx={{ fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell component="th" sx={{ '&.MuiTableCell-root': TABLE_HEADER_STYLE }}>{column}</TableCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody >
            {rows !== undefined && rows.map((row) => (
                <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                >
                    {columns.map(column => (
                        <TableCell component="td" scope="row" sx={{ '&.MuiTableCell-root': TABLE_BODY_STYLE }}>{row[column]}</TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    </Table>
)

export default StyledDataTable;
