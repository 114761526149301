import {
  Grid,
  Box
} from "@mui/material";
import { ResponsiveContainer, Area, AreaChart } from 'recharts';
import {
  AssessmentOutlined
} from "@mui/icons-material";
import React from "react";
import Colors from "../../../../constants/Colors";
import Paper from "@mui/material/Paper";
import {CARD_CAPTION_STYLE} from "../../../../constants/Styles";
import {ContainedButton} from "../../../common/StyledButtons";
import {withRouter} from "react-router";
import {getSiteAnalyticsById} from "../../../../selectors/SiteSelectors";
import {observer} from "mobx-react-lite";
import { formatSecondsToPrettyMSTime } from "../../../../utilities/DateUtilities";

const ActivityCard = ({ match, history }) => {
  const siteId = match.params.site_id
  const analytics = getSiteAnalyticsById(siteId)

  const prepareFormatteedTime = () => {
    if (!analytics
    || !analytics.report
    || !analytics.report.time_average
    || !analytics.report.time_average.count) return '---';
    return formatSecondsToPrettyMSTime(analytics.report.time_average.count)
  }

  const prepareVisitorData = () => {
    if (!analytics.report.visitors) return []
    return analytics.report.visitors.timeline.map((record) => ({
      uv: record.count
    }))
  }

  const renderVisitorsSection = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', position: 'relative', zIndex: 15, gap: '48px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <h6 style={{ fontSize: '20px' }}>Visitors</h6>
        <h3 style={{ margin: 0, marginBottom: '-11px' }}>{analytics.report.visitors ? analytics.report.visitors.count : '---'}</h3>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <h6 style={{ fontSize: '20px' }}>Avg. Duration</h6>
        <Box>
          <h3 style={{ margin: 0, fontSize: '28px', padding: 0 }}>{prepareFormatteedTime()}</h3>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
          <div style={CARD_CAPTION_STYLE}>Activity</div>
          <AssessmentOutlined sx={{ color: '#666' }}/>
        </Box>
        {renderVisitorsSection()}
        <Box sx={{ zIndex: 15 }}>
          { analytics.message === "no-clicky-connected" && <p>Analytics are not enabled for your account. If you would like them to be enabled, please contact us.</p>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '32px', position: 'relative', zIndex: 15}}>
          <ContainedButton
            onClick={() => history.push(`/dashboard/site/${siteId}/activity`)}
            sx={{ padding: '8px 16px 8px 16px' }}
          >Explore</ContainedButton>
        </Box>
        <Box sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%', padding: 0 }}>
          <ResponsiveContainer width="100%" height={200} style={{ padding: 0 }}>
            <AreaChart data={prepareVisitorData()}>
              <Area type="monotone" dataKey="uv" stroke={Colors.primaryColor} dot={false} fill={Colors.primaryColor} fillOpacity={0.05} />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Grid>
  )
}

export default observer(withRouter(ActivityCard));
