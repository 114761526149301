import React, { useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useRef } from 'react';
import {showNotificationBar} from '../../../actions/error-pages/NotificationBarActions';
import { getUserId } from '../../../actions/profile/UserActions';
import { uploadSiteImage } from '../../../clients/bettercallkyle/BckApi';
import { STANDARD_PARAGRAPH_STYLE } from '../../../constants/Styles';
import { ContainedButton, LoadingButton, TextButton } from '../../common/StyledButtons';
import { observer } from 'mobx-react-lite';
import { getSiteByIdForUser } from '../../../actions/internal/SiteActions';

const UpdateSiteImageModal = ({ open, onClose, site }) => {

    const fileUploaderRef = useRef(null);

    const fileResultRef = useRef();
    const filePropsRef = useRef();

    const [fileName, setFileName] = useState();
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const handleClose = () => {
        fileUploaderRef.current = null;
        fileResultRef.current = undefined;
        filePropsRef.current = undefined;
        setFileName()
        setIsUploadingImage(false)
        setIsLoading(false)
        setError()
        onClose();
    }

    const handleAddDocumentRequest = (files) => {
        const file = files[0];
        const document_extention = file.name.substr(file.name.lastIndexOf('.'));
        const reader = new FileReader();
        setIsUploadingImage(true);
        reader.addEventListener("load", () => {
            let encoded_image = reader.result;
            fileResultRef.current = encoded_image;
            encoded_image = encoded_image.substr(encoded_image.indexOf(',')+1);
            filePropsRef.current = { encoded_image, document_extention }
            setFileName(file.name);
            setIsUploadingImage(false);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleSave = async () => {
        if (!filePropsRef.current) {
            setError('Please provide valid information for all fields.')
            return;
        }
        try {
            setIsLoading(true)
            const doc = {
                directory: 'site_img',
                type: filePropsRef.current.document_extention,
                encoded: filePropsRef.current.encoded_image
            }
            await uploadSiteImage(site.site_id, getUserId(), doc)
            await getSiteByIdForUser(site.site_id, getUserId(), true)
            showNotificationBar('success', "The site's image has been updated successfully!");
            handleClose();
        } catch(err) {
            console.error(err)
            showNotificationBar('error', 'An error occurred while setting the site image.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={'md'}
        >
            <DialogTitle>
                <h4 style={{ margin: 0, padding: 0 }}>Update Business Avatar</h4>
                <p style={STANDARD_PARAGRAPH_STYLE}>Provide a business logo to display. The suggested dimensions are 500x500 pixels.</p>
                {error !== undefined &&
                    <p style={{ color: '#f90506' }}>{error}</p>}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Box
                        component="img"
                        sx={{
                            height: 200,
                            width: 200,
                            maxHeight: { xs: 150, md: 200 },
                            maxWidth: { xs: 150, md: 200 },
                        }}
                        src={(fileResultRef.current) || (site && site.site_details.site_img_url)}
                    />
                </Box>
               
            </DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '16px'}}>
                <ContainedButton disabled={isUploadingImage} onClick={() => fileUploaderRef.current.click()}>
                    {fileName || (site && site.site_details.site_img_url) ? 'Change Image' : 'Select Image'}
                    <input type='file' accept=".png,.jpg,.jpeg,.svg,.webp" id='multi' onChange={(e) => handleAddDocumentRequest(e.target.files)} ref={fileUploaderRef} style={{ display: 'none' }} />
                </ContainedButton>
                <Box>
                    <TextButton sx={{ marginRight: '10px' }} onClick={handleClose} disabled={isLoading}>Cancel</TextButton>
                    <LoadingButton onClick={handleSave} isLoading={isLoading} disabled={isLoading || isUploadingImage}>Save</LoadingButton>
                </Box>
            </Box>
        </Dialog>
    )
}

export default observer(UpdateSiteImageModal);
