import React from "react";

const FixedBackground = ({ background }) => (
  <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%', zIndex: -99 }}>
    <img src={background} alt={"logo"} style={{
      height: '100%',
      width:'auto'
    }} />
  </div>
)

export default FixedBackground;
