import {
  getJob,
  getJobs
} from "../../clients/bettercallkyle/BckApi";
import {toJS} from "mobx";
import {getJobForSiteById, getJobsBySiteId} from "../../selectors/JobSelectors";
import JobStore from "../../stores/internal/JobStore";
import { getUserId } from "../../selectors/UserSelectors";
import { ACTIVE_JOB_STATUSES, JOB_STATUS_MAP, INACTIVE_JOB_STATUES, ACTION_REQUIRED_JOB_STATUSES, JOB_GROUPS } from "../../constants/Statuses";

const sortJobs = (jobs) => {
  return ({
      [JOB_GROUPS.DRAFT]: jobs.filter(job => job.job_details.job_status === JOB_STATUS_MAP.DRAFT),
      [JOB_GROUPS.ACTIVE]: jobs.filter(job => ACTIVE_JOB_STATUSES.includes(job.job_details.job_status)),
      [JOB_GROUPS.INACTIVE]: jobs.filter(job => INACTIVE_JOB_STATUES.includes(job.job_details.job_status)),
      [JOB_GROUPS.ACTION_REQUIRED]: jobs.filter(job => ACTION_REQUIRED_JOB_STATUSES.includes(job.job_details.job_status)),
      [JOB_GROUPS.COMPLETED]: jobs.filter(job => job.job_details.job_status === JOB_STATUS_MAP.COMPLETED)
  })
}

export const getJobsForSite = async (site_id, force_refresh = false) => {
  const jobsBySite = toJS(getJobsBySiteId(site_id))
  if (!jobsBySite || force_refresh) {
    const jobs = await getJobs(site_id, getUserId());
    JobStore.setJobsBySite(site_id, jobs);
    JobStore.setGroupedJobsBySite(site_id, sortJobs(jobs))
    return jobs
  } else {
    return jobsBySite
  }
}

export const getJobForSite = async (site_id, job_id, force_refresh = false) => {
  const jobsBySite = toJS(getJobForSiteById(site_id, job_id))
  if (!jobsBySite || force_refresh) {
    const job = await getJob(site_id, job_id, getUserId());
    JobStore.setJobBySite(site_id, job);
    return job;
  } else {
    return jobsBySite
  }
}
