import { Box, Grid, List, Paper } from "@mui/material";
import React, { useRef, useState } from "react";
import { StyledMultilineTextField, StyledTextField } from "./StyledTextField";
import { ContainedButton, TextButton } from "./StyledButtons";
import { generateShortUIDString, toBase64 } from "../../utilities/StringFormatUtilities";
import StyledListItem from "./StyledListItem";
import { AttachmentOutlined, DeleteForeverOutlined } from "@mui/icons-material";
import { showNotificationBar } from "../../actions/error-pages/NotificationBarActions";

const FILE_UPLOAD_LIMIT = 5;
const IGNORE_FIELD_TYPES = ['upload']

const StyledForm = ({
  fields,
  onClick,
  primaryActionText = 'Submit',
  secondaryActionText = 'Cancel',
  onSecondaryAction
}) => {

  const fieldValues = useRef({});
  const fileUploaderRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);
  const [attachments, setAttachments] = useState([])
  const [errors, setErrors] = useState([])

  const setFieldValue = (id, value) => {
    fieldValues.current[id] = value;
  }

  const handleAddDocumentRequest = async (files) => {
    try {
      let arrayOfFiles = Array.from(files);
      setIsUploading(true)
      if (attachments.length + arrayOfFiles.length > FILE_UPLOAD_LIMIT) {
        const acceptedFileCount = FILE_UPLOAD_LIMIT - attachments.length < 0 ? 0 : FILE_UPLOAD_LIMIT - attachments.length;
        arrayOfFiles = arrayOfFiles.slice(0, acceptedFileCount)
        showNotificationBar('error', `You cannot have more than ${FILE_UPLOAD_LIMIT} files attached.`)
      }
      
      const atmts = await Promise.all(arrayOfFiles.map(async (file) => {
        const document_extention = file.name.substr(file.name.lastIndexOf('.'));
        if (file) {
            const resp = await toBase64(file).then(str => str.substr(str.indexOf(',')+1));
            return { document_encoded: resp, document_extention, document_file_name: file.name, id: generateShortUIDString() };
        }
        return;
      }))
      setAttachments([ ...attachments, ...atmts ])
    } catch (err) {
      console.error(err)
    } finally {
      setIsUploading(false)
    }
  }

  const handleRemoveDocument = (id) => {
    const atmts = attachments.filter((att) => att.id !== id)
    setAttachments(atmts)
  }

  const handleSubmit = () => {
    const fieldsInError = fields.filter((field) => {
      return field.isRequired
        && !IGNORE_FIELD_TYPES.includes(field.type)
        && (fieldValues.current[field.id] === undefined || fieldValues.current[field.id] === '')
    })
    if (fieldsInError.length > 0) {
      setErrors(fieldsInError.map(field => field.id))
      return;
    }
    onClick({ fields: fieldValues.current, attachments })
    clearForm();
  }

  const clearForm = () => {
    fieldValues.current = {};
    setErrors([])
    setAttachments([])
  }

  const renderInputField = ({ label, id, type, isRequired, ...fieldProps }) => {
    switch(type) {
      case 'multiline':
        return (
          <StyledMultilineTextField
            label={`${label}${isRequired ? '*' : ''}`}
            onChange={(e) => setFieldValue(id, e.target.value)}
            error={errors.includes(id)}
            {...fieldProps}
          />
        )
      case 'upload':
        const { fileTypes, multiple } = fieldProps;
        return (
          <Paper variant='outlined' sx={{ borderRadius: 0, borderColor: '#cdcdcd', padding: '14px', marginTop: '8px' }}>
            <h6 style={{ fontSize: '20px', margin: 0, padding: 0, fontWeight: 500, color: '#666' }}>{label}{isRequired ? '*' : ''}</h6>
            <p style={{ marginTop: '5px', paddingTop: 0, marginBottom: 0 }}>{isUploading ? 'Uploading files...' : fieldProps.helperText}</p>
            <p style={{ marginTop: '5px', paddingTop: 0 }}><i>A maximum of {FILE_UPLOAD_LIMIT} files can be uploaded.</i></p>
            <Box>
              <List>
                {attachments.map(att => (
                  <StyledListItem
                    primary={att.document_file_name}
                    actionIcon={<DeleteForeverOutlined />}
                    icon={<AttachmentOutlined />}
                    actionTooltipText="Remove attachment"
                    actionProps= {{ onClick: () => handleRemoveDocument(att.id) }}
                    />
                  ))
                }
              </List>
            </Box>
            <TextButton onClick={() => fileUploaderRef.current.click()} sx={{ paddingLeft: 0, paddingRight: 0, marginTop: '20px' }} disabled={ attachments.length >= 5 }>
                Select Documents
                <input type='file' accept={ fileTypes || ".pdf,.png,.jpg,.txt,.jpeg,.svg" } id='multi' onChange={(e) => handleAddDocumentRequest(e.target.files)} ref={fileUploaderRef} style={{ display: 'none' }} multiple={multiple} />
            </TextButton>
          </Paper>
         
        )
      case 'input':
      default:
        return (<StyledTextField
            label={`${label}${isRequired ? '*' : ''}`}
            onChange={(e) => setFieldValue(id, e.target.value)}
            error={errors.includes(id)}
            {...fieldProps}
          />)
    }
  }

  return (
    <Grid container>
      {fields.map((field) => (
        <Grid item xs={12}>
          {renderInputField(field)}
        </Grid>
      ))}
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '30px' }}>
          { onSecondaryAction && <TextButton sx={{ marginRight: '10px' }} onClick={onSecondaryAction}>{secondaryActionText}</TextButton> }
          <ContainedButton onClick={handleSubmit} >{primaryActionText}</ContainedButton>
        </Grid>
    </Grid>
  )
}

export default StyledForm;
