import { cloneDeep } from "lodash";
import {
  fetchAllSitesForUser,
  fetchSite,
  fetchSiteHoursReport,
  fetchSitePaymentReport,
  fetchSiteSubscription,
  getSiteWebsiteReport,
  queryAllSubmissions,
  updateSite,
  updateSubmission,
  updateUserAccessToSite
} from "../../clients/bettercallkyle/BckApi";
import SitesStore from "../../stores/internal/SitesStore";
import { toJS } from "mobx";

export const getAllSitesForUser = async (user_id, force_refresh = false) => {
  if (!force_refresh && SitesStore.sites !== undefined) {
    return cloneDeep({ sites: SitesStore.sites });
  }
  if (user_id === undefined) {
    throw new Error("No user id provided");
  }
  const response = await fetchAllSitesForUser(user_id);
  SitesStore.setSites(response.sites);
  return response;
}

// MORE SECURE
export const getSiteByIdForUser = async (site_id, user_id, force_refresh = false) => {
  const sitesById = toJS(SitesStore.siteById)
  if (!sitesById[site_id] || force_refresh) {
    const site = await fetchSite(site_id, user_id);
    sitesById[site_id] = site;
    SitesStore.setSite(sitesById);
    return site
  } else {
    return sitesById[site_id]
  }
}

// DEPRECATED - use getSiteByIdForUser
export const getSiteById = async (site_id, force_refresh = false) => {
  const sitesById = toJS(SitesStore.siteById)
  if (!sitesById[site_id] || force_refresh) {
    const site = await fetchSite(site_id);
    sitesById[site_id] = site;
    SitesStore.setSite(sitesById);
    return site
  } else {
    return sitesById[site_id]
  }
}

export const getSiteAnalytics = async (site_id, force_refresh = false) => {
  if (!force_refresh
    && SitesStore.site_analytics[site_id] !== undefined) {
      return cloneDeep(SitesStore.site_analytics[site_id])
  }
  const analytics = await getSiteWebsiteReport(site_id);
  SitesStore.setSiteAnalytics(site_id, analytics);
  return analytics;
}

export const getSiteHoursReport = async (site_id, start = undefined, end = undefined, force_refresh = false) => {
  let key = `${site_id}`;
  let timeKey = `${start}|${end}`
  if (!force_refresh
    && SitesStore.site_hour_reports[key] !== undefined
    && SitesStore.site_hour_reports[key][timeKey] !== undefined) {
    return cloneDeep(SitesStore.site_hour_reports[key][timeKey])
  }
  const report = await fetchSiteHoursReport(site_id, start, end);
  SitesStore.setSiteHourReport(key, timeKey, report);
  return report;
}

export const getSitePaymentReport = async (site_id, force_refresh = false) => {
  try {
    if (!force_refresh && SitesStore.site_invoice_reports[site_id] !== undefined) {
      return cloneDeep(SitesStore.site_invoice_reports[site_id])
    }
    const report = await fetchSitePaymentReport(site_id)
    SitesStore.setSiteInvoiceReport(site_id, report);
    return report;
  } catch (err) {
    const error = await err;
    throw error
  }
}

export const getSiteSubscription = async (user_id, site_id, force_refresh = false) => {
  try {
    if (!force_refresh && SitesStore.site_subscriptions[site_id] !== undefined) {
      return cloneDeep(SitesStore.site_subscriptions[site_id])
    }
    const response = await fetchSiteSubscription(user_id, site_id);
    SitesStore.setSiteSubscriptionReport(site_id, response);
    return response;
  } catch (err) {
    const error = await err;
    throw error;
  }
}

export const updateSiteAccess = async (site_id, user_id, removed_users, added_users) => {
  await updateUserAccessToSite(site_id, user_id, removed_users, added_users);
  await getSiteByIdForUser(site_id, user_id, true)
}

export const updateSiteInformation = async (site_id, user_id, masks, maskProps) => {
  await updateSite(site_id, user_id, masks, maskProps)
  await getSiteByIdForUser(site_id, user_id, true)
}

export const getSiteSubmissions = async (site_id, force_refresh = false) => {
  try {
    if (!force_refresh && SitesStore.site_submissions[site_id] !== undefined) {
      return cloneDeep(SitesStore.site_submissions[site_id])
    }
    const response = await queryAllSubmissions(undefined, site_id, true);
    SitesStore.setSiteSubmissions(site_id, response || []);
    return response;
  } catch (err) {
    const error = await err;
    throw error;
  }
}

export const updateSiteSubmission = async (user_id, site_id, submission_id, masks, maskProps) => {
  await updateSubmission(submission_id, user_id, masks, maskProps);
  await getSiteSubmissions(site_id, true);
}
