import React, { useRef, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { beginForgottenPasswordProcess, resendVerificationCode, resetForgottenPassword } from '../../../clients/profile/ProfileClient';
import Colors from '../../../constants/Colors';
import { isValidEmail } from '../../../utilities/Validation';
import {showNotificationBar} from "../../../actions/error-pages/NotificationBarActions";
import {StyledTextField} from "../../common/StyledTextField";
import {LoadingButton, TextButton} from "../../common/StyledButtons";
import { DesktopBox, MobileBox } from "../../common/Boxes";

const ForgotPasswordForm = ({ location, history }) => {

  const [hasResentCode, setHasRecentCode] = useState();
  const [hasSentCode, setHasSentCode] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errors, setErrors] = useState([]);

  const userRef = useRef(location.state ? location.state.user : undefined);

  const goToSignIn = () => {
    history.replace('/login')
  }

  const handleResendCode = async event => {
    try {
      if (userRef.current.email === undefined) history.push('/login');
      await resendVerificationCode(userRef.current.email);
      setHasRecentCode(true);
      showNotificationBar('info', 'Verification code sent.');
    } catch(err) {
      console.error('resend code error', err)
      showNotificationBar('error', 'An issue happened when sending the verification code.');
    }
  }

const isFormValid = () => isValidEmail(email)

const isPasswordFormValid = () => {
  const foundErrors = [];
  const capitalVal = (/[A-Z]/.test(newPassword));
  const numberVal = (/\d/.test(newPassword));
  if (!code || code.length !== 6) foundErrors.push('verificationCode');
  if (!newPassword || newPassword.length < 8 || !capitalVal || !numberVal) foundErrors.push('newPassword');
  if (!foundErrors.includes('password') && newPassword !== confirmNewPassword) foundErrors.push('confirmNewPassword');
  setErrors(foundErrors);
  return foundErrors.length === 0;
}

const handleSubmitEmailToResetPassword = async () => {
    try {
      if (!isFormValid()) {
        setErrorMessage('Please enter a valid email')
        return;
      }
      setIsLoading(true)
      let username = email.toLowerCase();
      await beginForgottenPasswordProcess(username);
      setHasSentCode(true);
      setErrorMessage(undefined);
    } catch (e) {
      console.error('LOGIN ERROR', e)
      setErrorMessage(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangePassword = async () => {
    try {
      if (isPasswordFormValid().length > 0) {
        setErrorMessage('Please enter a valid email')
        return;
      }
      setIsLoading(true)
      let username = email.toLowerCase();
      await resetForgottenPassword(username, code, newPassword);
      setHasSentCode(true);
      setErrorMessage(undefined);
      showNotificationBar('success', 'Successfully updated your password! Please try to login now.')
      history.push('/login')
    } catch (e) {
      console.error('LOGIN ERROR', e)
      setErrorMessage('Uh oh! Something went wrong, please double check your verification code.')
    } finally {
      setIsLoading(false)
    }
  }

  const renderForm = () => (
    <>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={11}>
          <h3 style={{ textAlign: 'center' }}>Find Your Account</h3>
          <p style={{ textAlign: 'center' }}>Enter the email associated with your account.</p>
          { errorMessage &&
            <div style={{ color: Colors.errorOutline }}>{errorMessage}</div>
          }
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: '16px', paddingBottom: '24px'}}>
          <StyledTextField
            autoFocus
            key='email'
            value={email}
            label="Email"
            onKeyPress={(e) => {if (e.key === 'Enter') { handleSubmitEmailToResetPassword();}}}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
        <Grid item xs={'auto'}>
          <TextButton
            onClick={goToSignIn}
            disabled={hasResentCode }
          >Sign Into Existing Account</TextButton>
        </Grid>
        <Grid item xs={'auto'}>
          <LoadingButton
            onClick={handleSubmitEmailToResetPassword} disabled={isLoading} isLoading={isLoading}
          >Recover Account</LoadingButton>
        </Grid>
      </Grid>
    </>
  )

  const renderResetPasswordForm = () => (
    <>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={11}>
          <h3 style={{ textAlign: 'center' }}>Find Your Account</h3>
          <p style={{ textAlign: 'center' }}>We emailed you a code. Provide it below along with the new password for your account.</p>
          { errorMessage &&
            <div style={{ color: Colors.errorOutline }}>{errorMessage}</div>
          }
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 16, paddingBottom: 0}}>
          <StyledTextField
            key='code'
            value={code}
            error={errors.includes('verificationCode')}
            helperText={errors.includes('verificationCode') && 'This is not valid. The code sent should have been 6 digits.'}
            label="Verification Code"
            onChange={(e) => setCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 16, paddingBottom: 0}}>
          <StyledTextField
            error={errors.includes('newPassword')}
            helperText={errors.includes('newPassword') && 'A password must be 8 characters containing a capital letter and a number.'}
            value={newPassword}
            type="password"
            label="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 0, paddingBottom: 60}}>
          <StyledTextField
            error={errors.includes('confirmNewPassword')}
            helperText={errors.includes('confirmNewPassword') && 'The passwords did not match.'}
            value={confirmNewPassword}
            type="password"
            label="Confirm New Password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
        <Grid item xs={'auto'}>
          <TextButton
            onClick={handleResendCode}
            disabled={isLoading || hasResentCode }
          >Resend Code</TextButton>
        </Grid>
        <Grid item xs={'auto'}>
          <LoadingButton
            onClick={handleChangePassword}
            disabled={isLoading}
            isLoading={isLoading}
            >Reset Password</LoadingButton>
        </Grid>
      </Grid>
    </>
  )

  return (
    <div>
      <DesktopBox sx={{justifyContent: 'center', width: '100%'}}>
        <Paper sx={{ minWidth: '600px', marginTop: '5%', paddingBottom: '24px', maxWidth: { lg: '60%', md: '50%' } }} variant='outlined'>
          {hasSentCode ? renderResetPasswordForm() : renderForm()}
        </Paper>
      </DesktopBox>
      <MobileBox sx={{flexDirection: 'column', justifyContent: 'center', height: '90vh', backgroundColor: '#fff'}} >
        {hasSentCode ? renderResetPasswordForm() : renderForm()}
      </MobileBox>
    </div>
  )
}

export default ForgotPasswordForm;
