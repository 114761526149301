import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Grid, MenuItem, Paper } from '@mui/material';
import { Store } from "@mui/icons-material";
import { isEmpty, isEqual } from "lodash";
import { showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { getSiteByIdForUser, updateSiteInformation } from "../../../actions/internal/SiteActions";
import { isValidEmail } from "../../../utilities/Validation";
import { getClockifyClients, getClockifyProjects } from "../../../clients/clockify/ClockifyApi";
import { COMPANY_TYPES } from "../../../constants/Common";
import UpdateSiteImageModal from "../modals/UpdateSiteImageModal";
import { SITE_STATUSES } from "../../../constants/Statuses";
import StyledSubHeader from "../../common/StyledSubHeader";
import { StyledTextField } from "../../common/StyledTextField";
import { getSiteById } from "../../../selectors/SiteSelectors";
import StyledProgressCircle from "../../common/StyledProgressCircle";
import { LoadingButton, TextButton } from "../../common/StyledButtons";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";

const AdminEditBusinessAccountPage = ({ classes, history, users, match }) => {

    const originalFields = useRef();

    const selectableClockifyProjects = useRef([])
    const selectableClockifyClients = useRef([])

    const [fields, setFields] = useState();

    const [showAddSiteImage, setShowAddSiteImage] = useState(false)

    const [errors, setErrors] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    const siteId = match.params.site_id
    const site = getSiteById(siteId)

    useEffect(() => {
        loadSession()
    }, [])

    const loadSession = async () => {
        try {
            const siteId = match.params.site_id
            const userId = getUserId()
            const siteObj = await getSiteByIdForUser(siteId, userId);

            selectableClockifyProjects.current = await getClockifyProjects();
            selectableClockifyClients.current = await getClockifyClients();

            const primaryUser = siteObj.site_security.accessible_users.find(user => user.is_primary_user)
            const fields = {
                primaryUser: primaryUser ? primaryUser.email : '',
                companyStatus: siteObj.site_details.site_status,
                companyName: siteObj.site_details.site_name,
                companyStructure: siteObj.site_details.site_structure,
                companyAddressLine1: siteObj.site_details.site_address.address_line_1,
                companyAddressLine2: siteObj.site_details.site_address.address_line_2,
                companyCity: siteObj.site_details.site_address.city,
                companyState: siteObj.site_details.site_address.state,
                companyZipCode: siteObj.site_details.site_address.zipcode,
                companyEin: siteObj.site_details.site_ein,

                // external connections
                companyRecoveryEmail: siteObj.site_details.site_recovery_email,
                boxUrl: siteObj.site_external_connections.box ? siteObj.site_external_connections.box.box_url : undefined,
                wordpressLoginUrl: siteObj.site_external_connections.wordpress ? siteObj.site_external_connections.wordpress.wordpress_login_url : undefined,
                clockifyClient: siteObj.site_external_connections.clockify ? siteObj.site_external_connections.clockify.client_id : undefined,
                clickyId: siteObj.site_external_connections.clicky ? siteObj.site_external_connections.clicky.clicky_id : undefined,
                clickyKey: siteObj.site_external_connections.clicky ? siteObj.site_external_connections.clicky.clicky_key: undefined,
                uptimeId: siteObj.site_external_connections.uptime ? siteObj.site_external_connections.uptime.uptime_robot_id : undefined,
                stripeCustomerId: siteObj.site_external_connections.stripe ? siteObj.site_external_connections.stripe.customer_id : undefined,
                stripeSubscriptionId: siteObj.site_external_connections.stripe ? siteObj.site_external_connections.stripe.subscription_id : undefined
            }
            originalFields.current = { ...fields };
            setFields(fields)
        } catch (err) {
            showNotificationBar('error', 'Could not load site to edit at this time. Please try again later.');
            console.error(err)
            history.goBack()
        } finally {
            setIsLoading(false)
        }
    }

    const handleFieldChange = (key, value) => {
        const updatedFields = { ...fields };
        updatedFields[key] = value;
        setFields(updatedFields)
    }

    const isValidForm = () => {
        const foundErrors = [];
        if (!fields.primaryUser) foundErrors.push('primaryUser')
        if (!fields.companyName || isEmpty(fields.companyName)) foundErrors.push('companyName');
        if (!fields.companyStatus) foundErrors.push('companyStatus');
        if (!fields.companyStructure || isEmpty(fields.companyStructure)) foundErrors.push('companyStructure');
        if (!fields.companyAddressLine1 || isEmpty(fields.companyAddressLine1)) foundErrors.push('companyAddressLine1')
        if (!fields.companyCity || isEmpty(fields.companyCity)) foundErrors.push('companyCity')
        if (!fields.companyState || isEmpty(fields.companyState)) foundErrors.push('companyState')
        if (!fields.companyZipCode || isEmpty(fields.companyZipCode) || isNaN(fields.companyZipCode) || Number(fields.companyZipCode) < 10000 || Number(fields.companyZipCode) > 99999 ) foundErrors.push('companyZipCode')
        if (!isEmpty(fields.companyRecoveryEmail) && !isValidEmail(fields.companyRecoveryEmail)) foundErrors.push('recoveryEmail');

        setErrors(foundErrors)
        return foundErrors.length === 0
    }

    const preparePayload = () => { // NEED TO PREP PAYLOAD TO EXPECT EMAIL INSTEAD OF USER ID!
        const masks = []
        const maskProps = {}
        const primaryUser = site.site_security.accessible_users.find(user => user.is_primary_user) || {};
        if (fields.primaryUser !== primaryUser.email) { masks.push('primary_user_email'); maskProps.primary_user_email = fields.primaryUser }
        if (fields.companyName !== site.site_details.site_name) { masks.push('site_name'); maskProps.site_name = fields.companyName }
        if (fields.companyStatus !== site.site_details.site_status) { masks.push('site_status'); maskProps.site_status = fields.companyStatus }
        if (fields.companyStructure !== site.site_details.site_structure) { masks.push('site_structure'); maskProps.site_structure = fields.companyStructure; }
        if (fields.companyAddressLine1 !== site.site_details.site_address.address_line_1) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, address_line_1: fields.companyAddressLine1 } }
        if (fields.companyAddressLine2 !== site.site_details.site_address.address_line_2) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, address_line_2: fields.companyAddressLine2 } }
        if (fields.companyCity !== site.site_details.site_address.city) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, city: fields.companyCity } }
        if (fields.companyState !== site.site_details.site_address.state) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, state: fields.companyState } }
        if (fields.companyZipCode !== site.site_details.site_address.zipcode) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, zipcode: fields.companyZipCode } }
        if (fields.companyRecoveryEmail !== site.site_details.site_recovery_email && (!isEmpty(fields.companyRecoveryEmail) && !site.site_details.site_recovery_email)) { masks.push('site_recovery_email'); maskProps.site_recovery_email = fields.companyRecoveryEmail }

        // let externalMaskProps = site.site_external_connections

        const updatedExternalProps = {
            ...site.site_external_connections,
            clockify: { client_id: fields.clockifyClient },
            wordpress: { wordpress_login_url: fields.wordpressLoginUrl },
            box: { box_url: fields.boxUrl },
            stripe: {
                customer_id: fields.stripeCustomerId,
                subscription_id: fields.stripeSubscriptionId
            },
            uptime: {
                uptime_robot_id: fields.uptimeId
            }
        }

        if (!isEqual(updatedExternalProps, site.site_external_connections)) {
            masks.push('site_external_connections')
            maskProps.site_external_connections = updatedExternalProps
        }

        // merge existing site address with edits
        if (maskProps.site_address !== undefined) maskProps.site_address = { ...site.site_details.site_address, ...maskProps.site_address }
        return { masks, maskProps }
    }

    const handleUpdateSite = async () => {
        // If no change then backout
        if (isEqual(fields, originalFields.current)) {
            history.goBack()
            return;
        }

        if(!isValidForm()) return;
        try {
            setIsLoading(true);

            const sitePayload = preparePayload()
            await updateSiteInformation(site.site_id, getUserId(), sitePayload.masks, sitePayload.maskProps)
            showNotificationBar('success', `${fields.companyName} has been updated successfully!`);
            history.goBack();
        } catch(err) {
            const resp = await err;
            console.error(resp);
            showNotificationBar('error', 'Uh oh! The business account could not update the site at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderAccountDetailsSection = () => (
        <Grid container spacing={1} sx={{ marginBottom: '24px' }} >
            <Grid item xs={12}>
                <StyledTextField
                    select
                    error={errors.includes('primaryUser')}
                    label="*Primary User"
                    value={fields.primaryUser}
                    onChange={(e) => handleFieldChange('primaryUser', e.target.value)}
                >
                    {site.site_security.accessible_users.map((user) => (
                    <MenuItem key={user.email} value={user.email}>
                        {user.first_name} {user.last_name} <span style={{fontSize: 16, paddingLeft:5}}>({user.email})</span>
                    </MenuItem>
                    ))}
                </StyledTextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    select
                    error={errors.includes('companyStatus')}
                    label="*Account Status"
                    value={fields.companyStatus}
                    onChange={(e) => handleFieldChange('companyStatus', e.target.value)}
                >
                    {SITE_STATUSES.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                        {status.label}
                    </MenuItem>
                    ))}
                </StyledTextField>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Paper variant='outlined' sx={{ borderRadius: 0, borderColor: '#cdcdcd', padding: '10px', marginTop: '6px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h6 style={{ fontSize: '20px', marginTop: '5px', padding: 0, fontWeight: 500, color: '#666' }}>Account Avatar</h6>
                    {site && site.site_details && site.site_details.site_img_url ?
                    <Avatar alt={fields.companyName} src={site.site_details.site_img_url} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => setShowAddSiteImage(true)} />
                    :
                    <Avatar style={{ marginRight: 10, cursor: 'pointer'  }} onClick={() => setShowAddSiteImage(true)} ><Store /></Avatar>
                    }
                </Paper>
            </Grid>
        </Grid>
    )

    const renderCompanyInfoSection = () => (
        <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('companyName')}
                    value={fields.companyName}
                    label="*Company/Organization Name"
                    onChange={(e) => handleFieldChange('companyName', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    select
                    error={errors.includes('companyStructure')}
                    label="*Business Structure"
                    value={fields.companyStructure}
                    onChange={(e) => handleFieldChange('companyStructure', e.target.value)}
                >
                    {COMPANY_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </StyledTextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    error={errors.includes('companyEin')}
                    value={fields.companyEin}
                    label="Employer ID Number (EIN)"
                    onChange={(e) => handleFieldChange('companyEin', e.target.value)}
                />
            </Grid>
        </Grid>
    )

    const renderCompanyAddressSection = () => (
        <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('companyAddressLine1')}
                    value={fields.companyAddressLine1}
                    label="*Street Address"
                    onChange={(e) => handleFieldChange('companyAddressLine1', e.target.value)}
                />
                </Grid>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('companyAddressLine2')}
                    value={fields.companyAddressLine2}
                    label="Unit/Apt/Suite"
                    onChange={(e) => handleFieldChange('companyAddressLine2', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <StyledTextField
                    error={errors.includes('companyCity')}
                    value={fields.companyCity}
                    label="*City"
                    onChange={(e) => handleFieldChange('companyCity', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <StyledTextField
                    error={errors.includes('companyState')}
                    value={fields.companyState}
                    label="*State"
                    onChange={(e) => handleFieldChange('companyState', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <StyledTextField
                    error={errors.includes('companyZipCode')}
                    value={fields.companyZipCode}
                    label="*Zip Code"
                    onChange={(e) => handleFieldChange('companyZipCode', e.target.value)}
                />
            </Grid>
        </Grid>
    )

    const renderExternalConnectionsSection = () => (
        <Grid container spacing={1}>
            <Grid item xs={12}><h5 style={{ margin: 0, fontSize: '16px', paddingTop: '16px' }}>Account Recovery</h5></Grid>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('recoveryEmail')}
                    label="Recovery Email"
                    value={fields.companyRecoveryEmail}
                    onChange={(e) => handleFieldChange('recoveryEmail', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}><h5 style={{ margin: 0, fontSize: '16px', paddingTop: '16px' }}>Cloud Storage</h5></Grid>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('boxUrl')}
                    label="Box URL"
                    value={fields.boxUrl}
                    onChange={(e) => handleFieldChange('boxUrl', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}><h5 style={{ margin: 0, fontSize: '16px', paddingTop: '16px' }}>Project Management</h5></Grid>
            <Grid item xs={12}>
                <StyledTextField
                    select
                    error={errors.includes('clockifyClient')}
                    label="Clockify Client"
                    value={fields.clockifyClient}
                    disabled={isLoading}
                    onChange={(e) => handleFieldChange('clockifyClient', e.target.value)}
                >
                    {selectableClockifyClients.current.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                            {client.name}
                        </MenuItem>
                    ))}
                </StyledTextField>
            </Grid>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('wordpressLoginUrl')}
                    label="Wordpress Login URL"
                    value={fields.wordpressLoginUrl}
                    onChange={(e) => handleFieldChange('wordpressLoginUrl', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}><h5 style={{ margin: 0, fontSize: '16px', paddingTop: '16px' }}>Analytics</h5></Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    error={errors.includes('clickyId')}
                    label="Clicky ID"
                    value={fields.clickyId}
                    onChange={(e) => handleFieldChange('clickyId', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    error={errors.includes('clickyKey')}
                    label="Clicky Key"
                    value={fields.clickyKey}
                    onChange={(e) => handleFieldChange('clickyKey', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('uptimeId')}
                    label="Uptime ID"
                    value={fields.uptimeId}
                    onChange={(e) => handleFieldChange('uptimeId', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}><h5 style={{ margin: 0, fontSize: '16px', paddingTop: '16px' }}>Revenue Management</h5></Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    error={errors.includes('stripeCustomerId')}
                    label="Stripe Customer ID"
                    value={fields.stripeCustomerId}
                    onChange={(e) => handleFieldChange('stripeCustomerId', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField
                    error={errors.includes('stripeSubscriptionId')}
                    label="Stripe Subscription ID"
                    value={fields.stripeSubscriptionId}
                    onChange={(e) => handleFieldChange('stripeSubscriptionId', e.target.value)}
                />
            </Grid>
        </Grid>
    )

    return (
        <div>
             <Grid container>
                <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
                    <StyledSubHeader title="Edit Business Account" closeIcon />
                    {isLoading ? renderLoadingContent() :
                        <Paper variant="outlined" sx={{ padding: '16px', marginTop: '20px'}}>
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Account Information</h4>
                            <p>Provide the primary contact and the status of the account for billing and communication purposes.</p>
                            {renderAccountDetailsSection()}
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Company Information</h4>
                            <p>Include the legal name and business structure of the business as well as the EIN if available.</p>
                            {renderCompanyInfoSection()}
                            <h4 style={{ margin: 0, fontSize: '24px' }}>Legal Address</h4>
                            <p>Provide the legal business address. If none exists, please provide the home address. This information is securely saved, and will never be shared externally.</p>
                            {renderCompanyAddressSection()}
                            <h4 style={{ margin: 0, fontSize: '24px' }}>External Connections</h4>
                            <p>Add or edit connections to external accounts.</p>
                            {renderExternalConnectionsSection()}
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <TextButton onClick={() => history.goBack() } sx={{ marginRight: '10px' }}>
                                    Cancel
                                </TextButton>
                                <LoadingButton
                                    isLoading={isLoading}
                                    disabled={isEqual(fields, originalFields.current)}
                                    onClick={handleUpdateSite} color="primary">Save</LoadingButton>
                            </Box>
                            
                        </Paper>
                    }
                    
                </Grid>
            </Grid>
            <UpdateSiteImageModal
                site={site}
                open={showAddSiteImage} onClose={() => setShowAddSiteImage(false)} />
        </div>
    )
}

export default withRouter(observer(AdminEditBusinessAccountPage));

/*
<Paper>
                        <div style={{padding: 16}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Company Info</div>
                                </Grid>
                                <Grid item xs={10} md={8}>
                                    <TextField
                                    error={errors.includes('companyName')}
                                    fullWidth
                                    value={companyName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Company/Organization Name"
                                    variant="outlined"
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} md={4} style={{ marginTop: 10 }}>
                                    {site && site.site_details && site.site_details.site_img_url ?
                                    <Avatar alt={companyName} src={site.site_details.site_img_url} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => setShowAddSiteImage(true)} />
                                    :
                                    <Avatar style={{ marginRight: 10, cursor: 'pointer'  }} onClick={() => setShowAddSiteImage(true)} ><Store /></Avatar>
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    error={errors.includes('companyStructure')}
                                    fullWidth
                                    className={classes.textField}
                                    label="*Business Structure"
                                    variant="outlined"
                                    value={companyStructure}
                                    onChange={(e) => setCompanyStructure(e.target.value)}
                                >
                                    {COMPANY_TYPES.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    error={errors.includes('companyEin')}
                                    fullWidth
                                    value={companyEin}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="Employer ID Number (EIN)"
                                    variant="outlined"
                                    onChange={(e) => setCompanyEin(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Legal Address</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Please provide your legal business address. If you do not yet have one, please provide your current address. This information is securely saved, and will never be shared externally.</div>
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    error={errors.includes('companyAddressLine1')}
                                    fullWidth
                                    value={companyAddressLine1}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Street Address"
                                    variant="outlined"
                                    onChange={(e) => setCompanyAddressLine1(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    error={errors.includes('companyAddressLine2')}
                                    fullWidth
                                    value={companyAddressLine2}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="Unit/Apt/Suite"
                                    variant="outlined"
                                    onChange={(e) => setCompanyAddressLine2(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    error={errors.includes('companyCity')}
                                    fullWidth
                                    value={companyCity}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*City"
                                    variant="outlined"
                                    onChange={(e) => setCompanyCity(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    error={errors.includes('companyState')}
                                    fullWidth
                                    value={companyState}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*State"
                                    variant="outlined"
                                    onChange={(e) => setCompanyState(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    error={errors.includes('companyZipCode')}
                                    fullWidth
                                    value={companyZipCode}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Zip Code"
                                    variant="outlined"
                                    onChange={(e) => setCompanyZipCode(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Additional Info</div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        error={errors.includes('recoveryEmail')}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        label="Recovery Email"
                                        value={companyRecoveryEmail}
                                        variant="outlined"
                                        onChange={(e) => setCompanyRecoveryEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        error={errors.includes('boxUrl')}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        label="Box URL"
                                        value={boxUrl}
                                        variant="outlined"
                                        onChange={(e) => setBoxUrl(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        error={errors.includes('clockifyClient')}
                                        fullWidth
                                        className={classes.textField}
                                        label="Clockify Client"
                                        variant="outlined"
                                        value={clockifyClient}
                                        disabled={isLoading}
                                        onChange={(e) => setClockifyClient(e.target.value)}
                                    >
                                        {selectableClockifyClients.current.map((client) => (
                                            <MenuItem key={client.id} value={client.id}>
                                                {client.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                            </Grid>
                        </div>
                    </Paper>
                    <Grid container justify='flex-end' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <Button
                                className={classes.buttonContainedStyle}
                                variant='contained'
                                disabled={isLoading}
                                onClick={handleUpdateSite} color="primary">
                                { isLoading ?
                                <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                : <span>Update Site</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
*/
