import React, { useRef, useState } from 'react';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { Delete, PersonAddOutlined, PersonOutline, UndoOutlined } from '@mui/icons-material';
import Colors from '../../../constants/Colors';
import { STANDARD_PARAGRAPH_STYLE } from '../../../constants/Styles';
import { ContainedButton, LoadingButton, TextButton, TooltipIconButton } from '../../common/StyledButtons';
import { StyledTextField } from '../../common/StyledTextField';
import { isValidEmail } from '../../../utilities/Validation';
import { NOTIFICATION_TYPE, showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { updateSiteAccess } from '../../../actions/internal/SiteActions';
import { getUserId } from '../../../selectors/UserSelectors';
import { generateShortUIDString } from '../../../utilities/StringFormatUtilities';

const AddLineItemModal = ({ open, onClose, onAdd }) => {

    const addedLineItemRef = useRef({});

    const [ showAddUserModal, setShowAddUserModal ] = useState(false);
    const [ removedUsers, setRemovedUsers ] = useState([]);
    const [ addedUsers, setAddedUsers ] = useState([])
    const [ errors, setErrors ] = useState([])
    const [ isSaving, setIsSaving ] = useState(false)
    const [ total, setTotal ] = useState();

    const validateLineItem = () => {
        const { item_name, item_units, item_unit_cost } = addedLineItemRef.current;
        const errors = [];
        if (!item_name || item_name.trim() === '') errors.push('item_name');
        if (item_unit_cost === undefined || item_unit_cost < 0) errors.push('item_unit_cost');
        if (item_units === undefined || item_units < 0 || item_units % 1 !== 0) errors.push('item_units');

        setErrors(errors);

        return errors.length === 0;
    }

    const resetModal = () => {
        addedLineItemRef.current = {};
        setTotal(undefined);
    }

    const handleSave = async () => {
        if (!validateLineItem()) return;
        const item = {
            ...addedLineItemRef.current,
            item_total: total,
            item_unit_cost: Math.floor(addedLineItemRef.current.item_unit_cost * 100),
            item_id: generateShortUIDString()
        }
        onAdd(item);
        resetModal();
    }

    const handleClose = () => {
        resetModal();
        onClose();
    }

    const calculateTotal = () => {
        const { item_units, item_unit_cost } = addedLineItemRef.current;
        if (item_units === undefined
            || item_unit_cost === undefined
            || isNaN(item_units)
            || isNaN(item_unit_cost)) {
                setTotal(undefined);
        } else {
            const total = Math.floor(parseFloat(item_unit_cost) * 100) * parseFloat(item_units);
            setTotal(Math.floor(parseFloat(item_unit_cost) * 100) * parseFloat(item_units))
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={'md'}
        >
            <DialogTitle>
                <h4 style={{ margin: 0, padding: 0 }}>Add Line Item</h4>
                <p style={ STANDARD_PARAGRAPH_STYLE }>Provide the information below to create a single line item for this project.</p>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12} >
                        <StyledTextField
                            type="text"
                            label="Item Name"
                            error={errors.includes('item_name')}
                            onChange={(e) => addedLineItemRef.current.item_name = e.target.value}
                            onBlur={calculateTotal}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledTextField
                            type="number"
                            label="Item Price"
                            error={errors.includes('item_unit_cost')}
                            onChange={(e) => addedLineItemRef.current.item_unit_cost = e.target.value}
                            onBlur={calculateTotal}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledTextField
                            type="number"
                            label="Number of Units"
                            error={errors.includes('item_units')}
                            onChange={(e) => addedLineItemRef.current.item_units = e.target.value}
                            onBlur={calculateTotal}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <h6 style={{ margin: 0, padding: 0, fontSize: '18px' }}>Total: ${total ? (total / 100).toFixed(2) : '-.--'}</h6>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <TextButton onClick={handleClose}>Cancel</TextButton>
                <ContainedButton  onClick={handleSave}>Add</ContainedButton>
            </DialogActions>
        </Dialog>
    )
}

export default AddLineItemModal;

