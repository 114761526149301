import NotificationBarStore from "../../stores/error-pages/NotificationBarStore";

export const NOTIFICATION_TYPE = {
    INFO: 'info',
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'success'
}

export const showNotificationBar = (variant = NOTIFICATION_TYPE.INFO, message = 'Something happened') => {
    NotificationBarStore.showBar(variant, message);
}

export const resetNotificationBar = () => {
    NotificationBarStore.setShowBar(false);
    NotificationBarStore.setMessage(undefined);
}
