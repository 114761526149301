import React from "react";
import {Route, Switch} from 'react-router-dom';
import '../../styles/external/external-styles.css';
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GenericNotFound from "../error-pages/GenericNotFound";
import NewCompanyForm from "./pages/NewCompanyForm";
import VerificationForm from "./pages/VerificationForm";
import RegistrationForm from "./pages/RegistrationForm";
import LoginForm from "./pages/LoginForm";
import ExternalHeader from "./ExternalHeader";
import ForgotPasswordForm from "./pages/ForgotPasswordForm";
import background from "../../assets/backgrounds/brick-background.jpeg";
import FixedBackground from "../common/FixedBackground";

const ExternalRouter = () => (
    <div>
        <FixedBackground background={background} />
        <ExternalHeader />
        <Switch>
          <Route exact path="/" render={routeProps => <LoginForm {...routeProps} />} />
          <Route exact path="/login" render={routeProps => <LoginForm {...routeProps} />} />
          <Route exact path="/terms" render={routeProps => <TermsAndConditions {...routeProps} />} />
          <Route exact path="/privacy" render={routeProps => <PrivacyPolicy {...routeProps} />} />
          <Route exact path="/register" render={routeProps => <RegistrationForm {...routeProps} />} />
          <Route exact path="/verify" render={routeProps => <VerificationForm {...routeProps} />} />
          <Route exact path="/reset-password" render={routeProps => <ForgotPasswordForm {...routeProps} />} />
          <Route exact path="/welcome" render={routeProps => <NewCompanyForm {...routeProps} />} />
          <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
        </Switch>
    </div>
)

export default ExternalRouter;
