import React from 'react';
import { Grid } from '@mui/material';
import {ContainedButton} from "../common/StyledButtons";
import Paper from "@mui/material/Paper";

const ErrorPanel = ({ message }) => {
    const handleRefreshPage = () => {
        window.location.reload();
    }

    return (
        <Grid container justifyContent='center' sx={{ marginTop: '100px' }}>
            <Grid item xs={11} sm={9} md={8} lg={7}>
                <Paper variant="outlined" sx={{ padding: '32px' }}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={10}>
                            <h4 style={{ textAlign: 'center', margin: 0 }}>Uh Oh!</h4>
                            <p style={{ textAlign: 'center' }}>{message}</p>
                        </Grid>
                        <Grid item xs={'auto'} style={{ marginTop: 35}}>
                            <ContainedButton
                              onClick={handleRefreshPage}
                            >Reload Page</ContainedButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ErrorPanel;
