import { FormControlLabel, Switch, TextField } from "@mui/material";
import React from "react";
import {SUMMARY_TITLE_FONT, TABLE_BODY_STYLE } from "../../constants/Styles";

const StyledSwitch = ({
  children,
  textFieldStyle = {},
  title,
  description,
  ...props
}) => (
  <FormControlLabel
    control={<Switch {...props} /> }
    label={
      <div style={{ ...SUMMARY_TITLE_FONT, fontSize: 16 }}>{title}: <span style={{ ...TABLE_BODY_STYLE, fontSize: 16, fontWeight: 'normal' }}>{description}</span></div>
    }
  />
)

export default StyledSwitch;
