import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Colors from '../../constants/Colors';

const StyledProgressCircle = ({ sx, ...props }) => (
    <CircularProgress style={{ color: Colors.primaryColor, ...sx }} thickness={3} {...props} />
)

export const StyledLoadingSection = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <StyledProgressCircle />
    </Box>
)

export default StyledProgressCircle;
