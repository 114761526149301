import { LOCAL_BCK_API, LOCAL_BOOKKEEPING_API } from "../../constants/System";

const BCK_API_V2_URL = LOCAL_BCK_API ? 'http://localhost:6969/v2/' : 'https://ehmejvr6bg.execute-api.us-east-2.amazonaws.com/prod/';
const BCK_BOOKKEEPING_API_URL = LOCAL_BOOKKEEPING_API ? 'http://localhost:7777/v2/' : 'https://xkahxzm19i.execute-api.us-east-2.amazonaws.com/prod/';

const __post = async (url, payload) => {
  try {
    return await fetch(url, {
      method: 'post',
      body : payload,
      //mode: 'no-cors'
    })
    .then(resp => {
      if (!resp.ok || resp.status >= 400) throw resp.json()
      return resp.json()
    })
    .catch(error => {
        throw error;
    })
  } catch (err) {
    throw err
  }
}

const __patch = async (url, payload) => {
  try {
    return await fetch(url, {
      method: 'PATCH',
      body : payload,
      //mode: 'no-cors'
    })
    .then(resp => {
      if (!resp.ok || resp.status >= 400) throw resp.json()
      return resp.json()
    })
    .catch(error => {
        throw error;
    })
  } catch (err) {
    throw err
  }
}

const __delete = async (url, payload) => {
  try {
    return await fetch(url, {
      method: 'DELETE',
      body : payload,
      //mode: 'no-cors'
    })
    .then(resp => {
      if (!resp.ok || resp.status >= 400) throw resp.json()
      return resp.json()
    })
    .catch(error => {
        throw error;
    })
  } catch (err) {
    throw err
  }
}

const __get = async (url) => {
  try {
    return await fetch(url)
    .then(resp => {
      if (!resp.ok || resp.status >= 400) {
        throw resp.json()
      }
      if (resp.status === 204) { // no content
        return null
      } 
      return resp.json()
    })
    .catch(error => {
        throw error
    })
  } catch (err) {
    throw err
  }
}


/***************************/
/***    GET API CALLS    ***/
/***************************/

export const getUser = async (user_id) => {
  return __get(BCK_API_V2_URL + `user/${user_id}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const fetchSite = async (site_id, user_id) => {
  let url = BCK_API_V2_URL + `site/${site_id}`;
  if (user_id) url += `?user_id=${user_id}`
  return __get(url)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const updateSite = async (site_id, user_id, masks, maskProps) => {
  const payload = { user_id, site_id, masks, ...maskProps };
  return __patch(BCK_API_V2_URL + `site`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const updateSubmission = async (submission_id, user_id, masks, maskProps) => {
  const payload = { user_id, submission_id, masks, ...maskProps };
  return __patch(BCK_API_V2_URL + `submission`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const updateUser = async (user_id, requesting_user_id, masks, maskProps) => {
  const payload = { user_id, requesting_user_id, masks, ...maskProps };
  return __patch(BCK_API_V2_URL + `user`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const fetchAllSitesForUser = async (user_id) => {
  return __get(BCK_API_V2_URL + `user/${user_id}/sites`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getUserPaymentReport = async (user_id) => {
  return __get(BCK_BOOKKEEPING_API_URL + `payments/report/user/${user_id}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const fetchSitePaymentReport = async (site_id) => {
  return __get(BCK_BOOKKEEPING_API_URL + `payments/report/site/${site_id}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getSiteWebsiteReport = async (site_id) => {
  return __get(BCK_BOOKKEEPING_API_URL + `website/report/site/${site_id}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getUserHoursReport = async (user_id) => {
  return __get(BCK_BOOKKEEPING_API_URL + `hours/report/user/${user_id}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const fetchSiteHoursReport = async (site_id, start, end) => {
  const params = [];
  let url = BCK_BOOKKEEPING_API_URL + `hours/report/site/${site_id}`;
  if (start !== undefined) params.push(`start=${start}`);
  if (end !== undefined) params.push(`end=${end}`);
  if (params.length > 0) url += `?${params.join('&')}`;
  return __get(url)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const fetchAllSites = async (user_id) => {
  const payload = { user_id };
  return __post(BCK_API_V2_URL + `admin/get-sites`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const queryAllSubmissions = async (user_id, site_id, active_only = false) => {
  const params = [];
  if (user_id) params.push(`user_id=${user_id}`)
  if (site_id) params.push(`site_id=${site_id}`)
  params.push(`active_only=${active_only}`)
  return __get(BCK_API_V2_URL + `submission?${params.join('&')}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getAllUsers = async (user_id) => {
  const payload = { user_id };
  return __post(BCK_API_V2_URL + `admin/get-users`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const fetchSiteSubscription = async (user_id, site_id) => {
  const payload = { user_id, site_id };
  return __post(BCK_BOOKKEEPING_API_URL + `payments/subscription`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getProducts = async () => {
  return __get(BCK_BOOKKEEPING_API_URL + `payments/products`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getJobs = async (site_id, user_id) => {
  let url = BCK_BOOKKEEPING_API_URL + `jobs/${site_id}`
  url += `?user_id=${user_id}&`
  return __get(url)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const queryAllProjects = async (user_id, statuses) => {
  const params = [];
  if (user_id) params.push(`user_id=${user_id}`)
  if (statuses) params.push(`status=${statuses.join(',')}`)
  return __get(BCK_BOOKKEEPING_API_URL + `jobs?${params.join('&')}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const getJob = async (site_id, job_id, user_id, include_hours = false, include_access = false) => {
  let url = BCK_BOOKKEEPING_API_URL + `jobs/${site_id}`
  url += `?user_id=${user_id}&`
  if (job_id) url += `job_id=${job_id}&`
  url += `include_hours=${include_hours}`
  url += `&include_access=${include_access}`
  return __get(url)
  .then(response => {
    if (response && response.length > 0) return response[0];
    return response;
  })
  .catch(err => { throw err });
}

export const fetchJobById = async (job_id, user_id, include_hours = false, include_access = false) => {
  let url = BCK_BOOKKEEPING_API_URL + `job/${job_id}`
  url += `?user_id=${user_id}&`
  url += `include_hours=${include_hours}`
  url += `&include_access=${include_access}`
  return __get(url)
  .then(response => {
    if (response && response.length > 0) return response[0];
    return response;
  })
  .catch(err => { throw err });
}

export const getAllJobs = async (admin_user_id) => {
  return __get(`${BCK_BOOKKEEPING_API_URL}jobs?user_id=${admin_user_id}`)
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const createJob = async (user_id, site_id, job, options) => {
  const payload = { job, user_id, options };
  return __post(BCK_BOOKKEEPING_API_URL + `jobs/${site_id}`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}


/***************************/
/***   CREATE API CALLS  ***/
/***************************/

export const createNewUser = async (user_first_name, user_last_name, user_email) => {
  const payload = { user_first_name, user_last_name, user_email };
  return __post(BCK_API_V2_URL + 'user', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const createNewSite = async (authorized_user_id,  user_id, site, stripe_customer_id, clockify_project_id) => {
  const payload = { authorized_user_id,  user_id, site, stripe_customer_id, clockify_project_id };
  return __post(BCK_API_V2_URL + 'site', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const registerNewUser = async (user, site) => {
  const payload = { user, site };
  return __post(BCK_API_V2_URL + 'user/register', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const createNewSiteSubscription = async (user_id, site_id, site_service_modules, site_subscription_products, options) => {
  const payload = { user_id, site_id, site_service_modules, site_subscription_products, options};
  return __post(BCK_BOOKKEEPING_API_URL + `payments/subscription/${site_id}/create`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const createNewSiteExpense = async (user_id, site_id, item_data, document_encoded, document_type) => {
  const payload = { user_id, site_id, item_data, document_encoded, document_type };
  return __post(BCK_BOOKKEEPING_API_URL + `payments/expense`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const uploadSiteImage = async (site_id, admin_user_id, document) => {
  const payload = { site_id, admin_user_id, document };
  return __post(BCK_API_V2_URL + `site/upload`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const createSubmission = async (submission_type, submission_identifiers, submission_fields, submission_attachments) => {
  const payload = { submission_type, submission_identifiers, submission_fields, submission_attachments };
  return __post(BCK_API_V2_URL + 'submission', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}



/***************************/
/***   UPDATE API CALLS  ***/
/***************************/

 export const updateSiteSubscription = async (user_id, site_id, site_service_modules, site_subscription_products) => {
  const payload = { user_id, site_id, site_service_modules, site_subscription_products};
  return __post(BCK_BOOKKEEPING_API_URL + `payments/subscription/${site_id}/update`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const updateJob = async (job_id, site_id, user_id, masks, maskProps) => {
  const payload = { job_id, user_id, site_id, masks, ...maskProps };
  return __patch(BCK_BOOKKEEPING_API_URL + `jobs/${site_id}`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const updateJobStatus = async (user_id, site_id, job_id, status, status_change_reason, status_note, options) => {
  const payload = { user_id, site_id, job_id, job_status: { status, status_note, status_change_reason }, options};
  return __post(BCK_BOOKKEEPING_API_URL + `jobs/status`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}




/***************************/
/***   DELETE API CALLS  ***/
/***************************/

export const deleteJob = async (job_id, user_id) => {
  const payload = { user_id };
  return __delete(BCK_BOOKKEEPING_API_URL + `job/${job_id}`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}




/***************************/
/***   POST API CALLS  ***/
/***************************/

export const findUser = async (user_login_id) => {
  const payload = { user_login_id };
  return __post(BCK_API_V2_URL + 'user/find', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const connectUsersToSite = async (site_id, connect_user_ids = [], disconnect_user_ids = [], authorized_user_id) => {
  const payload = { site_id, connect_user_ids, disconnect_user_ids, authorized_user_id };
  return __post(BCK_API_V2_URL + 'site/connect-users', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const updateUserAccessToSite = async (site_id, user_id, removed_users = [], added_users = []) => {
  const payload = { site_id, user_id, removed_users, added_users };
  return __post(BCK_API_V2_URL + 'site/access', JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const sendApprovalRequest = async (job_id, user_id) => {
  const payload = { user_id };
  return __post(BCK_BOOKKEEPING_API_URL + `job/${job_id}/requests/submit_approval`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}

export const sendProposalResponse = async (job_id, user_id, body = {}, action) => {
  const payload = { user_id, metadata: { ...body } };
  return __post(BCK_BOOKKEEPING_API_URL + `job/${job_id}/requests/${action}`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}