import React from 'react';
import {Paper, Grid, MenuItem} from '@mui/material';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { isValidEmail } from '../../../utilities/Validation';
import { registerNewUser } from '../../../clients/bettercallkyle/BckApi';
import { showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import CreateAccountModal from '../modals/CreateAccountModal';
import { registerProfile } from '../../../clients/profile/ProfileClient';
import { useRef } from 'react';
import { COMPANY_TYPES } from '../../../constants/Common';
import {StyledTextField} from "../../common/StyledTextField";
import {LINK_TEXT_STYLE} from "../../../constants/Styles";
import {LoadingButton} from "../../common/StyledButtons";
import {DesktopBox, MobileBox} from "../../common/Boxes";

const NewCompanyForm = ( { history }) => {

  const userRef = useRef();
  const siteRef = useRef();

  const [firstName, setFirstName ] = useState();
  const [lastName, setLastName ] = useState();
  const [email, setEmail ] = useState();
  const [companyName, setCompanyName ] = useState();
  const [companyStructure, setCompanyStructure ] = useState();
  const [companyAddressLine1, setCompanyAddressLine1 ] = useState();
  const [companyAddressLine2, setCompanyAddressLine2 ] = useState();
  const [companyCity, setCompanyCity] = useState();
  const [companyState, setCompanyState] = useState();
  const [companyZipCode, setCompanyZipCode] = useState();
  const [companyEin, setCompanyEin] = useState();

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal ] = useState();

  const isValidForm = () => {
    const foundErrors = [];
    if (!firstName || isEmpty(firstName)) foundErrors.push('firstName');
    if (!lastName || isEmpty(lastName)) foundErrors.push('lastName');
    if (!email || isEmpty(email) || !isValidEmail(email)) foundErrors.push('email');
    if (!companyName || isEmpty(companyName)) foundErrors.push('companyName');
    if (!companyStructure || isEmpty(companyStructure)) foundErrors.push('companyStructure');
    if (!companyAddressLine1 || isEmpty(companyAddressLine1)) foundErrors.push('companyAddressLine1')
    if (!companyCity || isEmpty(companyCity)) foundErrors.push('companyCity')
    if (!companyState || isEmpty(companyState)) foundErrors.push('companyState')
    if (!companyZipCode || isEmpty(companyZipCode) || isNaN(companyZipCode) || Number(companyZipCode) < 10000 || Number(companyZipCode) > 99999 ) foundErrors.push('companyZipCode')

    setErrors(foundErrors)
    return foundErrors.length === 0
  }

  const register = async () => {
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const user = {
        user_first_name: firstName,
        user_last_name: lastName,
        user_email: email
      }

      const site = {
        site_name: companyName,
        site_structure: companyStructure,
        site_ein: companyEin,
        site_address: {
          address_line_1: companyAddressLine1,
          address_line_2: companyAddressLine2,
          city: companyCity,
          state: companyState,
          zipcode: companyZipCode
        }
      }
      // register new user info with DB (NOT cognito - will only create account if user wants in next step)
      const response = await registerNewUser(user, site);
      userRef.current = response.user;
      siteRef.current = response.site;

      // push to next screen to create account
      setShowCreateAccountModal(true)

    } catch(ex) {
      const resp = await (new Promise(resolve => resolve(ex)));
      if (resp && resp.code === 'user-already-exists') {
        setErrors(['email'])
        showNotificationBar('error', `The email you provided has already been registered.`)
      } else {
        showNotificationBar('error', `The registration was unable to be completed. Please try again.`)
      }
    } finally {
      setIsLoading(false)
    }

  }

  const handleCreateAccount = async (password) => {
    try {
      setIsLoading(true)
      // const resp = await registerProfile('kylewegener@gmail.com', password, 'Kyle', 'Wegener', 'f4a53320-4a5b-11ed-b1b6-1f47abd8917c')
      await registerProfile(userRef.current.user_login_id, password, userRef.current.user_personal.first_name, userRef.current.user_personal.last_name, userRef.current.user_id)
      setShowCreateAccountModal(false);
      history.push('/verify', { user: { email: userRef.current.user_login_id, password } });
      showNotificationBar('success', `Woohoo! Your account has been created. Now, let's verify your account.`)
    } catch(ex) {
      showNotificationBar('error', `Oh no! Something wen't wrong when creating your account. Please try again.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLeave = () => {
    setShowCreateAccountModal(false);
    showNotificationBar('success', `Woohoo! You're registration was a success! Please make sure to create your account as soon as possible.`)
    history.push('/register');
  }

  const handleShowTermsAndConditions = () => {
    const win = window.open('/terms', '_blank');
    win.focus();
  }

  const handleShowPrivacyPolicy = () => {
    const win = window.open('/privacy', '_blank');
    win.focus();
  }

  const renderForm = () => (
    <div style={{ padding: 16}}>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={11}>
          <h5 style={{ textAlign: 'center', margin: 0 }}>BCK Consulting</h5>
          <h3 style={{ textAlign: 'center', margin: 0 }}>Account Creation</h3>
          <p style={{ textAlign: 'center' }}>Welcome! Please review, and provide the necessary information below. (* Required Field)</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12}>
          <h4>Personal Information</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            autoFocus
            error={errors.includes('firstName')}
            value={firstName}
            type="text"
            label="*First Name"
            onChange={(e) => setFirstName(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={errors.includes('lastName')}
            value={lastName}
            type="text"
            label="*Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={errors.includes('email')}
            helperText={'This is the email that you will receive account information and invoices. We will also be sending a code to validate this email, please only use existing emails.'}
            fullWidth
            value={email}
            type="email"
            label="*E-Mail"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12}>
          <h4>Company/Organization Information</h4>
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={errors.includes('companyName')}
            value={companyName}
            type="text"
            label="*Company/Organization Name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            select
            error={errors.includes('companyStructure')}
            label="*Business Structure"
            onChange={(e) => setCompanyStructure(e.target.value)}
          >
            {COMPANY_TYPES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            error={errors.includes('companyEin')}
            value={companyEin}
            type="text"
            label="Employer ID Number (EIN)"
            onChange={(e) => setCompanyEin(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Legal Address</h4>
          <p style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Please provide your legal business address. If you do not yet have one, please provide your current address. This information is securely saved, and will never be shared externally.</p>
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={errors.includes('companyAddressLine1')}
            value={companyAddressLine1}
            type="text"
            label="*Street Address"
            onChange={(e) => setCompanyAddressLine1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={errors.includes('companyAddressLine2')}
            value={companyAddressLine2}
            type="text"
            label="Unit/Apt/Suite"
            onChange={(e) => setCompanyAddressLine2(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            error={errors.includes('companyCity')}
            value={companyCity}
            type="text"
            label="*City"
            onChange={(e) => setCompanyCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            error={errors.includes('companyState')}
            value={companyState}
            type="text"
            label="*State"
            onChange={(e) => setCompanyState(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            error={errors.includes('companyZipCode')}
            value={companyZipCode}
            type="text"
            label="*Zip Code"
            onChange={(e) => setCompanyZipCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container style={{marginTop: 30}} justifyContent='center' >
        <Grid item xs={12}>
          <p>By clicking 'I Agree & Continue' below you are accepting the <span style={LINK_TEXT_STYLE} onClick={handleShowTermsAndConditions}
          >Terms and Conditions</span> and the <span style={LINK_TEXT_STYLE} onClick={handleShowPrivacyPolicy}
          >Privacy Policy</span> of Better Call Kyle, LLC.</p>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item xs={'auto'}>
          <LoadingButton
            onClick={register} disabled={isLoading} isLoading={isLoading}
          >I Agree & Continue</LoadingButton>
        </Grid>
      </Grid>
    </div>
  )

   return (
     <div>
       <DesktopBox sx={{justifyContent: 'center', width: '100%'}}>
         <Paper sx={{ minWidth: '600px', maxWidth: { lg: '60%', md: '50%' },  paddingTop: '30px', paddingBottom: '30px', marginTop: '5%', marginBottom: '50px' }} variant='outlined'>
           {renderForm()}
         </Paper>
       </DesktopBox>
       <MobileBox sx={{flexDirection: 'column', justifyContent: 'center', height: '100%', minHeight: '90vh', backgroundColor: '#fff'}} >
         {renderForm()}
       </MobileBox>
       <CreateAccountModal open={showCreateAccountModal} onLeave={handleLeave} onCreateAccount={handleCreateAccount} isLoading={isLoading} />
     </div>
   )
}

export default NewCompanyForm;
