import React, { useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, Divider, Link } from '@mui/material';
import {NOTIFICATION_TYPE, showNotificationBar} from '../../../actions/error-pages/NotificationBarActions';
import { getUserId, isUserAdmin } from '../../../actions/profile/UserActions';
import Colors from '../../../constants/Colors';
import { LoadingButton, TextButton } from '../../common/StyledButtons';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { updateSiteSubmission } from '../../../actions/internal/SiteActions';
import { formatDateTimeToFancyString } from '../../../utilities/DateUtilities';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_LOOKUP, SUBMISSION_TYPES_HUMAN_MAP } from '../../../constants/Statuses';
import StyledLineItem from '../../common/StyledLineItem';
import { capitalize } from 'lodash';
import ConfirmationModal from './ConfirmationModal';
import EditRequestModal from './EditRequestModal';
import { SUBMISSION_TYPES } from '../../../actions/external/SubmissionActions';

const ViewRequestModal = ({ open, onClose, request, match }) => {

  const [ confirmCancelRequest, setConfirmCancelRequest ] = useState(false);
  const [ showEditRequestModal, setShowEditRequestModal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleClose = () => {
    onClose()
  }

  const handleCancelRequest = async () => {
    try {
      setIsLoading(true)
      setConfirmCancelRequest(false)
      const site_id = request.submission_identifiers.site ? request.submission_identifiers.site.site_id : undefined;
      await updateSiteSubmission(getUserId(), site_id, request.submission_id, ['submission_status'], { submission_status: SUBMISSION_STATUS.CANCELED })
      showNotificationBar(NOTIFICATION_TYPE.SUCCESS, "This request has been canceled successfully!");
      onClose();
    } catch(ex) {
      console.error(ex);
      showNotificationBar(NOTIFICATION_TYPE.ERROR, "An error has occurred while canceling this request. If this continues to happen, please contact us.");
    } finally {
      setIsLoading(false)
    }
  }

  const { submission_received_epoch, submission_type, submission_status, submission_id, submission_fields, submission_attachments } = request || {}

  return (
    <>
      <Dialog
          fullWidth
          open={open && !confirmCancelRequest && !showEditRequestModal}
          maxWidth={'md'}
      >
          <DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box>
                <h4 style={{ margin: 0, padding: 0 }}>{SUBMISSION_TYPES_HUMAN_MAP[submission_type] || submission_type}</h4>
                <h6 style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: 'normal' }}>Status: {submission_status ? SUBMISSION_STATUS_LOOKUP[submission_status].label : 'N/A'}</h6>
                <h6 style={{ margin: 0, padding: 0, fontSize: 18, fontWeight: 'normal' }}><em>Received on {formatDateTimeToFancyString(submission_received_epoch)}</em></h6>
              </Box>
              <Box>
                <h4 style={{ margin: 0, padding: 0, fontSize: 20 }}>{submission_id ? submission_id.substring(submission_id.length - 6).toUpperCase() : 'N/A'}</h4>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <h6 style={{ margin: 0, padding: 0, fontSize: 20 }}>Provided Information:</h6>
              {submission_fields && Object.keys(submission_fields).map(key => (
                <>
                  <StyledLineItem primary={capitalize(key)} secondary={submission_fields[key]} sx={{ padding: '8px 16px 8px 16px' }} />
                  <Divider />
                </>
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: '18px' }}>
              <h6 style={{ margin: 0, padding: 0, fontSize: 20 }}>Attachments:</h6>
              {submission_attachments && submission_attachments.map(attachment => (
                  <Link href={attachment.document_s3_location} target="_blank">{attachment.document_file_name}</Link>
              ))}
              {submission_attachments && submission_attachments.length === 0 &&
                <p>No attachments provided.</p>
              }
            </Box>
          </DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '16px'}}>
            <TextButton
              sx={{ marginRight: '10px', color: Colors.redColor }}
              onClick={() => setConfirmCancelRequest(true)}
              disabled={isLoading || (submission_status !== SUBMISSION_STATUS.SUBMITTED && !isUserAdmin())}
            >Cancel Request</TextButton>
            <Box>
              {submission_type === SUBMISSION_TYPES.CHANGE_REQUEST &&
                <TextButton sx={{ marginRight: '10px' }} onClick={() => setShowEditRequestModal(true)} disabled={isLoading || (submission_status !== SUBMISSION_STATUS.SUBMITTED && !isUserAdmin())}>Edit</TextButton>
              }
              <LoadingButton onClick={handleClose} isLoading={isLoading}>Close</LoadingButton>
            </Box>
          </Box>
      </Dialog>
      <ConfirmationModal
        open={confirmCancelRequest}
        onCancel={() => setConfirmCancelRequest(false)}
        description={"We are making sure you want to cancel this request. If you proceed, the updates or changes to your website or virtual assets wont be made. Feel free to confirm if you're sure!"}
        okButtonText='Cancel Request'
        cancelButtonText='Go Back'
        onOk={handleCancelRequest}
      />
      <EditRequestModal
        open={showEditRequestModal}
        onClose={() => setShowEditRequestModal(false)}
        onSave={() => {
          setShowEditRequestModal(false);
          handleClose();
        }}
        request={request}
      />
    </>
  )
}

export default observer(withRouter(ViewRequestModal));
// LEFT OFF: NEED TO PROVIDE ABILITY TO CANCEL REQUEST
// NEED TO BE ABLE TO EDIT SUBMITTED DRAFTS!! - Make Modal after submitted status to state that the need to contact us if they want to make a change! Admins can always edit!!
