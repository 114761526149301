import {observable, action, makeObservable} from "mobx";

class SitesStore {
    jobs_by_site = {};
    jobs_by_site_grouped = {};
    job_lookup_by_site = {};

    constructor() {
        makeObservable(this, {
            jobs_by_site: observable,
            jobs_by_site_grouped: observable,
            job_lookup_by_site: observable,

            setJobsBySite: action,
            setGroupedJobsBySite: action,
            setJobBySite: action
        })
    }

    initialize() {
        this.jobs_by_site = {};
        this.jobs_by_site_grouped = {};
    }

    setJobsBySite(site_id, jobs) {
        if (!site_id || !jobs) return
        this.jobs_by_site[site_id] = jobs;
    }

    setGroupedJobsBySite(site_id, jobs) {
        if (!site_id || !jobs) return
        this.jobs_by_site_grouped[site_id] = jobs;
    }

    setJobBySite(site_id, job) {
        if (!site_id || !job) return
        if (this.job_lookup_by_site[site_id]) {
            this.job_lookup_by_site[site_id][job.job_id] = job;
        } else {
            this.job_lookup_by_site[site_id] = { [job.job_id]: job }
        }
    }
};

/* makeObservable(SitesStore, {
    sites: observable,
    site_hour_reports: observable,
    site_invoice_reports: observable,
    site_subscriptions: observable,

    setSites: action,
    setSiteHourReport: action,
    setSiteInvoiceReport: action,
    setSiteSubscriptionReport: action,
}); */

export default new SitesStore();
