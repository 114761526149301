import { Box, Grid, Link, Paper } from '@mui/material';
import { BuildOutlined, CloudOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { getSiteSubscription } from "../../../actions/internal/SiteActions";
import { getUserId } from "../../../actions/profile/UserActions";
import Colors from "../../../constants/Colors";
import { CARD_CAPTION_STYLE, CARD_HEADER_STYLE } from "../../../constants/Styles";
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ContainedButton } from '../../common/StyledButtons';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import StyledSubHeader from '../../common/StyledSubHeader';
import { getSiteSubscriptionById } from '../../../selectors/SiteSelectors';
import StyledChip from "../../common/StyledChip";
import StyledAccordion from '../../common/StyledAccordion';
import { DesktopBox } from '../../common/Boxes';

export const SERVICE_ACTIONS = {
    SITE_CARE: {
        url: '/site-care',
        button: 'Request an Update',
        description: "Get updates or additions made to your site."
    },
    SITE_CARE_PLUS: {
        url: '/site-care',
        button: 'Request an Update',
        description: "Get updates or additions made to your site."
    },
    SHOP_BLOG: {
        url: '/shop-blog-editor',
        button: 'Request an Update',
        description: 'Expand your store with new items or enrich your blog with fresh posts.'
    }
}

const HOSTING_NO_PACKAGE_TITLE = "NO_PACKAGE"
const WEBMASTER_SERVICE_TITLE = 'WEBMASTER_SUPPORT';
const WEBMASTER_HAS_SERVICE_DESC = 'With our Proactive Website Care, BCK Consulting keeps your site secure, updated, and running smoothly so you can focus on your business.'
const WEBMASTER_NO_SERVICE_DESC = 'With a Self-Managed Site, you take full responsibility for all updates, enhancements, and security measures.'

const ViewSitePackageComponent = ({ history, location, match }) => {

    const [ isLoading, setIsLoading ] = useState(true);

    const siteId = match.params.site_id
    const subscription = getSiteSubscriptionById(siteId)

    useEffect(() => {
        loadData().then();
    }, [])

    const loadData = async () => {
        try {
            const userId = getUserId()
            await getSiteSubscription(userId, siteId)
        } catch (ex) {
            console.error(ex);
        } finally {
            setIsLoading(false);
        }
    }

    const resolvePackageName = () => {
        if (!subscription || !subscription.site_package) return '---'
        return subscription.site_package.package_details.name;
    }

    const hasWebmasterService = () => {
        if (!subscription || !subscription.site_package) return false
        return !!subscription.site_package.package_services.included_services[WEBMASTER_SERVICE_TITLE] || !!subscription.site_package.package_services.extra_services[WEBMASTER_SERVICE_TITLE]
    }

    const hasHostingService = () => {
        if (!subscription || !subscription.site_package) return false
        return subscription.site_package.package_details.type !== HOSTING_NO_PACKAGE_TITLE;
    }

    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )
    
    const renderOverviewSection = () => (
        <Box>
            <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Overview</h5>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={CARD_CAPTION_STYLE}>Website Hosting</div>
                            <StyledChip
                            text={hasHostingService() ? 'Active' : 'Inactive'}
                            sx={{ backgroundColor: hasHostingService() ? Colors.primaryColor : Colors.greyColor }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <DesktopBox sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '36px' }}>
                                <CloudOutlined sx={{ color: Colors.primaryColor, fontSize: '72px' }} />
                            </DesktopBox>
                            <Box>
                                <h6 style={{ textAlign: 'left', padding: 0 }}>{resolvePackageName()}</h6>
                                <p style={{ marginTop: '8px', textAlign: 'left', maxWidth: '600px' }}>{subscription.site_package.package_details.description}</p>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={CARD_CAPTION_STYLE}>Webmaster Support</div>
                            <StyledChip
                                text={hasWebmasterService() ? 'Active' : 'Inactive'}
                                sx={{ backgroundColor: hasWebmasterService() ? Colors.primaryColor : Colors.greyColor }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <DesktopBox sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '36px' }}>
                                <BuildOutlined sx={{ color: Colors.primaryColor, fontSize: '72px' }} />
                            </DesktopBox>
                            <Box>
                                <h6 style={{ textAlign: 'left', padding: 0 }}>{hasWebmasterService() ? 'Proactive Website Care' : 'Self-Managed Site'}</h6>
                                <p style={{ marginTop: '8px', textAlign: 'left', maxWidth: '600px' }}>{hasWebmasterService() ? WEBMASTER_HAS_SERVICE_DESC : WEBMASTER_NO_SERVICE_DESC}</p>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )

    const renderActionCards = (actions) => actions.map((action) => (
        <Grid item xs={12} sm={6} md={4} key={action.name}>
          <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
            <Box>
              <div style={{ ...CARD_HEADER_STYLE }}>{action.name}</div>
              <p style={{ }}>{action.description}</p>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', marginTop: '40px' }}>
              <ContainedButton onClick={() =>  history.push(action.link)} sx={{ padding: '8px 16px 8px 16px' }}>{action.button}</ContainedButton>
            </Box>
          </Paper>
        </Grid>
      ))

    const renderActionsSection = () => {
        const allServices = { ...subscription.site_package.package_services.extra_services, ...subscription.site_package.package_services.included_services }
        const actions = Object.keys(allServices).filter(key => SERVICE_ACTIONS[key] !== undefined)
        .map(key => ({
            name: allServices[key].name,
            description: SERVICE_ACTIONS[key].description,
            link: `${location.pathname}${SERVICE_ACTIONS[key].url}`,
            button: SERVICE_ACTIONS[key].button
        }))
        return (
            <Box>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Actions</h5>
                <Grid container spacing={1}>
                    {actions.length > 0 ? renderActionCards(actions) : <p style={{ paddingLeft: '8px' }}>No actions available.</p>}
                </Grid>
            </Box>
        )
    }

    const renderProductsServicesSection = () => {
        const allServices = { ...subscription.site_package.package_services.included_services, ...subscription.site_package.package_services.extra_services };
        const items = Object.keys(allServices).map(key => ({
            title: allServices[key].name,
            details: <>
                <p>{allServices[key].description}</p>
                <br />
                <i>{subscription.site_package.package_services.included_services[key] ? 'This service is included in your hosting package.' : 'This is an additional service.' }</i>
            </>
        }))

        return (
            <Box>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>My Products & Services</h5>
                {items.length > 0 ? 
                <>
                    <StyledAccordion items={items} />
                    <p>To view pricing information for these services, <Link href={`/dashboard/site/${siteId}/billing`}>go to Billing</Link>.</p>
                </> : <p>No products or services available.</p>}
                
            </Box>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
                <StyledSubHeader title="My Services" />
                {isLoading ? 
                    renderLoadingContent()
                    :
                    <>
                        {renderOverviewSection()}
                        {renderActionsSection()}
                        {renderProductsServicesSection()}
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default observer(withRouter(ViewSitePackageComponent));
