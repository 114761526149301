import React, { useRef, useState, useEffect } from 'react';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Switch } from '@mui/material';
import { Delete, PersonAddOutlined, PersonOutline, UndoOutlined } from '@mui/icons-material';
import Colors from '../../../constants/Colors';
import { STANDARD_PARAGRAPH_STYLE } from '../../../constants/Styles';
import { ContainedButton, LoadingButton, TextButton, TooltipIconButton } from '../../common/StyledButtons';
import { StyledTextField } from '../../common/StyledTextField';
import { isValidEmail } from '../../../utilities/Validation';
import { NOTIFICATION_TYPE, showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { getSiteByIdForUser, updateSiteAccess } from '../../../actions/internal/SiteActions';
import { getUserId } from '../../../selectors/UserSelectors';
import { StyledDropDown } from '../../common/StyledDropDown';

const AdminEditProjectAccessModal = ({ job, open, onClose, onSave, added_users, removed_users }) => {

    const addedUserDataRef = useRef();
    const siteRef = useRef();
    const siteUsersRef = useRef();

    const [ showAddUserModal, setShowAddUserModal ] = useState(false);
    const [ removedUsers, setRemovedUsers ] = useState([]);
    const [ addedUsers, setAddedUsers ] = useState([])
    const [ errors, setErrors ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)

    useEffect(() => {
        if (!open) return;

        if (!siteRef.current) loadData().then();
        if (added_users) setAddedUsers(added_users);
        if (removed_users) setRemovedUsers(removed_users);
    }, [open])

    const loadData = async () => {
        try {
            setIsLoading(true);
            const site = await getSiteByIdForUser(job.job_site.site_id, getUserId());

            siteRef.current = site;
            siteUsersRef.current = site.site_security.accessible_users.reduce((acc, val) => {
                acc[val.email] = val;
                return acc;
            }, {});
        } catch (ex) {
            showNotificationBar(NOTIFICATION_TYPE.ERROR, 'Could not load data to manage access. Please try again later.')
            console.error(ex);
            handleCleanCloseOfManageAccessModal();
        } finally {
            setIsLoading(false);
        }
    }

    const handleCleanCloseOfAddUserModal = () => {
        setErrors([])
        addedUserDataRef.current = undefined
        setShowAddUserModal(false);
    }

    const handleCleanCloseOfManageAccessModal = () => {
        setRemovedUsers([]);
        setAddedUsers([]);
        siteRef.current = undefined;
        onClose();
    }

    const handleAddUser = () => {
        addedUsers.push(siteUsersRef.current[addedUserDataRef.current]);
        handleCleanCloseOfAddUserModal();
    }

    const handleSave = async () => {
        onSave(addedUsers, removedUsers);
        handleCleanCloseOfManageAccessModal();
    }

    const renderAddUserModal= () => (
        <Dialog
            fullWidth
            open={showAddUserModal}
            maxWidth={'md'}
        >
            <DialogTitle>
                <h4 style={{ margin: 0, padding: 0 }}>Add User</h4>
                <p style={ STANDARD_PARAGRAPH_STYLE }>Please provide the first and last name of the user you would like to add, as well as their email address. By registering for an account, they will gain immediate access to this business account as a standard user.</p>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12}>
                        <StyledDropDown
                            label="Status"
                            onChange={(e) => addedUserDataRef.current = e.target.value}
                        >
                            {siteUsersRef.current && Object.keys(siteUsersRef.current).map(email => (
                                <MenuItem
                                    key={email}
                                    value={email}
                                    disabled={job.job_security.accessible_users.some(u => u.user_contact.email.primary === email) || addedUsers.some(u => u.email === email)}
                                >
                                    {siteUsersRef.current[email].first_name} {siteUsersRef.current[email].last_name} ({siteUsersRef.current[email].email})
                                </MenuItem>
                            ))}
                        </StyledDropDown>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <TextButton onClick={handleCleanCloseOfAddUserModal}>Cancel</TextButton>
               <ContainedButton  onClick={handleAddUser}>Add</ContainedButton>
            </DialogActions>
        </Dialog>
    )


    return (
        <>
        <Dialog
            fullWidth
            open={open && !showAddUserModal}
            maxWidth={'md'}
        >
            <DialogTitle>
                <h4 style={{ margin: 0, padding: 0 }}>Manage User Project Access</h4>
                <p style={STANDARD_PARAGRAPH_STYLE}>This panel allows you to manage user access to the project by adding or removing users. Please note that the primary user cannot be removed.</p>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <List>
                        {job && siteUsersRef.current && job.job_security.accessible_users.map((u) => {
                            const user = siteUsersRef.current[u.user_contact.email.primary];
                            return (
                                <ListItem
                                    key={user.email}
                                    dense
                                    sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                    <ListItemAvatar>
                                        <Avatar><PersonOutline /></Avatar>
                                    </ListItemAvatar>
                                    {removedUsers.includes(user.email) ? 
                                        <ListItemText primary={<strike>{user.first_name} {user.last_name} {user.is_primary_user ? '(Primary)' : ''}</strike>} secondary={<stike>{user.email}</stike>} />
                                    :
                                        <ListItemText primary={`${user.first_name} ${user.last_name} ${user.is_primary_user ? '(Primary)' : ''}`} secondary={user.email} />
                                    }
                                    <ListItemSecondaryAction>
                                        {removedUsers.includes(user.email) ? 
                                            <TooltipIconButton
                                                text={'Undo'}
                                                onClick={() => setRemovedUsers([...removedUsers.filter(email => email !== user.email)])}
                                                icon={<UndoOutlined sx={{ fontSize: '24px', color: !user.is_primary_user && Colors.primaryColor }} />} />
                                        :
                                            <TooltipIconButton
                                                text={'Remove Access'}
                                                disabled={user.is_primary_user}
                                                onClick={() => setRemovedUsers([...removedUsers, user.email])}
                                                icon={<Delete sx={{ fontSize: '24px', color: !user.is_primary_user && Colors.redColor }} />} />
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                        {addedUsers.map((user) => (
                            <ListItem
                                key={user.email}
                                dense
                                sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                <ListItemAvatar>
                                    <Avatar><PersonAddOutlined /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                                <ListItemSecondaryAction>
                                    <TooltipIconButton
                                        text={'Remove Added User'}
                                        disabled={user.is_primary_user}
                                        onClick={() => setAddedUsers([...addedUsers.filter(u => u.email !== user.email)])}
                                        icon={<Delete sx={{ fontSize: '24px', color: !user.is_primary_user && Colors.redColor }} />} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '16px'}}>
                <ContainedButton onClick={() => setShowAddUserModal(true) }>Add User</ContainedButton>
                <Box>
                    <TextButton sx={{ marginRight: '10px' }} onClick={handleCleanCloseOfManageAccessModal}>Cancel</TextButton>
                    <LoadingButton onClick={handleSave} isLoading={isLoading}>Save</LoadingButton>
                </Box>
            </Box>
        </Dialog>
        {renderAddUserModal()}
        </>
    )
}

export default AdminEditProjectAccessModal;

