import { Box, Grid, Link, Paper } from '@mui/material';
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import StyledSubHeader from '../../common/StyledSubHeader';
import { getSiteSubscription } from '../../../actions/internal/SiteActions';
import { getUserId } from '../../../selectors/UserSelectors';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import Colors from '../../../constants/Colors';
import { CheckCircleOutline } from '@mui/icons-material';
import StyledForm from '../../common/StyledForm';
import { showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { postSubmission, SUBMISSION_TYPES } from '../../../actions/external/SubmissionActions';

// const ESSENTIALS_DESCRIPTION = 'From content updates to minor technical adjustments, we ensure your site remains secure, optimized, and up-to-date. Additionally, this service allows you to request up to two small updates or tweaks per month to keep your website current and fully functional.';
// const ESSENTIAL_BULLET_POINTS=['Security & Technical Updates', 'Up to 2 Monthly Tweaks', '1-3 Day Fulfillment Time']

// const PLUS_DESCRIPTION = 'From content updates to minor technical adjustments, we ensure your site remains secure, optimized, and up-to-date. This includes website caching to speed up your site and hosting on a CDN (Content Delivery Network) to ensure quick access from anywhere. Additionally, this service allows you to request up to five small updates or tweaks per month to keep your website current and fully functional.';
// const PLUS_BULLET_POINTS = ['Security & Technical Updates', 'Website Caching', 'Globally Accessible (CDN Enabled)', 'Up to 5 Monthly Tweaks', '12-36 Hours Fulfillment Time']

const ShopBlogPage = ({ match }) => {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSubmittingRequest, setIsSubmittingRequest ] = useState(false);
  const [ hasSubmittedRequest, setHasSubmittedRequest ] = useState(false);

  const siteId = match.params.site_id

  useEffect(() => {
      loadData().then();
  }, [])

  const loadData = async () => {
      try {
        const userId = getUserId()
        await getSiteSubscription(userId, siteId)
      } catch (ex) {
          console.error(ex);
      } finally {
          setIsLoading(false);
      }
  }

  const renderLoadingContent = () => (
    <StyledProgressCircle />
  )

  const renderSubmittedContent = () => (
    <Box sx={{ border: '1px solid #cdcdcd;', borderRadius: '4px', marginTop: '40px', marginBottom: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <CheckCircleOutline sx={{ color: Colors.primaryColor, fontSize: '128px' }} />
      </Box>
      <h4 style={{ textAlign: 'center', marginBottom: 0, fontSize: '24px'  }}>Change Request Submitted</h4>
      <p style={{ textAlign: 'center', marginTop: 0 }}>Woo-hoo! Your request has been sent. You will receive an email when we begin working on it.</p>
    </Box>
  )

  /* const renderBulletPoint = (bullet) => (
    <IconTextSection
      key={bullet}
      icon={<Check style={{ color: Colors.primaryColor }} />}
      primaryText={bullet}
      primaryTextStyle={SUMMARY_TITLE_FONT} />
  ) */

  const renderContent = () => {
    if (!isSubmittingRequest && !hasSubmittedRequest) {
      return (
        <StyledForm
          fields={[
            { id: 'title', label: 'Item/Blog Title', type: 'input', isRequired: true },
            { id: 'description', label: 'Request Description', type: 'multiline', isRequired: true  },
            { id: 'attachments', label: 'Attachments', helperText: 'Include any images, content or drawings that would be helpful for this request.', type: 'upload', multiple: true }
          ]}
          onClick={handleFormSubmission}
        />
      )
    } else if (isSubmittingRequest) {
      return renderLoadingContent()
    } else {
      return renderSubmittedContent()
    }
  }

  // REMOVE?
  /* const renderIntroductionSection = () => (
    <Box>
        <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Overview</h5>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
              <Box>
                  <h6 style={{ textAlign: 'left', padding: 0 }}>Service Details</h6>
                  <p style={{ marginTop: '8px', textAlign: 'left' }}>{ESSENTIALS_DESCRIPTION }</p>
              </Box>
              <Box>
                  {ESSENTIAL_BULLET_POINTS.map((bullet) => renderBulletPoint(bullet))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
    </Box>
  ) */

const handleFormSubmission = async (values) => {
  try {
    setIsSubmittingRequest(true)
    await postSubmission(
      SUBMISSION_TYPES.CHANGE_REQUEST,
      { user_id: getUserId(), site_id: siteId},
      values.fields,
      values.attachments.map(attachment => { delete attachment.id; return attachment; })
    )
    setHasSubmittedRequest(true)
  } catch (err) {
    showNotificationBar('error', 'Uh oh! We could not submit your request at this time! Please try again later, or email us directly.');
  } finally {
    setIsSubmittingRequest(false)
  }
}

const renderUpdateRequestFormSection = () => (
  <Box>
      <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Change Request Form</h5>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
            <Box>
                <h6 style={{ textAlign: 'left', padding: 0 }}>Requesting an Addition or Update</h6>
                <p>If you would like to update or add a blog post or e-commerce store item, please do it here. Depending on when your request is submitted, our response time can be up to 24 hours. If you have any questions, please <Link href="https://consultwithkyle.com/contact-us" target="_blank">contact us</Link>. We're here to help!</p>
                <h6 style={{ textAlign: 'left', padding: 0, fontSize: '18px', marginTop: '30px' }}>Instructions</h6>
                <p>When filling out the form below, please provide the title of the post or item. In the "Request Description" section, include as many details as possible about what and where to change or add. If you would like to include changes to or additions of multiple items, please outline each change in the description section. <strong>Please do not include more than 5 items or blogs within one request.</strong> Don't forget to attach any relevant images or content!</p>
            </Box>
            {renderContent()}
          </Paper>
        </Grid>
      </Grid>
  </Box>
)

  return (
      <Grid container>
        <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
          <StyledSubHeader title="Shop & Blog" />
          {isLoading ? renderLoadingContent() :
              <div>
                {renderUpdateRequestFormSection()}
              </div>
          }
      </Grid>
    </Grid>
  )
}

export default observer(withRouter(ShopBlogPage));
