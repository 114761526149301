import React from 'react';
import {Box, Link} from '@mui/material';
import {FOOTER_STYLE} from "../../constants/Styles";
import packageJson from "../../../package.json";

const InternalFooter = () => {

  return (
    <Box sx={{ ...FOOTER_STYLE, display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'space-between'} }>
      <Box sx={{textAlign: {
          xs: 'center',
          sm: 'center',
          md: 'left'
        }}}>
        Designed + Managed by <b>BCK</b> | v.{packageJson.version}
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Link href="https://app.consultwithkyle.com/terms" target="_blank">Terms & Conditions</Link>
        <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>|</span>
        <Link href="https://app.consultwithkyle.com/privacy" target="_blank">Privacy Policy</Link>
      </Box>
    </Box>
    
  )
}

export default InternalFooter;
