import * as React from 'react';
import { Box, Checkbox } from '@mui/material';

const StyledCheckbox = ({
    label,
    ...props
  }) => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Checkbox {...props} />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            {label}
        </div>
    </Box>
)

export default StyledCheckbox;