import React from "react";
import {
  Grid,
  Box
} from "@mui/material";
import {
  DoorBackOutlined,
StorageOutlined,
WebAssetOutlined
} from "@mui/icons-material";
import Colors from "../../../../constants/Colors";
import Paper from "@mui/material/Paper";
import {CARD_HEADER_STYLE} from "../../../../constants/Styles";
import {ContainedButton} from "../../../common/StyledButtons";
import {withRouter} from "react-router";
import {getSiteById} from "../../../../selectors/SiteSelectors";
import {observer} from "mobx-react-lite";

const ActivityCard = ({ match }) => {
  const siteId = match.params.site_id
  const site = getSiteById(siteId)

  const prepareTools = () => {
    const tools = []
    if (site.site_external_connections.box) {
      tools.push({
        name: "Box Storage",
        icon: <StorageOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
        description: 'BCK Cloud storage, powered by Box Inc.',
        link: site.site_external_connections.box.box_url})
    }
    if (site.site_external_connections.wordpress) {
      tools.push({
        name: "Wordpress Dashboard",
        icon: <WebAssetOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
        description: 'Access to your website media and content.',
        link: site.site_external_connections.wordpress.wordpress_login_url})
    }
    tools.push({
      name: "Advanced Panel",
      icon: <DoorBackOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
      description: 'Backend access for your site & services.',
      link: 'https://d-90679ff33b.awsapps.com/start/'
    })
    
    return tools
  }

  const renderToolCards = () => prepareTools().map((tool) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={tool.name}>
      <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
           {tool.icon}
        </Box>
        <Box>
          <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>{tool.name}</div>
          <p style={{ textAlign: 'center' }}>{tool.description}</p>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          <ContainedButton onClick={() =>  window.open(tool.link, '_blank')} sx={{ padding: '8px 16px 8px 16px' }}>Access</ContainedButton>
        </Box>
      </Paper>
    </Grid>
  ))

  return (
    <Grid container item xs={12} spacing={1}>
      {renderToolCards()}
    </Grid>
  )
}

export default observer(withRouter(ActivityCard));
