import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';
import '../../styles/internal/internal-styles.css';
import {Box, CircularProgress, Grid} from '@mui/material';
import { showNotificationBar } from "../../actions/error-pages/NotificationBarActions";
import { getCurrentUser, signUserOut } from "../../actions/profile/UserActions";
import Colors from "../../constants/Colors";
import ErrorPanel from "../error-pages/ErrorPanel";
import GenericNotFound from "../error-pages/GenericNotFound";
import DashboardComponent from "./dashboard/DashboardComponent";
import InternalHeader from "./InternalHeader";
import InternalSiteRouter from "./InternalSiteRouter";
import { SESSION_REDIRECT_KEY, setSessionProperty } from "../../utilities/SessionUtilities";
import InternalFooter from "./InternalFooter";
import { getAllSitesForUser } from "../../actions/internal/SiteActions";
import UserAccountDetails from "./account/UserAccountDetails";
import AdminRouter from "./admin/AdminRouter";
import { StyledLoadingSection } from "../common/StyledProgressCircle";

const InternalRouter = ({ history, location }) => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ error, setError ] = useState(false);

    useEffect(() => {
        loadSession();
    }, [])

    const loadSession = async () => {
        setIsLoading(true)
        try {
            const user = await getCurrentUser();
            if (!user) {
                setSessionProperty(SESSION_REDIRECT_KEY, location.pathname)
                history.replace('/login');
                return;
            }
            await getAllSitesForUser(user.user_id)
        } catch(ex) {
            if (ex.code === 'UserNotFoundException') {
                signUserOut();
                history.replace('/login');
                return;
            }
            const error = await ex;
            console.error(error);
            setError('Something went wrong!');
            showNotificationBar('error', 'Oh no! A problem occurred, please try again later.');
            history.replace('/login');
        } finally {
            setIsLoading(false)
        }

    }

    const loadingContent = (
        <StyledLoadingSection />
    )

    const mainContent = !isLoading && !error ?
        <Switch>
            <Route exact path="/dashboard" render={routeProps => <DashboardComponent {...routeProps} />} />
            <Route path="/dashboard/site/:site_id" render={routeProps => <InternalSiteRouter {...routeProps} />} />
            <Route exact path="/account/me" render={routeProps => <UserAccountDetails {...routeProps} />} />
            <Route path="/admin" render={routeProps => <AdminRouter {...routeProps} />} />
            <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
        </Switch>
        :
        <ErrorPanel
            message={"Oh no! Something went wrong. Please check your internet connection and try again."}
        />;
    return (
        <React.Fragment>
            <InternalHeader />
            <Box sx={{ minHeight: '75vh', padding: '18px' }}>
                {isLoading ? loadingContent : mainContent}
            </Box>
            <InternalFooter />
        </React.Fragment>
    );
}

export default InternalRouter;
