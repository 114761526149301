import React, { Component } from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const styles = {
  backgroundStyle : {
    position:'fixed',
    top:0,
    left:0,
    height:'100%',
    width:'100vw',
    backgroundColor:'#373536'
  },
  termsTitleStyle: {
    fontSize:38,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTitleImportantStyle: {
    fontSize:24,
    marginBottom:10,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTextImportantStyle: {
    fontSize:18,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTextImportantIndent1Style: {
    fontSize:18,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838',
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:0
  },
  sectionTextImportantIndent2Style: {
    fontSize:18,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838',
    paddingLeft:40
  },
  sectionTitleStyle: {
    fontSize:18,
    marginBottom:10,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTextStyle: {
    fontSize:15,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
}

export default class TermsAndConditions extends Component {
    render() {
        return (
            <div style={{backgroundColor: '#DADADA'}}>
                <Grid container justifyContent="center">
                    <Grid item xs={11} sm={10} md={9}>
                    <Paper style={{ position: 'relative', marginTop: 100, paddingLeft: 50, paddingRight: 50, paddingBottom: 10, marginBottom: 50}}>
                        <div style={{padding:20, marginTop:5}}>
                            <span style={styles.termsTitleStyle}>Terms & Conditions</span>
                        </div>
                        <div style={{padding:20, marginTop:5}}>
                            <div style={styles.sectionTitleImportantStyle}>Welcome to Better Call Kyle, LLC</div>
                            <div style={styles.sectionTextImportantStyle}>&#09;These terms and conditions outline the rules and regulations for the use of Better Call Kyle&#39;s Website.</div>
                            <div style={styles.sectionTextImportantStyle}>Better Call Kyle LLC is located at:  911 Washington Ave suite 501, St. Louis, MO 63101 </div>
                        </div>
                        <div style={{paddingLeft:20, paddingRight:20, marginTop:5}}>
                            <div style={styles.sectionTextImportantStyle}>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Better Call Kyle&#39;s website if you do not accept all of the terms and conditions stated on this page.
                            The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company&#39;s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of Missouri. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                            </div>
                        </div>

                        <div style={{padding:20, marginTop:45}}>
                            <div style={styles.sectionTitleImportantStyle}>Cookies</div>
                            <div style={styles.sectionTextImportantStyle}>We employ the use of cookies. By using Better Call Kyle&#39;s website you consent to the use of cookies in accordance with Better Call Kyle&#39;s privacy policy.
                            Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each visit. Cookies
                            are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our
                            affiliate / advertising partners may also use cookies.</div>
                        </div>

                        <div style={{padding:20, marginTop:30}}>
                            <div style={styles.sectionTitleImportantStyle}>License</div>
                            <div style={styles.sectionTextImportantStyle}>Unless otherwise stated, Better Call Kyle and&#47;or it&#39;s licensors own the intellectual property rights for all material on Better Call Kyle. All intellectual property rights are reserved. You may view and/or print pages from http:&#47;&#47;consultwithkyle.com for your own personal use subject to restrictions set in these terms and conditions.</div>
                            <div style={styles.sectionTextImportantIndent1Style}>You must not:</div>
                            <div style={styles.sectionTextImportantIndent2Style}>Republish material from http:&#47;&#47;consultwithkyle.com</div>
                            <div style={styles.sectionTextImportantIndent2Style}>Sell, rent or sub-license material from http:&#47;&#47;consultwithkyle.com</div>
                            <div style={styles.sectionTextImportantIndent2Style}>Reproduce, duplicate or copy material from http:&#47;&#47;consultwithkyle.com</div>
                            <div style={styles.sectionTextImportantIndent2Style}>Redistribute content from Better Call Kyle (unless content is specifically made for redistribution).</div>
                        </div>

                        <div style={{padding:20, marginTop:30}}>
                            <div style={styles.sectionTitleImportantStyle}>Reservation of Rights</div>
                            <div style={styles.sectionTextImportantStyle}>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Web site.
                            You agree to immediately remove all links to our Web site upon such request.
                            We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
                            to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</div>
                        </div>

                        <div style={{padding:20, marginTop:30}}>
                            <div style={styles.sectionTitleImportantStyle}>Removal of links from our website</div>
                            <div style={styles.sectionTextImportantStyle}>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</div>
                            <div style={styles.sectionTextImportantStyle}>Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</div>
                        </div>

                        <div style={{padding:20, marginTop:30}}>
                            <div style={styles.sectionTitleImportantStyle}>Content Liability</div>
                            <div style={styles.sectionTextImportantStyle}>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims
                            arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene
                            or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</div>
                        </div>

                        <div style={{padding:20, marginTop:30}}>
                            <div style={styles.sectionTitleImportantStyle}>Disclaimer</div>
                            <div style={styles.sectionTextImportantStyle}>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</div>
                            <div style={styles.sectionTextImportantIndent1Style}>Nothing in this disclaimer will:</div>
                                <div style={styles.sectionTextImportantIndent2Style}>limit or exclude our or your liability for death or personal injury resulting from negligence;</div>
                                <div style={styles.sectionTextImportantIndent2Style}>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</div>
                                <div style={styles.sectionTextImportantIndent2Style}>limit any of our or your liabilities in any way that is not permitted under applicable law; or</div>
                                <div style={styles.sectionTextImportantIndent2Style}>exclude any of our or your liabilities that may not be excluded under applicable law.</div>
                            <div style={styles.sectionTextImportantIndent1Style}>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: </div>
                            <div style={styles.sectionTextImportantIndent2Style}>(a) are subject to the preceding paragraph; and </div>
                            <div style={styles.sectionTextImportantIndent2Style}>(b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.</div>
                            <div style={{height:10, width:'100%'}}></div>
                            <div style={styles.sectionTextImportantStyle}>To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</div>
                        </div>

                        <div style={{padding:20, marginTop:30, marginBottom:70}}>
                            <div style={styles.sectionTitleImportantStyle}>Credit and Contact Information</div>
                            <div style={styles.sectionTextImportantStyle}>This Terms and conditions page was created at termsandconditionstemplate.com generator. If you have any queries regarding any of our terms, please contact us at kyle@consultwithkyle.com.</div>
                        </div>
                    </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
