import { Box, Divider, Grid, Paper } from '@mui/material';
import { Edit, EditOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { NOTIFICATION_TYPE, showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import Colors from "../../../constants/Colors";
import { JOB_STATUS_LOOKUP } from "../../../constants/Statuses";
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import StyledSubHeader from '../../common/StyledSubHeader';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import { SUMMARY_TITLE_FONT, CARD_SUB_HEADER_STYLE } from '../../../constants/Styles';
import { formatDateToFancyString, formatDateTimeToFancyString, formatSecondsToPrettyHMTime } from '../../../utilities/DateUtilities';
import { TooltipIconButton } from '../../common/StyledButtons';
import StyledDataTable from '../../common/StyledDataTable';
import { JOB_STRUCTURES, JOB_STRUCTURES_LABELS } from '../../../constants/Common';
import StyledLineItem from '../../common/StyledLineItem';
import { getProjectForAdmin } from '../../../actions/internal/AdminActions';
import { getProjectById } from '../../../selectors/AdminSelectors';
import { Link } from 'react-router-dom';

const AdminViewJobDetailsComponent = ({ history, match }) => {

    const [ isLoading, setIsLoading ] = useState(true);

    const jobId = match.params.project_id;
    const job = getProjectById(jobId);
  
    useEffect(() => {
      loadData().then();
    }, [])
  
    const loadData = async () => {
      try {
        await getProjectForAdmin(getUserId(), jobId)
      } catch (ex) {
        const e = await ex;
        console.error(e);
        showNotificationBar(NOTIFICATION_TYPE.ERROR, `Uh oh! Something went wrong when fetching project data. Details: ${e.message}`);
      } finally {
        setIsLoading(false);
      }
    }
    
    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderDescription = (description) => description.split('\n').reduce((acc, val)=> [...acc, <div>{val}</div>],[])
    
    const renderSummaryStructureDescription = (job) => {
        if (!job || !job.job_details || !job.job_details.job_payment_structure) return undefined;
        return (
            <>
                <div style={{marginTop: '30px', marginBottom: '18px' }}><span style={{paddingRight: '35px', ...SUMMARY_TITLE_FONT }}>Current Status</span>{JOB_STATUS_LOOKUP[job.job_details.job_status].label}</div>
                <Divider />
                <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '60px', ...SUMMARY_TITLE_FONT }}>Site Name</span><Link to={`/admin/sites/${job.job_site.site_id}/manage`} style={{ color: Colors.primaryColor }}>{job.job_site.site_name || <em>Not Available</em>}</Link></div>
                <Divider />
                <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '40px', ...SUMMARY_TITLE_FONT }}>Last Updated</span>{formatDateToFancyString(job.job_metadata.updated_at)}</div>
                <Divider />
                <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '30px', ...SUMMARY_TITLE_FONT }}>Update Details</span>{job.job_details.job_status_note ? job.job_details.job_status_note : <i>No additional details.</i>}</div>
                <Divider />
                <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '50px', ...SUMMARY_TITLE_FONT }}>Project Type</span>{JOB_STRUCTURES_LABELS[job.job_details.job_payment_structure] || <em>Not Available</em>}</div>
                <Divider />
            </>
            
        )
    }

    const renderPricingSection = (quote) => {
        let itemRows = [];
        if (quote && quote.quote_items) {
            itemRows = quote.quote_items.map((item) => {
                return {
                    "Name": item.item_name,
                    "Price": `$${(item.item_unit_cost / 100).toFixed(2)}`,
                    "Units": item.item_units,
                    "Total": `$${(item.item_total / 100).toFixed(2)}`,
                }
            })
        }
        return (
            <>
                <h5 style={{ marginBottom: 0, fontSize: '16px' }}>Price Breakdown</h5>
                <StyledDataTable 
                    columns={["Name", "Price", "Units", "Total"]}
                    rows={itemRows}
                />
                <Divider sx={{ borderBottomWidth: 'medium' }} />
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12} md={6}>
                        <StyledLineItem primary={"Due At Start"} secondary={`$${(quote.quote_amount_due_at_start / 100).toFixed(2)}`} sx={{ padding: '8px 16px 8px 16px' }} />
                        <Divider />
                        <StyledLineItem primary={"Due At Complete"} secondary={`$${(quote.quote_amount_due_at_complete / 100).toFixed(2)}`} sx={{ padding: '8px 16px 8px 16px' }} />
                        <Divider />
                        <StyledLineItem primary={"Final Total"} secondary={`$${(quote.quote_amount / 100).toFixed(2)}`} sx={{ padding: '8px 16px 8px 16px' }} />
                    </Grid>
                </Grid>
            </>
            
        )
    }
    const renderHoursSection = (hours) => {
        let hourRows = [];
        if (hours && hours.hours_incurred && hours.hours_incurred.entries) {
            hourRows = hours.hours_incurred.entries.map((entry) => {
                const start = new Date(entry.timeInterval.start)

                let formattedEnd;
                let formattedDuration;

                if (entry.timeInterval.end && entry.timeInterval.end !== null) {
                    const end = new Date(entry.timeInterval.end)
                    formattedEnd = formatDateTimeToFancyString(end.getTime())
                    formattedDuration = formatSecondsToPrettyHMTime((end - start) / 1000)
                } else {
                    formattedEnd = '---'
                    formattedDuration = formatSecondsToPrettyHMTime((Date.now() - start) / 1000)
                }
            
                return {
                    "Description": entry.description,
                    "Start": formatDateTimeToFancyString(start.getTime()),
                    "End": formattedEnd,
                    "Duration": formattedDuration
                }
            })
        }

        return (
            <>
                <h5 style={{ marginBottom: 0, fontSize: '16px' }}>Hours Breakdown</h5>
                <StyledDataTable 
                    columns={["Description", "Start", "End", "Duration"]}
                    rows={hourRows}
                />
                <Divider sx={{ borderBottomWidth: 'medium' }} />
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12} md={6}>
                        <StyledLineItem primary={"Total Hours"} secondary={(hours.hours_incurred.estimated_hours).toFixed(1)} sx={{ padding: '8px 16px 8px 16px' }} />
                    </Grid>
                </Grid>
            </>

        )
    }

    const renderSummaryStructureSection = (job) => {
        if (!job || !job.job_details || !job.job_details.job_payment_structure) return undefined;
        const { job_hours, job_quote } = job;
        switch(job.job_details.job_payment_structure) {
            case JOB_STRUCTURES.HOURLY:
                return renderHoursSection(job_hours)
            case JOB_STRUCTURES.QUOTE:
                return renderPricingSection(job_quote)
            default:
                return undefined
        }
    }

    const renderDetailsCard = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12} key={job.job_id}>
                <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                        <div>
                            <h4 style={{ margin: 0 }}>{job.job_details.job_name}</h4>
                            <div style={{...CARD_SUB_HEADER_STYLE, fontStyle: 'italic' }}>Created On: {formatDateToFancyString(job.job_metadata.created_at)}</div>
                        </div>
                        <Box>
                            <TooltipIconButton 
                                icon={<Edit sx={{ fontSize: '24px' }}/>}
                                text={'Edit Project'}
                                onClick={() => history.push(`/admin/projects/${job.job_id}/edit`)}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <h5 style={{ marginBottom: 0 }}>Description</h5>
                        <p>{renderDescription(job.job_details.job_description)}</p>
                    </Box>
                    <Box sx={{ marginTop: '50px' }}>
                        <h5 style={{ marginBottom: 0 }}>Summary</h5>
                        {renderSummaryStructureDescription(job)}
                        {renderSummaryStructureSection(job)}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )

    return (
        <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <StyledSubHeader title="Project Details" />
            {isLoading ? renderLoadingContent() :
                <>
                    {renderDetailsCard()}
                </>
            }
            
        </Grid>
      </Grid>
    )
}

export default observer(withRouter(AdminViewJobDetailsComponent));;
