import React from "react";
import { Tooltip } from "@mui/material";
import { JOB_STATUS_LOOKUP, JOB_STATUS_MAP, SUBMISSION_STATUS, SUBMISSION_STATUS_LOOKUP } from "../../constants/Statuses";
import { Cached, CallReceivedOutlined, CancelOutlined, Check, FeedbackOutlined, HourglassEmpty, InfoOutlined, ScheduleOutlined, ThumbUpOutlined } from "@mui/icons-material";
import Colors from "../../constants/Colors";

const StyledStatusIcon = ({
  status = '',
  sx,
  ...props
}) => {
    const { label, description } = JOB_STATUS_LOOKUP[status.toUpperCase()] || SUBMISSION_STATUS_LOOKUP[status.toUpperCase()] || {}
    const style = { cursor: 'pointer', fontSize: '28px' };
    
    const ICONS = {
        [JOB_STATUS_MAP.APPROVED]: <ThumbUpOutlined style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
        [JOB_STATUS_MAP.IN_PROGRESS]: <Cached style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
        [JOB_STATUS_MAP.PENDING_APPROVAL]: <ThumbUpOutlined style={{ ...style, color: Colors.blueColor, ...sx }} {...props} />,
        [JOB_STATUS_MAP.REQUESTED_CLIENT_FEEDBACK]: <HourglassEmpty style={{ ...style, color: Colors.warningBackground, ...sx }} {...props} />,
        [JOB_STATUS_MAP.REQUESTED_CLIENT_ITEMS]: <FeedbackOutlined style={{ ...style, color: Colors.warningBackground, ...sx }} {...props} />,
        [JOB_STATUS_MAP.QUEUED]: <ScheduleOutlined style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
        [JOB_STATUS_MAP.COMPLETED]: <Check style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
        [SUBMISSION_STATUS.SUBMITTED]: <CallReceivedOutlined style={{ ...style, color: Colors.blueColor, ...sx }} {...props} />,
        [SUBMISSION_STATUS.IN_PROGRESS]: <Cached style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
        [SUBMISSION_STATUS.COMPLETED]: <Check style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
        [SUBMISSION_STATUS.CANCELED]: <CancelOutlined style={{ ...style, color: Colors.blueColor, ...sx }} {...props} />
    }

    const icon = ICONS[status.toUpperCase()] || <InfoOutlined style={{ ...style, color: Colors.blueColor, ...sx }} {...props} />;

    return (<Tooltip title={`${label}: ${description}`}>{icon}</Tooltip>)
}

export default StyledStatusIcon;
