import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { JOB_STATUS_LOOKUP, JOB_STATUS_MAP } from "../../constants/Statuses";
import { Cached, Check, FeedbackOutlined, HourglassEmpty, InfoOutlined, ScheduleOutlined, ThumbUpOutlined } from "@mui/icons-material";
import Colors from "../../constants/Colors";
import { CHIP_STYLE } from "../../constants/Styles";

const StyledStatusChip = ({
  status = '',
  sx,
  ...props
}) => {
    const { label, description } = JOB_STATUS_LOOKUP[status.toUpperCase()] || {}
    const style = { cursor: 'pointer', color: '#fff' };
    
    const ICONS = {
      [JOB_STATUS_MAP.APPROVED]: <ThumbUpOutlined style={{ ...style, ...sx }} {...props} />,
      [JOB_STATUS_MAP.IN_PROGRESS]: <Cached style={{ ...style, ...sx }} {...props} />,
      [JOB_STATUS_MAP.PENDING_APPROVAL]: <ThumbUpOutlined style={{ ...style, ...sx }} {...props} />,
      [JOB_STATUS_MAP.REQUESTED_CLIENT_FEEDBACK]: <HourglassEmpty style={{ ...style, ...sx }} {...props} />,
      [JOB_STATUS_MAP.REQUESTED_CLIENT_ITEMS]: <FeedbackOutlined style={{ ...style, ...sx }} {...props} />,
      [JOB_STATUS_MAP.QUEUED]: <ScheduleOutlined style={{ ...style, ...sx }} {...props} />,
      [JOB_STATUS_MAP.COMPLETED]: <Check style={{ ...style, ...sx }} {...props} />
    }

    const BACKGROUND_COLOR = {
      [JOB_STATUS_MAP.APPROVED]: Colors.primaryColor, // <ThumbUpOutlined style={{ ...style, color: , ...sx }} {...props} />,
      [JOB_STATUS_MAP.IN_PROGRESS]: Colors.primaryColor, // <Cached style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
      [JOB_STATUS_MAP.PENDING_APPROVAL]: Colors.blueColor, // <ThumbUpOutlined style={{ ...style, color: Colors.blueColor, ...sx }} {...props} />,
      [JOB_STATUS_MAP.REQUESTED_CLIENT_FEEDBACK]: Colors.warningBackground, // <HourglassEmpty style={{ ...style, color: Colors.warningBackground, ...sx }} {...props} />,
      [JOB_STATUS_MAP.REQUESTED_CLIENT_ITEMS]: Colors.warningBackground, // <FeedbackOutlined style={{ ...style, color: Colors.warningBackground, ...sx }} {...props} />,
      [JOB_STATUS_MAP.QUEUED]: Colors.primaryColor, // <ScheduleOutlined style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />,
      [JOB_STATUS_MAP.COMPLETED]: Colors.primaryColor, // <Check style={{ ...style, color: Colors.primaryColor, ...sx }} {...props} />
    }

    const icon = ICONS[status.toUpperCase()] || <InfoOutlined style={{ ...style, ...sx }} {...props} />;
    const bgColor = BACKGROUND_COLOR[status.toUpperCase()] || Colors.blueColor;

    return (
    <Tooltip title={`${description}`}>
      <Chip
        sx={{ ...CHIP_STYLE, ...sx, backgroundColor: bgColor, borderWidth: 0 }}
          avatar={icon}
          style={{ marginTop: 10, cursor: 'pointer' }}
          label={label}
          variant="outlined"
      />
    </Tooltip>)
}

export default StyledStatusChip;
