import { Box, Grid, Link, Paper, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import React, { useEffect, useState } from "react";
import { getSiteSubmissionsById, getSiteSubscriptionById } from "../../../selectors/SiteSelectors";
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import StyledSubHeader from '../../common/StyledSubHeader';
import { getSiteSubmissions, getSiteSubscription } from '../../../actions/internal/SiteActions';
import { getUserId } from '../../../selectors/UserSelectors';
import StyledProgressCircle, { StyledLoadingSection } from '../../common/StyledProgressCircle';
import Colors from '../../../constants/Colors';
import { SUMMARY_TITLE_FONT } from '../../../constants/Styles';
import IconTextSection from '../../common/IconTextSection';
import { AssignmentOutlined, Check, CheckCircleOutline } from '@mui/icons-material';
import StyledForm from '../../common/StyledForm';
import { showNotificationBar } from '../../../actions/error-pages/NotificationBarActions';
import { postSubmission, SUBMISSION_TYPES } from '../../../actions/external/SubmissionActions';
import StyledStatusIcon from '../../common/StyledStatusIcon';
import { formatDateTimeToFancyString } from '../../../utilities/DateUtilities';
import { SUBMISSION_TYPES_HUMAN_MAP } from '../../../constants/Statuses';
import { TooltipIconButton } from '../../common/StyledButtons';
import ViewRequestModal from '../modals/ViewRequestModal';

const ESSENTIALS_DESCRIPTION = 'From content updates to minor technical adjustments, we ensure your site remains secure, optimized, and up-to-date. Additionally, this service allows you to request up to two small updates or tweaks per month to keep your website current and fully functional.';
const ESSENTIAL_BULLET_POINTS=['Security & Technical Updates', '1 Website Tweak every 30-Day Period', '2-5 Day Fulfillment Time']

const PLUS_DESCRIPTION = 'From content updates to minor technical adjustments, we ensure your site remains secure, optimized, and up-to-date. This includes website caching to speed up your site and hosting on a CDN (Content Delivery Network) to ensure quick access from anywhere. Additionally, this service allows you to request up to five small updates or tweaks every 30 days to keep your website current and fully functional.';
const PLUS_BULLET_POINTS = ['Security & Technical Updates', 'Website Caching', 'Globally Accessible (CDN Enabled)', 'Up to 5 Website Tweaks within 30 days', 'Priority Fulfillment Time']

const SiteCarePage = ({ match }) => {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSubmittingRequest, setIsSubmittingRequest ] = useState(false);
  const [ hasSubmittedRequest, setHasSubmittedRequest ] = useState(false);
  const [ requestInView, setRequestInView ] = useState();

  const siteId = match.params.site_id
  const subscription = getSiteSubscriptionById(siteId)
  const submissions = getSiteSubmissionsById(siteId)

  useEffect(() => {
      loadData().then();
  }, [])

  const loadData = async () => {
      try {
        const userId = getUserId()
        await getSiteSubscription(userId, siteId)
        await getSiteSubmissions(siteId)
      } catch (ex) {
          console.error(ex);
      } finally {
          setIsLoading(false);
      }
  }

  const isSiteCarePlus = () => ({ 
    ...subscription.site_package.package_services.included_services,
    ...subscription.site_package.package_services.extra_services
  }['SITE_CARE_PLUS'] !== undefined)

  const renderLoadingContent = () => (
    <StyledLoadingSection />
  )

  const renderSubmittedContent = () => (
    <Box sx={{ border: '1px solid #cdcdcd;', borderRadius: '4px', marginTop: '40px', marginBottom: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <CheckCircleOutline sx={{ color: Colors.primaryColor, fontSize: '128px' }} />
      </Box>
      <h4 style={{ textAlign: 'center', marginBottom: 0, fontSize: '24px'  }}>Change Request Submitted</h4>
      <p style={{ textAlign: 'center', marginTop: 0 }}>Woo-hoo! Your request has been sent. You will receive an email when we begin working on it.</p>
    </Box>
  )

  const renderBulletPoint = (bullet) => (
    <IconTextSection
      key={bullet}
      icon={<Check style={{ color: Colors.primaryColor }} />}
      primaryText={bullet}
      primaryTextStyle={SUMMARY_TITLE_FONT} />
  )

  const renderContent = () => {
    if (!isSubmittingRequest && !hasSubmittedRequest) {
      return (
        <StyledForm
          fields={[
            { id: 'title', label: 'Request Title', type: 'input', isRequired: true },
            { id: 'url', label: 'Page URL or Page Name', type: 'input' },
            { id: 'description', label: 'Request Description', type: 'multiline', isRequired: true  },
            { id: 'attachments', label: 'Attachments', helperText: 'Include any images, content or drawings that would be helpful for this request.', type: 'upload', multiple: true }
          ]}
          onClick={handleFormSubmission}
        />
      )
    } else if (isSubmittingRequest) {
      return renderLoadingContent()
    } else {
      return renderSubmittedContent()
    }
  }

  const renderIntroductionSection = () => (
    <Box>
        <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Service Details</h5>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
              <Box>
                  <h6 style={{ textAlign: 'left', padding: 0 }}>My SiteCare {isSiteCarePlus() ? 'Plus' : 'Essentials' }</h6>
                  <p style={{ marginTop: '8px', textAlign: 'left' }}>{isSiteCarePlus() ? PLUS_DESCRIPTION : ESSENTIALS_DESCRIPTION }</p>
              </Box>
              <Box>
                {isSiteCarePlus() ?
                PLUS_BULLET_POINTS.map((bullet) => renderBulletPoint(bullet))
                :
                  ESSENTIAL_BULLET_POINTS.map((bullet) => renderBulletPoint(bullet))
                }
              </Box>
            </Paper>
          </Grid>
        </Grid>
    </Box>
)

const handleFormSubmission = async (values) => {
  try {
    setIsSubmittingRequest(true)
    await postSubmission(
      SUBMISSION_TYPES.CHANGE_REQUEST,
      { user_id: getUserId(), site_id: siteId},
      values.fields,
      values.attachments.map(attachment => { delete attachment.id; return attachment; })
    )
    await getSiteSubmissions(siteId, true)
    setHasSubmittedRequest(true)
  } catch (err) {
    showNotificationBar('error', 'Uh oh! We could not submit your request at this time! Please try again later, or email us directly.');
  } finally {
    setIsSubmittingRequest(false)
  }
}

const renderActiveRequestsSection = () => {
  return (
    <Box>
      <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Active Requests</h5>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <List style={{ flex: 1 }}>
              {submissions && submissions.map((submission) => (
                  <ListItem
                      dense
                      sx={{ backgroundColor: '#fff', marginBottom: '5px' }}
                      key={submission.submission_id}
                  >
                      <ListItemAvatar>
                          <StyledStatusIcon status={submission.submission_status} />
                      </ListItemAvatar>
                      <ListItemText primary={`${SUBMISSION_TYPES_HUMAN_MAP[submission.submission_type] || submission.submission_type} - ${submission.submission_id.substring(submission.submission_id.length - 6).toUpperCase()}`} secondary={formatDateTimeToFancyString(submission.submission_received_epoch)} />
                      <ListItemSecondaryAction>
                          <TooltipIconButton
                              text={'View Details'}
                              icon= {<AssignmentOutlined sx={{ color: Colors.primaryColor }}
                              onClick={() => setRequestInView(submission)}
                          /> } />
                      </ListItemSecondaryAction>
                  </ListItem>
              ))}
          </List>
          {(!submissions || submissions.length === 0) &&
              <p>No active requests. To make a request, complete the Change Request Form below.</p>
          }
        </Grid>
      </Grid>
    </Box>
      
  )
}

const renderUpdateRequestFormSection = () => (
  <Box>
      <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Change Request Form</h5>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ padding: '16px', overflow: 'hidden' }}>
            <Box>
                <h6 style={{ textAlign: 'left', padding: 0 }}>Request A Change</h6>
                <p>If you need a tweak for your site, please submit your request here. Your request will be immediately queued, and you will receive an email to confirm we have received it. If you have any questions, please <Link href="https://consultwithkyle.com/contact-us" target="_blank">contact us</Link>.</p>
                <h6 style={{ textAlign: 'left', padding: 0, fontSize: '18px', marginTop: '30px' }}>Instructions</h6>
                <p>When filling out the form below, please provide a detailed title for your request. In the "Request Description" section, include as many details as possible about what and where to change or add to your site. Be sure to include the page URL or page name where the change or addition should occur. If the change should occur on multiple pages, include all the page URLs or names in the text box, separated by commas.</p>
            </Box>
            {renderContent()}
          </Paper>
        </Grid>
      </Grid>
  </Box>
)

  return (
      <Grid container>
        <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
          <StyledSubHeader title="SiteCare" />
          {isLoading ? renderLoadingContent() :
              <div>
                {renderIntroductionSection()}
                {renderActiveRequestsSection()}
                {renderUpdateRequestFormSection()}
              </div>
          }
      </Grid>
      <ViewRequestModal
        open={!!requestInView}
        onClose={() => setRequestInView()}
        request={requestInView}
      />
    </Grid>
  )
}

export default observer(withRouter(SiteCarePage));
