import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NotificationBarStore from '../../stores/error-pages/NotificationBarStore';
import { resetNotificationBar } from "../../actions/error-pages/NotificationBarActions";
import { observer } from "mobx-react-lite";
import { NOTIFICATION_BAR_STYLES } from "../../constants/Styles";

const NotificationBar = () => {
  const { show, message, variant } = NotificationBarStore;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetNotificationBar()
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={variant}
        variant="filled"
        sx={NOTIFICATION_BAR_STYLES}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default observer(NotificationBar);
