import React from "react";
import { Grid, Paper, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar } from '@mui/material';
import { EmailOutlined, Person, ReceiptOutlined, StoreOutlined } from "@mui/icons-material";
import { withRouter } from "react-router";
import StyledSubHeader from "../../common/StyledSubHeader";
import { getUserObject } from "../../../selectors/UserSelectors";
import IconTextSection from "../../common/IconTextSection";
import { getAllSites } from "../../../selectors/SiteSelectors";
import { TooltipIconButton } from "../../common/StyledButtons";
import Colors from "../../../constants/Colors";

const UserAccountDetails = ({ history }) => {
  const user = getUserObject();
  const sites = getAllSites();

  const renderAccessContent = () => (
    <>
      <Grid item xs={12}>
        <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Access & Billing</h5>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" style={{ padding: '24px 16px 24px 16px' }}>
          <h4 style={{ margin: 0, fontSize: '24px' }}>Accounts</h4>
          <p>Billing settings are managed at the Business Account level to ensure streamlined and secure handling of financial details. Individual user accounts do not have access to modify billing information. Only the primary account holder has the authority to request billing changes for the business. To review and update your billing information, please click on the Business Account link below.</p>
          <List style={{ flex: 1 }}>
            {sites && sites.map((site) => (
                <ListItem
                    dense
                    sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                    <ListItemAvatar>
                      {site && site.site_details && site.site_details.site_img_url ?
                        <Avatar alt={site.site_details.site_name} src={site.site_details.site_img_url} />
                        :
                        <Avatar><StoreOutlined /></Avatar>
                      }
                    </ListItemAvatar>
                    <ListItemText primary={site.site_details.site_name} secondary={site.site_security.primary_user_id === user.user_id ? 'Primary User Access' : 'Standard User'} />
                    <ListItemSecondaryAction>
                        <TooltipIconButton
                            text={'View Billing Details'}
                            icon= {<ReceiptOutlined sx={{ color: Colors.primaryColor }}
                            onClick={() => history.push(`/dashboard/site/${site.site_id}/billing`)}
                        /> } />
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
            {(!sites || sites.length === 0) &&
                <p>It doesn't look like you have access to any accounts at this time.</p>
            }
          </List>
        </Paper>
      </Grid>
    </>
  )

  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
        <StyledSubHeader title="My Account" />
        <Grid container justify='flex-start' spacing={2} sx={{ }}>
          <Grid item xs={12}>
            <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Account Information</h5>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" style={{ padding: '24px 16px 24px 16px' }}>
              <h4 style={{ margin: 0, fontSize: '24px', paddingBottom: '20px' }}>Overview</h4>
              <IconTextSection
                  icon={<Person sx={{ fontSize: '24px' }}/>}
                  primaryText={`${user.user_personal.first_name} ${user.user_personal.last_name}`}
                />
              <IconTextSection
                  icon={<EmailOutlined sx={{ fontSize: '24px' }}/>}
                  primaryText={user.user_contact.email.primary}
                />
            </Paper>
          </Grid>
          {renderAccessContent()}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(UserAccountDetails);