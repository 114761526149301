import { cloneDeep, groupBy } from "lodash";
import {
  fetchAllSites,
  fetchJobById,
  queryAllProjects,
  queryAllSubmissions,
} from "../../clients/bettercallkyle/BckApi";
import AdminStore from "../../stores/internal/AdminStore";
import { JOB_STATUS_MAP } from "../../constants/Statuses";

export const getAllSitesForAdmin = async (user_id, force_refresh = false) => {
  if (!force_refresh && AdminStore.sites !== undefined) {
    return cloneDeep({ sites: AdminStore.sites });
  }
  if (user_id === undefined) {
    throw new Error("No user id provided")
  }
  const response = await fetchAllSites(user_id);
  const sortedSites = response.sort((a, b) => a.site_details.site_name.localeCompare(b.site_details.site_name));
  AdminStore.setSites(sortedSites);
  return response;
}

export const getAllSubmissionsForAdmin = async (user_id, active_only = false, force_refresh = false) => {
  if (!force_refresh && AdminStore.submissions !== undefined) {
    return cloneDeep({ submissions: AdminStore.submissions });
  }
  if (user_id === undefined) throw new Error("No user id provided")
  const response = await queryAllSubmissions(user_id, undefined, active_only).catch(ex => undefined);
  const sortedSubmissions = response ? response.sort((a, b) => a.submission_received_epoch < b.submission_received_epoch) : [];
  AdminStore.setSubmissions(sortedSubmissions);
  return response;
}

export const getAllProjectsForAdmin = async (
  user_id,
  statuses = [JOB_STATUS_MAP.DRAFT, JOB_STATUS_MAP.PENDING, JOB_STATUS_MAP.AWAITING_PAYMENT, JOB_STATUS_MAP.QUEUED, JOB_STATUS_MAP.IN_PROGRESS, JOB_STATUS_MAP.PAUSED],
  force_refresh = false
  ) => {
  if (!force_refresh && AdminStore.projects !== undefined) {
    return cloneDeep(AdminStore.projects);
  }
  if (user_id === undefined) throw new Error("No user id provided")
  const response = await queryAllProjects(user_id, statuses);
  const jobs = response.jobs;
  if (!jobs) return [];

  const importanceOrder = [
    JOB_STATUS_MAP.IN_PROGRESS,
    JOB_STATUS_MAP.QUEUED,
    JOB_STATUS_MAP.APPROVED,
    JOB_STATUS_MAP.PENDING_APPROVAL,
    JOB_STATUS_MAP.DRAFT,
    JOB_STATUS_MAP.REQUESTED_CLIENT_FEEDBACK,
    JOB_STATUS_MAP.REQUESTED_CLIENT_ITEMS,
    JOB_STATUS_MAP.PAUSED,
    JOB_STATUS_MAP.COMPLETED,
    JOB_STATUS_MAP.STOPPED ,
    JOB_STATUS_MAP.REJECTED]
  const groups = groupBy(jobs, (j) => j.job_details.job_status);
  const sortedJobs = []
  for (const status of importanceOrder) {
    if (!groups[status]) continue;
    sortedJobs.push(...groups[status].sort((a, b) => a.job_details.job_name - b.job_details.job_name))
  }
  AdminStore.setProjects(sortedJobs);
  return sortedJobs;
}

export const getProjectForAdmin = async (
  user_id,
  job_id,
  force_refresh = false
  ) => {
  if (!force_refresh && AdminStore.project[job_id] !== undefined) {
    return cloneDeep(AdminStore.project[job_id]);
  }
  if (user_id === undefined) throw new Error("No user id provided")
  const job = await fetchJobById(job_id, user_id, true, true)
  if (job) AdminStore.setProject(job)
  return job;
}
