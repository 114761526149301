import {
  createSubmission
} from "../../clients/bettercallkyle/BckApi";

export const SUBMISSION_TYPES = {
  CHANGE_REQUEST: 'CHANGE_REQUEST'
}

export const postSubmission = async (type, identifiers, fields, attachments) => {
  const response = await createSubmission(type, identifiers, fields, attachments)
  return response;
}
