import {
  Grid,
  Box, Skeleton
} from "@mui/material";
import {
  ScheduleOutlined
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {CARD_CAPTION_STYLE} from "../../../../constants/Styles";
import {ContainedButton} from "../../../common/StyledButtons";
import {withRouter} from "react-router";
import {getSiteHoursReportByIdAndTime} from "../../../../selectors/SiteSelectors";
import {observer} from "mobx-react-lite";
import {getSiteHoursReport} from "../../../../actions/internal/SiteActions";
import {getMonthName} from "../../../../utilities/DateUtilities";

const ConsultingCard = ({ match, history }) => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

  const [ isLoading, setIsLoading ] = useState(true);

  const siteId = match.params.site_id
  const hoursReport = getSiteHoursReportByIdAndTime(siteId, startDate.getTime(), endDate.getTime())

  useEffect(() => {
    loadData().then();
  }, [])

  const loadData = async () => {
    try {
      await getSiteHoursReport(siteId, startDate.getTime(), endDate.getTime())
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const renderLoadingContent = () => (
    <>
      <Skeleton variant="rectangular" width={85} height={36} sx={{ marginBottom: '5px' }} />
      <Skeleton variant="rectangular" width={210} height={20} />
    </>
  )

  const getCurrentTotalHours = () =>
    hoursReport
    && hoursReport.report
    && hoursReport.report.current_hours
    && hoursReport.report.current_hours.total_hours

  const getCurrentTotalHoursMessage = () => {
    if (renderLoadingContent() === undefined) return 'Not currently available.'
    return `for ${getMonthName(new Date().getMonth())}.`
  }

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
          <div style={CARD_CAPTION_STYLE}>Consulting</div>
          <ScheduleOutlined sx={{ color: '#666' }}/>
        </Box>
        {isLoading ? renderLoadingContent() :
        <Box>
          <h4 style={{ margin: 0 }}>{getCurrentTotalHours() === undefined ? '--' : getCurrentTotalHours()} hours</h4>
          <p style={{ margin: 0 }}>{getCurrentTotalHoursMessage()}</p>
        </Box>
        }
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '32px', position: 'relative', zIndex: 15}}>
          <ContainedButton
            sx={{ padding: '8px 16px 8px 16px' }}
            onClick={() => history.push(`/dashboard/site/${siteId}/consulting`)}
          >View</ContainedButton>
        </Box>
      </Paper>
    </Grid>
  )
}

export default observer(withRouter(ConsultingCard));
