import {
  Grid,
  Box, Skeleton
} from "@mui/material";
import {
  ReceiptOutlined
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {CARD_CAPTION_STYLE} from "../../../../constants/Styles";
import {ContainedButton} from "../../../common/StyledButtons";
import {withRouter} from "react-router";
import { getSiteInvoiceReportById } from "../../../../selectors/SiteSelectors";
import {observer} from "mobx-react-lite";
import {getSitePaymentReport} from "../../../../actions/internal/SiteActions";

const InvoicesCard = ({ match, history }) => {

  const [ isLoading, setIsLoading ] = useState(true);

  const siteId = match.params.site_id
  const invoiceReport = getSiteInvoiceReportById(siteId)

  useEffect(() => {
    loadData().then();
  }, [])

  const loadData = async () => {
    try {
      await getSitePaymentReport(siteId)
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const getCurrentAmountDue = () =>
    invoiceReport
    && invoiceReport.report
    && invoiceReport.report.open_invoices
    && invoiceReport.report.open_invoices.invoice_amount_due !== undefined
    && invoiceReport.report.open_invoices.invoice_amount_due / 100;


  const getAmountDueMessage = () => {
    if (getCurrentAmountDue() === undefined) return 'Not currently available.'
    else if (getCurrentAmountDue() <= 0) return 'You\'re all caught up! 🎉'
    return 'is currently due.'
  }

  const renderLoadingContent = () => (
    <>
      <Skeleton variant="rectangular" width={85} height={36} sx={{ marginBottom: '5px' }} />
      <Skeleton variant="rectangular" width={210} height={20} />
    </>
  )

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
          <div style={CARD_CAPTION_STYLE}>Billing</div>
          <ReceiptOutlined sx={{ color: '#666' }}/>
        </Box>
        {isLoading ? renderLoadingContent() :
        <Box>
          <h4 style={{ margin: 0 }}>${getCurrentAmountDue() === undefined ? '-.--' : getCurrentAmountDue().toFixed(2)}</h4>
          <p style={{ margin: 0 }}>{getAmountDueMessage()}</p>
        </Box>
        }
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '32px', position: 'relative', zIndex: 15}}>
          <ContainedButton
            sx={{ padding: '8px 16px 8px 16px' }}
            onClick={() => history.push(`/dashboard/site/${siteId}/billing`)}
          >View</ContainedButton>
        </Box>
      </Paper>
    </Grid>
  )
}

export default observer(withRouter(InvoicesCard));
