import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, TextButton } from '../../common/StyledButtons';

const ConfirmationModal = ({
    open,
    title = "Are You Sure?", description,
    onOk, onCancel,
    okButtonText = "Ok", cancelButtonText = "Cancel"
}) => (
    <Dialog
        aria-labelledby="confirmation-modal"
        open={open}
        maxWidth={'md'}
    >
        <DialogTitle>
            <h4 style={{ margin: 0, padding: 0 }}>{title}</h4>
        </DialogTitle>
        <DialogContent>
            <p>{description}</p>
        </DialogContent>
        <DialogActions>
            {onCancel && <TextButton onClick={onCancel}>{cancelButtonText}</TextButton> }
            <ContainedButton onClick={onOk}>{okButtonText}</ContainedButton>
        </DialogActions>
    </Dialog>
)

export default ConfirmationModal
