import {observable, action, makeObservable} from "mobx";

class SitesStore {
    sites = undefined;
    siteById = {};
    site_analytics = {};
    site_hour_reports = {}
    site_invoice_reports = {}
    site_subscriptions = {}
    site_submissions = {}

    constructor() {
        makeObservable(this, {
            sites: observable,
            site_hour_reports: observable,
            site_invoice_reports: observable,
            site_subscriptions: observable,
            site_analytics: observable,
            site_submissions: observable,

            setSites: action,
            setSiteHourReport: action,
            setSiteInvoiceReport: action,
            setSiteSubscriptionReport: action,
            setSiteSubmissions: action
        })
    }

    initialize() {
        this.sites = undefined;
        this.siteById = {};
        this.site_analytics = {};
        this.site_hour_reports = {};
        this.site_invoice_reports = {};
        this.site_subscriptions = {};
        this.site_submissions = {};
    }

    setSites(sites) {
        this.sites = sites;
    }

    setSite(site) {
        if (!site || !site.site_id) return;
        this.siteById[site.site_id] = site;
    }

    setSiteAnalytics(site_id, analytics) {
        if (!site_id || !analytics) return
        this.site_analytics[site_id] = analytics;
    }

    setSiteHourReport(site_id, time_key, hour_report) {
        if (!site_id || !hour_report) return
        if (this.site_hour_reports[site_id]) {
            this.site_hour_reports[site_id][time_key] = hour_report;
        } else {
            this.site_hour_reports[site_id] = { [time_key]: hour_report }
        }
    }

    setSiteInvoiceReport(site_id, invoice_report) {
        if (!site_id || !invoice_report) return
        this.site_invoice_reports[site_id] = invoice_report;
    }

    setSiteSubscriptionReport(site_id, subscription) {
        if (!site_id || !subscription) return
        this.site_subscriptions[site_id] = subscription;
    }

    setSiteSubmissions(site_id, submissions) {
        if (!site_id || !submissions) return;
        this.site_submissions[site_id] = submissions;
    }
};

/* makeObservable(SitesStore, {
    sites: observable,
    site_hour_reports: observable,
    site_invoice_reports: observable,
    site_subscriptions: observable,

    setSites: action,
    setSiteHourReport: action,
    setSiteInvoiceReport: action,
    setSiteSubscriptionReport: action,
}); */

export default new SitesStore();
