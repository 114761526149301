import React from "react";
import { ICON_TEXT_STYLE } from "../../constants/Styles";
import { Box } from "@mui/material";

const IconTextSection = ({ icon, primaryText, secondaryText, style = {}, primaryTextStyle = {}, secondaryTextStyle = {}}) => (
 <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 auto', gap: '10px', ...style }}>
  <Box>
    {icon}
  </Box>
  <Box sx={{ flex: '20 1 auto' }}>
    <div style={{ ...ICON_TEXT_STYLE, ...primaryTextStyle }}>{primaryText}</div>
    <div style={{ ...ICON_TEXT_STYLE, ...secondaryTextStyle }}>{secondaryText}</div>
  </Box>
 </Box>

)

export default IconTextSection;
