import React from "react";
import { Route, Switch, withRouter } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from "react";
import { NOTIFICATION_TYPE, showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getUserId, isAdmin } from "../../../actions/profile/UserActions";
import Colors from "../../../constants/Colors";
import GenericNotFound from "../../error-pages/GenericNotFound";
import AdminComponent from "./AdminComponent";
import { getAllSitesForAdmin, getAllSubmissionsForAdmin } from "../../../actions/internal/AdminActions";
import AdminBusinessesPage from "./AdminBusinessesPage";
import AdminManageBusinessPage from "./AdminManageBusinessPage";
import AdminEditBusinessAccountPage from "./AdminEditBusinessAccountPage";
import AdminEditSubscriptionPage from "./AdminEditSubscriptionPage";
import AdminProjectsPage from "./AdminProjectsPage";
import AdminViewJobDetailsComponent from "./AdminViewJobDetailsComponent";
import AdminEditProjectComponent from "./AdminEditProjectComponent";
import { StyledLoadingSection } from "../../common/StyledProgressCircle";

const AdminRouter = ({ history }) => {

    const [isLoading, setIsLoading] = useState(true)

    const loadData = async () => {
        if (!isAdmin()) {
            history.replace('/dashboard');
            return;
        }

        try {
            const userId = getUserId();
            await Promise.all([
                getAllSitesForAdmin(userId),
                getAllSubmissionsForAdmin(userId, true)
            ])
        } catch(err) {
            const e = await err;
            console.error(e);
            showNotificationBar(NOTIFICATION_TYPE.ERROR, 'Uh oh! There was a problem retrieving site information.')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadData();
    }, [loadData])

    const loadingContent = (
        <StyledLoadingSection />
    )

    const mainContent = (
    <Switch>
        <Route exact path="/admin" render={routeProps => <AdminComponent {...routeProps} />} />

        {/* Sites Routes */}
        <Route exact path="/admin/sites" render={routeProps => <AdminBusinessesPage {...routeProps} />} />
        <Route exact path="/admin/sites/:site_id/manage" render={routeProps => <AdminManageBusinessPage {...routeProps} />} />
        <Route exact path="/admin/sites/:site_id/details/edit" render={routeProps => <AdminEditBusinessAccountPage {...routeProps} />} />
        <Route exact path="/admin/sites/:site_id/subscription/edit" render={routeProps => <AdminEditSubscriptionPage {...routeProps} />} />

        {/* Projects Routes */}
        <Route exact path="/admin/projects" render={routeProps => <AdminProjectsPage {...routeProps} />} />
        <Route exact path="/admin/projects/:project_id/manage" render={routeProps => <AdminViewJobDetailsComponent {...routeProps} />} />
        <Route exact path="/admin/projects/:project_id/edit" render={routeProps => <AdminEditProjectComponent {...routeProps} />} />

        {/* Misc Routes */}
        <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
    </Switch>)

    return (
        <>
            {isLoading ? loadingContent : mainContent}
        </>
    );
}

export default withRouter(AdminRouter);

/*
<Route exact path="/admin/users/add" render={routeProps => <AdminAddUserComponent {...routeProps} />} />
            <Route exact path="/admin/sites/add" render={routeProps => <AdminAddSiteComponent {...routeProps} users={users} />} />
            <Route exact path="/admin/sites/:site_id/edit" render={routeProps => <AdminEditSiteComponent {...routeProps} users={users} />} />

            <Route exact path="/admin/sites/:site_id" render={routeProps => <AdminManageSiteComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/jobs/add" render={routeProps => <AdminAddJobComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/sites/:site_id/jobs" render={routeProps => <AdminEditJobComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/sites/:site_id/jobs/:job_id/edit" render={routeProps => <AdminEditJobComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/users/:user_id" render={routeProps => <AdminManageUserComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/users/:user_id/edit" render={routeProps => <AdminEditUserComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/subscription/:site_id/create" render={routeProps => <AdminCreateSubscriptionComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/subscription/:site_id/edit" render={routeProps => <AdminEditSubscriptionComponent {...routeProps} sites={sites} users={users} />} />
*/
