export const COMPANY_TYPES = [
  {
    value: 'SP',
    label: 'Sole Proprietorship',
  },
  {
    value: 'GP',
    label: 'General Partnership',
  },
  {
    value: 'LP',
    label: 'Limited Partnership',
  },
  {
    value: 'LLP',
    label: 'Limited Liability Partnership (LLP)',
  },
  {
    value: 'LLC',
    label: 'Limited Liability Company (LLC)',
  },
  {
    value: 'INC',
    label: 'Corporation',
  },
  {
    value: 'CCORP',
    label: 'Corporation - C Corp',
  },
  {
    value: 'SCORP',
    label: 'Corporation - S Corp',
  },
  {
    value: 'BCORP',
    label: 'Corporation - B Corp',
  },
  {
    value: 'INC',
    label: 'Incorporated (INC)',
  },
  {
    value: 'PC',
    label: 'Professional Corporation (P.C.)',
  },
  {
    value: 'NFP',
    label: 'Not For Profit (NFP)',
  },
  {
    value: 'NONE',
    label: 'Not Yet Structured',
  },
  {
    value: 'IDK',
    label: "I Don't Know",
  },
  {
    value: 'OTHER',
    label: "Other",
  }
];

export const JOB_STRUCTURES = {
  QUOTE: "QUOTE",
  HOURLY: "HOURLY",
  NO_COST: "NO_COST"
}

export const JOB_STRUCTURES_LABELS = {
  [JOB_STRUCTURES.QUOTE]: 'Fixed Price',
  [JOB_STRUCTURES.HOURLY]: 'Hourly',
  [JOB_STRUCTURES.NO_COST]: 'No Cost'
}

export const PACKAGE_NAMES = {
  LITE_HOSTING_LEGACY: 'Lite Hosting',
  E_COMMERCE_HOSTING: 'E-Commerce Hosting',
  BLOG_VLOG_HOSTING: 'Blog Hosting',
  MULTI_SITE_HOSTING: 'Multi Site Hosting',
  STANDARD_HOSTING_LEGACY: 'Standard Hosting',
  PARKED: 'Parked Site',
}
