import UserStore from '../../stores/profile/UserStoreV2';

import find from 'lodash/find';
import { getAttributes, isLoggedIn } from '../../clients/profile/ProfileClient';
import UserStoreV2 from '../../stores/profile/UserStoreV2';
import { getUser } from '../../clients/bettercallkyle/BckApi';
import { cloneDeep } from 'lodash';
import SitesStore from '../../stores/internal/SitesStore';

export const signUserOut = () => {
    if(UserStore.user_cognito_object) {
        UserStore.user_cognito_object.signOut();
    }
    UserStore.initialize();
    SitesStore.initialize();
}

export const getCurrentUser = async () => {
    try {
        const cognitoUser = await isLoggedIn();
        if (cognitoUser) {
            return setUserFromCognito(cognitoUser);
        }
    } catch (err) {
        return undefined;
    }
}

export const setUserFromCognito = async (cognitoUser) => {
    try {
        const attributes = await getAttributes(cognitoUser)
        
        const user_id_attr = find(attributes, {'name': 'custom:user_id'});
        // FOR OLD USERS (Need for admin account kyle@consultwithkyle.com and other clients)
        const user_id_attr_backup = find(attributes, {'name': 'custom:custom:user_uuid'});
        const user_id = user_id_attr !== undefined ? user_id_attr : user_id_attr_backup;

        if (!user_id) return undefined;
        const user = await getUser(user_id.value);

        UserStoreV2.setUserObject(user);
        UserStoreV2.setUserCognitoObject(cognitoUser)
        return user;
    } catch (err) {
        throw err;
    }
}

export const getUserObject = () => {
    return cloneDeep(UserStoreV2.user_object);
}

export const getUserId = () => {
    return UserStoreV2.user_object ? UserStoreV2.user_object.user_id : undefined;
}

export const isUserAdmin = () => {
    return UserStoreV2.user_object.user_security.is_admin;
}

export const getUserPersonalData = () => {
    return UserStoreV2.user_object ? UserStoreV2.user_object.user_personal : undefined;
}

export const getUserAccessibleSites = () => {
    return UserStoreV2.user_sites ? UserStoreV2.user_sites.accessible_site_ids : undefined;
}

export const isAdmin = () => {
    return UserStoreV2.user_object ? UserStoreV2.user_object.user_security.is_admin : false;
}