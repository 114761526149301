import {observable, action, makeObservable} from "mobx";

class UserStoreV2 {
    user_object = undefined;
    user_cognito_object = undefined;

    constructor() {
        makeObservable(this, {
            user_object: observable,
            user_cognito_object: observable,

            setUserObject: action,
            setUserCognitoObject: action,
        })
    }

    initialize() {
        this.user_object = undefined;
        this.user_cognito_object = undefined;
    }

    setUserObject(user_object) {
        this.user_object = user_object;
    }

    setUserCognitoObject(cognitoUser) {
        this.user_cognito_object = cognitoUser;
    }
};

export default new UserStoreV2();
