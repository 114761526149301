import React from "react";
import { StyledTextField } from "./StyledTextField";

export const StyledDropDown = ({
  children,
  textFieldStyle = {},
  ...props
}) => (
  <StyledTextField
    select
    {...props}
>
  {children}
  </StyledTextField>  
)