import { Button, CircularProgress, FormControlLabel, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Radio, RadioGroup, TextField, Typography, Switch, Box, Divider, InputAdornment, ListItemAvatar, Avatar } from '@mui/material';
import { Add, Close, Delete, Edit, PersonAddOutlined, PersonOutline } from "@mui/icons-material";
import { isEmpty, isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { NOTIFICATION_TYPE, showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { updateJob } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { JOB_STATUS, JOB_STATUS_LOOKUP, JOB_STATUS_MAP } from "../../../constants/Statuses";
import { generateShortUIDString } from '../../../utilities/StringFormatUtilities';
import { getProjectForAdmin } from '../../../actions/internal/AdminActions';
import StyledSubHeader from '../../common/StyledSubHeader';
import StyledProgressCircle from '../../common/StyledProgressCircle';
import StyledSwitch from '../../common/StyledSwitch';
import { getProjectById } from '../../../selectors/AdminSelectors';
import { LoadingButton, TextButton, TooltipIconButton } from '../../common/StyledButtons';
import { SUMMARY_TITLE_FONT, CARD_SUB_HEADER_STYLE } from '../../../constants/Styles';
import { formatDateTimeToFancyString } from '../../../utilities/DateUtilities';
import { StyledMultilineTextField, StyledTextField } from '../../common/StyledTextField';
import { JOB_STRUCTURES, JOB_STRUCTURES_LABELS } from '../../../constants/Common';
import AddLineItemModal from '../modals/AddLineItemModal';
import AdminEditProjectAccessModal from './AdminEditProjectAccessModal';
import StyledChip from '../../common/StyledChip';

const AdminEditProjectComponent = ({ match, history }) => {
    const originalFields = useRef();
    const editEnvironmentVariables = useRef({ isProjectAccepted: false })

    const [ isLoading, setIsLoading ] = useState(true);
    const [ errors, setErrors ] = useState([]);
    const [ fields, setFields ] = useState({});
    const [ showAddLineItemModal, setShowAddLineItemModal ] = useState(false);
    const [ showEditAccessModal, setShowEditAccessModal ] = useState(false);

    const jobId = match.params.project_id;
    const job = getProjectById(jobId);

    useEffect(() => {
        loadData().then();
    }, [])

    const isProjectAccepted = (project) => ![JOB_STATUS_MAP.DRAFT, JOB_STATUS_MAP.PENDING].includes(project.job_details.job_status);
    const setSessionFields = (project) => {
        const { job_details, job_quote, job_hours, job_security } = project;
        const fields = {
            project_name: job_details.job_name,
            project_description: job_details.job_description,
            project_payment_structure: job_details.job_payment_structure,
            project_quote_items: job_quote ? job_quote.quote_items : [],
            is_hidden: job_security.is_hidden,

            quote_amount_due_at_start: job_quote ? formatUsdToString(job_quote.quote_amount_due_at_start) : 0,
            quote_amount_due_at_complete: job_quote ? formatUsdToString(job_quote.quote_amount_due_at_complete) : 0,

            hours_clockify_project_id: job_hours ? job_hours.hours_clockify_project_id : '',
            hours_estimated_total: job_hours ? job_hours.hours_estimated_total : 0,

            added_users: [],
            removed_users: []
        }
        originalFields.current = fields;
        editEnvironmentVariables.current = {
            isProjectAccepted: isProjectAccepted(project)
        }
        setFields(fields);
    }

    const calculateItemTotal = () => fields.project_quote_items
        .reduce((acc, val) => {
            return acc + val.item_total
        }, 0)
    
    const calculateRequiredEndingAmount = () => {
        const total = calculateItemTotal();
        const startPrice = formatStringToUsd(fields.quote_amount_due_at_start);
        return (total - startPrice);
    }

    const formatUsdToString = (usd) => {
        let num = usd;
        if (isNaN(usd)) num = 0;
        return (parseFloat(num) / 100).toFixed(2);
    }

    const formatStringToUsd = (str) => {
        let num = str;
        if (isNaN(str)) num = 0;
        return Math.floor(parseFloat(num) * 100)
    }

    const loadData = async () => {
        try {
            const project = await getProjectForAdmin(getUserId(), jobId)
            setSessionFields(project);
        } catch (ex) {
            const e = await ex;
            console.error(e);
            showNotificationBar(NOTIFICATION_TYPE.ERROR, `Uh oh! Something went wrong when fetching project data. Details: ${e.message}`);
        } finally {
            setIsLoading(false);
        }
    }

    const handleFieldChange = (key, value) => {
        const updatedFields = { ...fields };
        updatedFields[key] = value;
        setFields(updatedFields)
    }

    const handleAddLineItem = (item) => {
        const items = [ ...fields.project_quote_items, item ];
        handleFieldChange('project_quote_items', items);
        setShowAddLineItemModal(false);
    }

    const handleRemoveLineItemById = (id) => {
        const items = fields.project_quote_items.filter(item =>  item.item_id !== id);
        handleFieldChange('project_quote_items', items);
    }

    const handleUpdateProjectAccess = (added_users = [], removed_users = []) => {
        const updatedFields = { ...fields, added_users, removed_users };
        setFields(updatedFields)
    }
    
    const renderLoadingContent = () => (
        <StyledProgressCircle />
    )

    const renderProjectDetailsSection = () => (
        <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
                <StyledTextField
                    error={errors.includes('project_name')}
                    value={fields.project_name}
                    label="*Title"
                    onChange={(e) => handleFieldChange('project_name', e.target.value)}
                />
                <StyledMultilineTextField
                    label="*Description"
                    onChange={(e) => handleFieldChange('project_description', e.target.value)}
                    error={errors.includes('project_description')}
                    value={fields.project_description}
                />
            </Grid>
        </Grid>
    )

    const renderSummaryStructureDescription = () => {
        if (!job || !job.job_details || !job.job_details.job_payment_structure) return undefined;
        return (
            <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                    <div style={{marginTop: '30px', marginBottom: '18px' }}><span style={{paddingRight: '35px', ...SUMMARY_TITLE_FONT }}>Current Status</span>{JOB_STATUS_LOOKUP[job.job_details.job_status].label}</div>
                    <Divider />
                    <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '60px', ...SUMMARY_TITLE_FONT }}>Site Name</span>{job.job_site.site_name || <em>Not Available</em>}</div>
                    <Divider />
                    <div style={{marginTop: '18px', marginBottom: '18px' }}><span style={{paddingRight: '40px', ...SUMMARY_TITLE_FONT }}>Last Updated</span>{formatDateTimeToFancyString(job.job_metadata.updated_at)}</div>
                    <Divider />
                </Grid>
            </Grid>
            
        )
    }

    const renderFixedPriceSection = () => {
        const { job_quote } = job;
        return (
            <>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <div style={{ ...SUMMARY_TITLE_FONT, fontSize: '20px', marginTop: '10px' }}>Project Line Items</div>
                        {fields.project_quote_items.length === 0 &&
                            <p style={{ color: Colors.redColor, margin: 0, padding: 0 }}>You need at least one item for this payment style.</p>
                        }
                    </Box>
                    <Box sx={{ marginTop: '5px' }}>
                        <TooltipIconButton
                            text={'Add Line Item'}
                            icon={<Add sx={{ color: Colors.primaryColor, fontSize: '24px' }} />}
                            onClick={() => setShowAddLineItemModal(true) }
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <List style={{ flex: 1 }}>
                        {fields.project_quote_items.map((item) => (
                            <ListItem
                                key={item.item_id}
                                dense
                                sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                                <ListItemText primary={item.item_name} secondary={`$${(item.item_unit_cost/100).toFixed(2)} x ${item.item_units} units = $${(item.item_total/100).toFixed(2)}`} />
                                <ListItemSecondaryAction>
                                    <TooltipIconButton
                                        text={'Delete Line Item'}
                                        icon={<Delete sx={{ color: Colors.redColor, fontSize: '24px' }} />}
                                        onClick={() => handleRemoveLineItemById(item.item_id)}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <div style={{ ...SUMMARY_TITLE_FONT, fontSize: '20px', marginTop: '10px' }}>Quote Pricing</div>
                        <p>Provide the timing and amounts for payments for this quote. The prices must add up to the total quote amount of <strong>${formatUsdToString(calculateItemTotal())}</strong>.</p>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledTextField
                        error={errors.includes('quote_amount_due_at_start')}
                        helperText={errors.includes('quote_amount_due_at_start') && 'Please provide a valid starting amount.'}
                        value={fields.quote_amount_due_at_start}
                        onChange={(e) => handleFieldChange('quote_amount_due_at_start', e.target.value)}
                        label="Start Project Price"
                        disabled={editEnvironmentVariables.current.isProjectAccepted}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledTextField
                        error={errors.includes('quote_amount_due_at_complete')}
                        helperText={`Ending amount must be $${formatUsdToString(calculateRequiredEndingAmount())}`}
                        value={fields.quote_amount_due_at_complete}
                        onChange={(e) => handleFieldChange('quote_amount_due_at_complete', e.target.value)}
                        label="Complete Project Price"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
            </>
        )
    }

    const renderHourlyPriceSection = () => {
        const { job_hours } = job;
        return (
            <>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <div style={{ ...SUMMARY_TITLE_FONT, fontSize: '20px', marginTop: '10px' }}>Clockify & Hour Tracking</div>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        error={errors.includes('hours_clockify_project_id')}
                        helperText={errors.includes('hours_clockify_project_id') && 'Please provide a valid clockify project id.'}
                        value={fields.hours_clockify_project_id}
                        onChange={(e) => handleFieldChange('hours_clockify_project_id', e.target.value)}
                        label="Clockify Project ID"
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        error={errors.includes('hours_estimated_total')}
                        helperText={errors.includes('hours_estimated_total') && 'Please provide a valid clockify project id.'}
                        value={fields.hours_estimated_total}
                        onChange={(e) => handleFieldChange('hours_estimated_total', e.target.value)}
                        label="Project Estimated Hours"
                        type="number"
                    />
                </Grid>
            </>
        )
    }

    const renderPaymentOptionsSection = () => {
        return (
            <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                    <StyledTextField
                        select
                        error={errors.includes('project_payment_structure')}
                        label="*Payment Style"
                        value={fields.project_payment_structure}
                        onChange={(e) => handleFieldChange('project_payment_structure', e.target.value)}
                        disabled={editEnvironmentVariables.current.isProjectAccepted}
                    >
                        {(Object.keys(JOB_STRUCTURES)).map((structure_key) => (
                        <MenuItem key={structure_key} value={structure_key}>
                            {JOB_STRUCTURES_LABELS[structure_key]}
                        </MenuItem>
                        ))}
                    </StyledTextField>
                </Grid>
                {fields.project_payment_structure === JOB_STRUCTURES.QUOTE && renderFixedPriceSection()}
                {fields.project_payment_structure === JOB_STRUCTURES.HOURLY && renderHourlyPriceSection()}
            </Grid>
        )
    }

    const renderAdditionalOptionsSection = () => {
        return (
            <Grid container spacing={1} sx={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                    <StyledSwitch
                        title="Hide From Client"
                        description="Do not allow any clients to view this project."
                        onChange={(e) => { handleFieldChange('is_hidden', e.target.checked) }}
                        checked={fields.is_hidden}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderAccessSection = () => {
        return (
            <>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <h4 style={{ margin: 0, fontSize: '24px' }}>Project Access</h4>
                        <p>This is the list of users that have access to view the project.</p>
                    </Box>
                    <Box sx={{ marginTop: '5px' }}>
                        <TooltipIconButton
                            text={'Edit User Project Access'}
                            icon={<Edit sx={{ fontSize: '24px' }} />}
                            onClick={() => setShowEditAccessModal(true) }
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {job && job.job_security.accessible_users.map((user) => (
                        <ListItem
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                            <ListItemAvatar>
                                <Avatar><PersonOutline /></Avatar>
                            </ListItemAvatar>
                            {fields.removed_users.includes(user.user_contact.email.primary) ? 
                                <ListItemText primary={<strike>{user.user_personal.first_name} {user.user_personal.last_name}</strike>} secondary={<strike>{user.user_contact.email.primary}</strike>}/>
                            :
                                <ListItemText primary={`${user.user_personal.first_name} ${user.user_personal.last_name}`} secondary={user.user_contact.email.primary} />
                            }
                            {user.is_primary_user && (<ListItemSecondaryAction>
                                <StyledChip text="Primary" />
                            </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                    {fields.added_users.map((user) => (
                        <ListItem
                            key={user.email}
                            dense
                            sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                            <ListItemAvatar>
                                <Avatar><PersonAddOutlined /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                        </ListItem>
                    ))}
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container>
            <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
                <StyledSubHeader title="Edit Project" closeIcon />
                {isLoading ? renderLoadingContent() :
                    <Paper variant="outlined" sx={{ padding: '16px', marginTop: '20px'}}>
                        <h4 style={{ margin: 0, fontSize: '24px' }}>Project Details</h4>
                        <p>Provide the name and description for the project.</p>
                        {renderProjectDetailsSection()}
                        <h4 style={{ margin: 0, fontSize: '24px' }}>Summary</h4>
                        <p>This includes ownership and progress data for reference that cannot be edited.</p>
                        {renderSummaryStructureDescription()}
                        <h4 style={{ margin: 0, fontSize: '24px' }}>Pricing Options</h4>
                        <p>Select the payment style you would like to use for this project. Please note that the payment style cannot be edited once the project has been accepted.</p>
                        {renderPaymentOptionsSection()}
                        <h4 style={{ margin: 0, fontSize: '24px' }}>Additional Options</h4>
                        <p>These are provided advanced options to assist or protect the project.</p>
                        {renderAdditionalOptionsSection()}
                        {renderAccessSection()}
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <TextButton onClick={() => history.goBack() } sx={{ marginRight: '10px' }}>
                                Cancel
                            </TextButton>
                            <LoadingButton
                                isLoading={isLoading}
                                disabled={isEqual(fields, originalFields.current)}
                                onClick={() => {}} color="primary">Save</LoadingButton>
                        </Box>
                    </Paper>
                }
                
            </Grid>
        </Grid>
        <AddLineItemModal
            open={showAddLineItemModal}
            onClose={ () => setShowAddLineItemModal(false) }
            onAdd={handleAddLineItem}
        />
        <AdminEditProjectAccessModal
            open={showEditAccessModal}
            onClose={ () => setShowEditAccessModal(false) }
            onSave={handleUpdateProjectAccess}
            job={job}
            added_users={fields.added_users}
            removed_users={fields.removed_users}
        />
      </>
    )
}

export default AdminEditProjectComponent;

/*
const [ startJobPrice, setStartJobPrice ] = useState();
    const [ endJobPrice, setEndJobPrice ] = useState();

    const [ paymentStructure, setPaymentStructure ] = useState();
    const [ expectedTotalJobPrice, setExpectedTotalJobPrice ] = useState(0);

    const [ quoteItems, setQuoteItems ] = useState([]);
    const [ quoteItemDescription, setQuoteItemDescription] = useState();
    const [ quoteItemUnits, setQuoteItemUnits] = useState();
    const [ quoteItemCostPerUnit, setQuoteItemCostPerUnit] = useState();

    const [ jobName, setJobName ] = useState();
    const [ jobDescription, setJobDescription ] = useState();

    const [ hoursEstimation, setHoursEstimation ] = useState();
    const [ clockifyProjectId, setClockifyProjectId ] = useState();

    const [ accessibleUserIds, setAccessibleUserIds ] = useState();
    const [ isHidden, setIsHidden ] = useState();

    const siteRef = useRef(sites.find(site => site.site_id === match.params.site_id))
    const jobRef = useRef()
    const usersRef = useRef()

    const statusRef = useRef();

    const jobId = match.params.project_id;
    // const job = getProjectById(jobId);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            setIsLoading(true)
            await getProjectForAdmin(getUserId(), jobId);
        } catch (err) {
            showNotificationBar('error', 'Could not job data at this time.');
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const renderRadioLabel = (title, description) => (
        <div className={classes.radioText}><b>{title}: </b><span style={{ fontSize: 16 }}>{description}</span></div>
    )

    const handleRemoveId = (id) => {
        const selectedIds = accessibleUserIds || jobRef.current.job_security.accessible_user_ids
        const index = selectedIds.indexOf(id);
        const arr = [...selectedIds]
        arr.splice(index, 1)
        setAccessibleUserIds(arr);
    }

    const isValidForm = () => {
        const foundErrors = [];
        // const jobStatus = jobRef.current.job_details.job_status;

        if (jobName !== undefined && isEmpty(jobName)) foundErrors.push('jobName');
        if (jobDescription !== undefined && isEmpty(jobDescription)) foundErrors.push('jobDescription');
        switch(paymentStructure) {
            case 'QUOTE':
                const { quote_amount_due_at_start, quote_amount_due_at_complete } = jobRef.current.job_quote || {}
                if (startJobPrice !== undefined && (isEmpty(startJobPrice) || isNaN(startJobPrice) || Number(startJobPrice) < 0) ) foundErrors.push('startJobPrice')
                if (endJobPrice !== undefined && (isEmpty(endJobPrice) || isNaN(endJobPrice) || Number(endJobPrice) < 0) ) foundErrors.push('endJobPrice')

                const providedStartJobPrice = startJobPrice || (quote_amount_due_at_start && (quote_amount_due_at_start / 100)) || 0
                const providedEndJobPrice = endJobPrice || (quote_amount_due_at_complete && (quote_amount_due_at_complete / 100)) || 0

                if ((!foundErrors.includes('startJobPrice') && !foundErrors.includes('endJobPrice')) && (providedEndJobPrice*100) + (providedStartJobPrice*100) !== expectedTotalJobPrice) foundErrors.push('totalJobPrice');

                if (!quoteItems || quoteItems.length === 0) {
                    showNotificationBar('error', 'You need to include at least one quote item before creating this quote. Otherwise, please select "Hourly" or "No Cost".')
                    foundErrors.push('quoteItemDescription')
                }
                break;
            case 'HOURLY':
            case 'PRO_BONO':
                if ((hoursEstimation !== undefined && hoursEstimation !== '') && (isNaN(hoursEstimation) || Number(hoursEstimation) < -1 || Number(hoursEstimation) === 0 )) foundErrors.push('hoursEstimation')
                if ((clockifyProjectId !== undefined && isEmpty(clockifyProjectId)) || (clockifyProjectId === undefined && isEmpty(jobRef.current.job_hours.hours_clockify_project_id))) foundErrors.push('clockifyProjectId')
                break;
            case 'NO_COST':
            default:
                break;
        }
        if (startJobPrice !== undefined && (isEmpty(startJobPrice) || isNaN(startJobPrice) || Number(startJobPrice) < 0) ) foundErrors.push('startJobPrice')
        if (endJobPrice !== undefined && (isEmpty(endJobPrice) || isNaN(endJobPrice) || Number(endJobPrice) < 0) ) foundErrors.push('endJobPrice')
        if (accessibleUserIds !== undefined && (isEmpty(accessibleUserIds))) foundErrors.push('accessibleUserIds')
        setErrors(foundErrors)
        return foundErrors.length === 0
    }

    const calculateQuickTotal = () => {
        if (!quoteItemUnits || isEmpty(quoteItemUnits) || isNaN(quoteItemUnits) || Number(quoteItemUnits) < 0 ) return '0.00'
        if (!quoteItemCostPerUnit || isEmpty(quoteItemCostPerUnit) || isNaN(quoteItemCostPerUnit) || Number(quoteItemCostPerUnit) < 0 ) return '0.00'
        return (Number(quoteItemUnits) * Number(quoteItemCostPerUnit)).toFixed(2)
    }

    const isValidQuoteItem = () => {
        const foundErrors = [];
        if (!quoteItemDescription || isEmpty(quoteItemDescription)) foundErrors.push('quoteItemDescription');
        if (!quoteItemUnits || isEmpty(quoteItemUnits) || isNaN(quoteItemUnits) || Number(quoteItemUnits) < 0 ) foundErrors.push('quoteItemUnits')
        if (!quoteItemCostPerUnit || isEmpty(quoteItemCostPerUnit) || isNaN(quoteItemCostPerUnit) || Number(quoteItemCostPerUnit) < 0 ) foundErrors.push('quoteItemCostPerUnit')

        setErrors(foundErrors)
        return foundErrors.length === 0
    }

    const handleAddQuoteItem = () => {
        if (!isValidQuoteItem()) return;
        const quoteItemTotal = 100 * Number(quoteItemCostPerUnit) * Number(quoteItemUnits)
        const quoteItem = {
            item_id: generateShortUIDString(),
            item_name: quoteItemDescription,
            item_unit_cost: Number((100 * Number(quoteItemCostPerUnit))),
            item_units: Number(quoteItemUnits),
            item_discount: 0,
            item_total: Number(quoteItemTotal)
        }
        setQuoteItems([...quoteItems, quoteItem])
        setQuoteItemDescription('')
        setQuoteItemUnits('')
        setQuoteItemCostPerUnit('')
        setExpectedTotalJobPrice(quoteItemTotal + expectedTotalJobPrice)
    }

    const handleRemoveQuoteItem = (item_id) => {
        const removedItem = quoteItems.find(qi => qi.item_id === item_id)
        const filteredQuoteItems = quoteItems.filter(qi => qi.item_id !== item_id)
        setQuoteItems(filteredQuoteItems)
        setExpectedTotalJobPrice(expectedTotalJobPrice - removedItem.item_total)
    }

    const saveJobEdits = async () => {
        if(!isValidForm()) return;
        try {
            setIsLoading(true);
            const payload = {};
            const masks = [];
            if (jobName !== undefined && jobName !== jobRef.current.job_details.job_name) {
                masks.push('job_name')
                payload.job_name = jobName;
            }
            if (jobDescription !== undefined && jobDescription !== jobRef.current.job_details.job_description) {
                masks.push('job_description')
                payload.job_description = jobDescription;
            }
            if (paymentStructure === 'QUOTE' && (startJobPrice !== undefined || endJobPrice !== undefined || !isEqual(jobRef.current.job_quote.quote_items, quoteItems))) {
                masks.push('job_quote')
                const { quote_amount_due_at_start, quote_amount_due_at_complete } = jobRef.current.job_quote || {}

                const quoteAmountAtStartValue = (startJobPrice !== undefined && (Number(startJobPrice) * 100)) || (quote_amount_due_at_start) || 0
                const quoteAmountAtEndValue = (endJobPrice !== undefined && (Number(endJobPrice) * 100)) || (quote_amount_due_at_complete) || 0

                // const quote_amount_due_at_start = startJobPrice !== undefined ? Number(startJobPrice) * 100 : jobRef.current.job_quote.quote_amount_due_at_start
                // const quote_amount_due_at_complete = endJobPrice !== undefined ? Number(endJobPrice) * 100 : jobRef.current.job_quote.quote_amount_due_at_complete
                const quote_amount = quoteAmountAtStartValue + quoteAmountAtEndValue
                payload.job_quote = {
                    quote_amount,
                    quote_amount_due_at_complete: quoteAmountAtEndValue,
                    quote_amount_due_at_start: quoteAmountAtStartValue,
                    quote_items: quoteItems
                }
            }
            if (clockifyProjectId !== undefined || hoursEstimation !== undefined) {
                masks.push('job_hours')
                const hours_clockify_project_id = clockifyProjectId !== undefined ? clockifyProjectId : jobRef.current.job_hours.hours_clockify_project_id
                let hours_estimated_total = hoursEstimation !== undefined ? Number(hoursEstimation) : jobRef.current.job_hours.hours_estimated_total
                if (paymentStructure !== 'HOURLY' && paymentStructure !== 'PRO_BONO') hours_estimated_total = -1
                payload.job_hours = {
                    hours_clockify_project_id,
                    hours_estimated_total: !hours_estimated_total ? -1 : hours_estimated_total
                }
            }
            if (paymentStructure !== jobRef.current.job_details.job_payment_structure) {
                masks.push('job_payment_structure');
                payload.job_payment_structure = paymentStructure
                if (paymentStructure === 'HOURLY' || paymentStructure === 'PRO_BONO') {
                    masks.push('job_hours');
                    const hours_clockify_project_id = clockifyProjectId !== undefined ? clockifyProjectId : jobRef.current.job_hours.hours_clockify_project_id
                    const hours_estimated_total = hoursEstimation !== undefined ? Number(hoursEstimation) : jobRef.current.job_hours.hours_estimated_total
                    payload.job_hours = {
                        hours_clockify_project_id,
                        hours_estimated_total: !hours_estimated_total ? -1 : hours_estimated_total
                    }
                }
            }
            if (accessibleUserIds !== undefined) {
                masks.push('accessible_user_ids')
                payload.accessible_user_ids = accessibleUserIds;
            }
            if (isHidden !== undefined) {
                masks.push('is_hidden')
                payload.is_hidden = isHidden;
            }
            if (masks.length === 0) { history.goBack(); return; }
            await updateJob(jobRef.current.job_id, siteRef.current.site_id, getUserId(), masks, payload);
            showNotificationBar('success', 'The job has been updated');
            history.goBack();
        } catch(err) {
            const resp = await err;
            console.error(resp);
            showNotificationBar('error', `Could not update this project at this time. Details: (code: ${resp.code}) Message: ${resp.message || 'No message provided.'}`)
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) {
        if (siteRef.current === undefined) return <Redirect to='/admin' />
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item lg={8} md={9} sm={12}>
                        <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                            <Grid item xs={'auto'}>
                                <IconButton onClick={() => history.goBack()}>
                                    <Close />
                                </IconButton>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Typography variant="h6" component="h2">
                                    Edit Project
                                </Typography>
                                <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                    {siteRef.current.site_details.site_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify='center'>
                    <Grid item xs={'auto'}>
                        <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                    </Grid>
                </Grid>
            </div>
        )
    }

    const renderHourlySection = () => {
        const {  hours_estimated_total } = jobRef.current.job_hours || {}
        const hoursEstimationValue = (hoursEstimation !== undefined ? hoursEstimation : hours_estimated_total)
        return (
            <>
                <Grid item xs={12}>
                    <div className={classes.sectionText}>Hour Tracking</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Provide a best estimation for the amount of hours that will be incurred by this project.</div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={errors.includes('hoursEstimation')}
                                helperText={errors.includes('hoursEstimation') && 'Please enter a valid estimation. Must be either greater than 0, or blank for "Not Estimated".'}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                onChange={(e) => setHoursEstimation(e.target.value || '')}
                                label="Estimated Total Hours"
                                value={hoursEstimationValue}
                                defaultValue={'0'}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={errors.includes('clockifyProjectId')}
                                helperText={(errors.includes('clockifyProjectId') && 'A Clockify project is required for an hourly or pro bono project, please enter a valid id.')}
                                fullWidth
                                className={classes.textField}
                                margin="normal"
                                onChange={(e) => setClockifyProjectId(e.target.value || '')}
                                label="Clockify Project ID"
                                value={clockifyProjectId !== undefined ? clockifyProjectId : (jobRef.current.job_hours && jobRef.current.job_hours.hours_clockify_project_id)}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    const renderAccessSection = () => {
        const users = usersRef.current;
        const selectedIds = accessibleUserIds || jobRef.current.job_security.accessible_user_ids
        const site = siteRef.current;
        return (
            <>
                <Grid item xs={12}>
                    <List>
                    {users.filter(user => selectedIds.includes(user.user_id)).map((user) => (
                    <ListItem dense key={user.user_id}>
                        <ListItemText primary={`${user.user_personal.first_name} ${user.user_personal.last_name} `} secondary={user.user_login_id} />
                        {user.user_id !== site.site_security.primary_user_id &&
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => handleRemoveId(user.user_id)}>
                                    <Close />
                                </IconButton>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                    ))}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        className={classes.textField}
                        label="User"
                        variant="outlined"
                        error={errors.includes('accessibleUserIds')}
                        helperText={errors.includes('accessibleUserIds') && 'Must include at least one user that can access this project.'}
                    >
                        {users.filter(user => !selectedIds.includes(user.user_id)).map((user) => (
                        <MenuItem key={user.user_id} value={user.user_id} onClick={(e) => setAccessibleUserIds([...selectedIds, user.user_id])}>
                            {user.user_personal.first_name} {user.user_personal.last_name} <span style={{ fontSize: 14, paddingLeft: 10 }}>({user.user_login_id})</span>
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </>
        )
    }

    const renderQuoteSection = () => {
        const { quote_amount_due_at_start, quote_amount_due_at_complete } = jobRef.current.job_quote || {}
        const startJobPriceValue = startJobPrice || (quote_amount_due_at_start && (quote_amount_due_at_start / 100).toFixed(2)) || ''
        const endJobPriceValue = endJobPrice || (quote_amount_due_at_complete && (quote_amount_due_at_complete / 100).toFixed(2)) || ''
        return (
            <>
                <Grid item xs={12}>
                    <div className={classes.sectionText}>Project Quote</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>{paymentStructure === 'QUOTE' ? `Please provide the agreed upon pricing for this job to share with the client.` : 'No quote will be sent to the client.'}</div>
                </Grid>
                {renderQuoteItemSection()}
                <Grid item xs={12}>
                    <div className={classes.sectionText} style={{ fontSize: 18, marginTop: 0 }}>Quote Pricing</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 16 }}>Provide the timing and amounts for payments for this quote. The prices must add up to the total quote amount of <b>${(expectedTotalJobPrice / 100).toFixed(2)}</b>.</div>
                    { errors.includes('totalJobPrice') && <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0, color: Colors.redColor }}>The start and end project price must add up to the total expected project price.</div> }
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                    error={errors.includes('startJobPrice')}
                    fullWidth
                    className={classes.textField}
                    helperText={(statusRef.current !== 'DRAFT' && statusRef.current !== 'PENDING_APPROVAL') && 'This project must be a draft or sent to the user for approval to include an amount due at start.'}
                    margin="normal"
                    onChange={(e) => setStartJobPrice(e.target.value)}
                    type="number"
                    label="*Start Project Price"
                    disabled={paymentStructure !== 'QUOTE' || (statusRef.current !== 'DRAFT' && statusRef.current !== 'PENDING_APPROVAL') }
                    defaultValue={'0.00'}
                    variant="outlined"
                    value={startJobPriceValue}
                    InputProps={{
                        startAdornment:
                        <span onClick={()=>0}>$</span>
                    }}
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                    error={errors.includes('endJobPrice')}
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                    onChange={(e) => setEndJobPrice(e.target.value)}
                    disabled={paymentStructure !== 'QUOTE'}
                    type="number"
                    label="*End Project Price"
                    defaultValue={'0.00'}
                    variant="outlined"
                    value={endJobPriceValue}
                    InputProps={{
                        startAdornment:
                    <span onClick={()=>0}>$</span>
                    }}
                />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.sectionText}>Hour Tracking</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>The Clockify project assigned to this project will be used to only track hours. No tracked hours will be charged to the final invoice.</div>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errors.includes('clockifyProjectId')}
                        helperText={(errors.includes('clockifyProjectId') && 'Please enter a valid clockify project id.')}
                        fullWidth
                        className={classes.textField}
                        margin="normal"
                        onChange={(e) => setClockifyProjectId(e.target.value || '')}
                        label="Clockify Project ID"
                        value={clockifyProjectId || (jobRef.current.job_hours && jobRef.current.job_hours.hours_clockify_project_id)}
                        variant="outlined"
                    />
                </Grid>
            </>
        )
    }

    const renderQuoteItemSection = () => (
        <>
            <Grid item xs={12}>
                <div className={classes.sectionText} style={{ fontSize: 18}}>Quote Items</div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
                <List>
                    {quoteItems.map((item) => (
                    <ListItem dense key={item.item_id} style={{ marginLeft: 0, paddingLeft: 0}}>
                        <ListItemText primary={`${item.item_name}: ${item.item_units} units  x  $${(item.item_unit_cost/100).toFixed(2)} = ${(item.item_total/100).toFixed(2)}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveQuoteItem(item.item_id)}>
                                <Close />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    ))}
                </List>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            error={errors.includes('quoteItemDescription')}
                            helperText={errors.includes('quoteItemDescription') && 'Please enter a quote item description.'}
                            label="Description"
                            variant="outlined"
                            value={quoteItemDescription}
                            onChange={(e) => setQuoteItemDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            error={errors.includes('quoteItemUnits')}
                            helperText={errors.includes('quoteItemUnits') && 'Please enter a valid amount of units.'}
                            label="Units"
                            type="number"
                            variant="outlined"
                            value={quoteItemUnits}
                            onChange={(e) => setQuoteItemUnits(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            error={errors.includes('quoteItemCostPerUnit')}
                            helperText={(errors.includes('quoteItemCostPerUnit') && 'Please enter a valid cost per unit.') || `Total Cost: $${calculateQuickTotal()}`}
                            label="Cost per Unit"
                            variant="outlined"
                            type="number"
                            value={quoteItemCostPerUnit}
                            onChange={(e) => setQuoteItemCostPerUnit(e.target.value)}
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>$</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton variant='contained' style={{ marginTop: 5}} onClick={handleAddQuoteItem}>
                            <Add />
                        </IconButton>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Edit Project
                            </Typography>
                            <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                {siteRef.current.site_details.site_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" style={{ width: '100%'}}>
                        <div style={{ padding: 16}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Project Info</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>This section includes the common details of the job. Please provide enough detail in the description for the client to be aware of its expectations and limitations.</div>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        error={errors.includes('jobName')}
                                        helperText={errors.includes('title') && 'Must provide a valid title.'}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        label="Title"
                                        value={jobName || jobRef.current.job_details.job_name}
                                        variant="outlined"
                                        onChange={(e) => setJobName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5} style={{ marginTop: 16 }}>
                                    <Grid container justify='space-between'>
                                        <div>
                                            <Typography variant='caption'>
                                                Current Status
                                            </Typography>
                                            <Typography variant='body1' style={{ marginTop: -5 }}>
                                                {JOB_STATUS.find(status => status.value === jobRef.current.job_details.job_status).label}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.includes('jobDescription')}
                                        fullWidth
                                        value={jobDescription === undefined ? jobRef.current.job_details.job_description : jobDescription}
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        multiline
                                        rows={6}
                                        label="*Detailed Description"
                                        variant="outlined"
                                        onChange={(e) => setJobDescription(e.target.value || '')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Payment Structure</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>{ jobRef.current.job_details.job_status === 'DRAFT' ? 'Select the method in which this project work will be compensated.' : 'This section can no longer be edited once out of draft state.' }</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup name="paymentStructure" value={paymentStructure || jobRef.current.job_details.job_payment_structure} onChange={(e) => setPaymentStructure(e.target.value)}>
                                        <FormControlLabel value="QUOTE" control={<Radio />} disabled={jobRef.current.job_details.job_status !== 'DRAFT'} label={renderRadioLabel("Quote", "A scope of work and itemized expenses will be used.")} />
                                        <FormControlLabel value="HOURLY" control={<Radio />} disabled={ jobRef.current.job_details.job_status !== 'DRAFT'} label={renderRadioLabel("Hourly", "Hours will be tracked and billed seperately.")} />
                                        <FormControlLabel value="PRO_BONO" control={<Radio />} disabled={jobRef.current.job_details.job_status !== 'DRAFT'} label={renderRadioLabel("Pro Bono", "Work will be tracked hourly, but will not be billed.")} />
                                        <FormControlLabel value="NO_COST" control={<Radio />} disabled={jobRef.current.job_details.job_status !== 'DRAFT'} label={renderRadioLabel("No Cost", "No direct cost will incurr with this project.")} />
                                    </RadioGroup>
                                </Grid>
                                { ((!paymentStructure && jobRef.current.job_details.job_payment_structure === 'QUOTE')
                                    || paymentStructure === 'QUOTE'
                                    ) && renderQuoteSection() }
                                { (
                                    (!paymentStructure
                                    && (jobRef.current.job_details.job_payment_structure === 'HOURLY' || jobRef.current.job_details.job_payment_structure === 'PRO_BONO'))
                                    || (paymentStructure === 'HOURLY'|| paymentStructure === 'PRO_BONO')
                                    )
                                && renderHourlySection() }
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Additional Options</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>{ jobRef.current.job_details.job_status === 'DRAFT' ? 'Select the method in which this project work will be compensated.' : 'This section can no longer be edited once out of draft state.' }</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Switch checked={isHidden === undefined ? jobRef.current.job_security.is_hidden : isHidden} onChange={(e) => {
                                        setIsHidden(e.target.checked)
                                    }}/> } label={renderRadioLabel("Hide From Client", "Do not make this project visible to the client.")} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Access</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Define the individuals within this site account that can have access to this project. People with access will receive invoice and update notifications.</div>
                                </Grid>
                                {renderAccessSection()}
                            </Grid>

                        </div>
                    </Paper>
                    <Grid container justify="flex-end" style={{marginTop: 10, marginBottom: 50 }}>
                        <div>
                            <Button
                                className={classes.buttonContainedStyle}
                                variant='contained'
                                onClick={saveJobEdits}
                                color="primary">
                                { isLoading ?
                                <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                : <span>Save</span>
                                }
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
*/

