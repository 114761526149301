import { Box, Grid, Paper, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import React from "react";
import { CARD_CAPTION_STYLE, SUBCONTENT_INFO_FONT } from "../../../../constants/Styles";

const GenericInfoCard = ({
  title, content, subcontent, footer, info
}) => {

return (
  <Grid item xs={12} md={4}>
    <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
        <div style={CARD_CAPTION_STYLE}>{title}</div>
        {info !== undefined &&
          <Tooltip title={info}>
            <InfoOutlined style={{ fontSize: 18, cursor: 'pointer', marginTop: 3 }} />
          </Tooltip>
        }
      </Box>
      <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: '20px', fontSize: '40px' }}>{content}</h4>
      <div style={{ ...SUBCONTENT_INFO_FONT, textAlign: 'center' }}>{subcontent}</div>
      <p style={{ textAlign: 'center' }}>{footer}</p>
    </Paper>
  </Grid>
)
}

export default GenericInfoCard;
