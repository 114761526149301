import { Box } from "@mui/material";
import React from "react";
import { SUMMARY_TITLE_FONT } from "../../constants/Styles";

const StyledLineItem = ({
 primary,
 secondary,
 sx,
 primarySx = {},
 secondarySx = {},
 ...props
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', ...sx}} {...props}>
    <div style={{ ...SUMMARY_TITLE_FONT, ...primarySx }}>{primary}</div>
    <div style={{ ...secondarySx }}>{secondary}</div>
  </Box>
)

export default StyledLineItem;