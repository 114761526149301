import * as React from 'react';
import { ArrowBackOutlined, CloseOutlined } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { withRouter } from "react-router"

const StyledSubHeader = ({
    title,
    history,
    closeIcon = false,
    ...props
  }) => (
    <Grid 
      container 
      justifyContent="flex-start" 
      alignItems="center"
      spacing={2}
      sx={{ flexWrap: 'nowrap' }}
    >
      <Grid item xs="auto">
        <IconButton onClick={() => history.goBack()}>
          {closeIcon ? <CloseOutlined /> : <ArrowBackOutlined />}
        </IconButton>
      </Grid>
      <Grid item xs={10}>
        <h6 style={{ margin: 0, overflowWrap: 'break-word' }}>{title}</h6> {/* Ensure no extra margin */}
      </Grid>
    </Grid>
)

export default withRouter(StyledSubHeader)